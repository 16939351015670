<agm-map
  #map
  [ngStyle]="{ height: '555px' }"
  [latitude]="center?.lat"
  [longitude]="center?.lng"
  [mapTypeControl]="true"
  [zoom]="zoom"
>
  <agm-polygon
    #polygon
    *ngFor="let field of fields"
    [paths]="field.coordinates"
    [visible]="true"
    [strokeWeight]="3"
    [strokeColor]="'#07b57a'"
    [fillColor]="'#07b57a'"
  >
  </agm-polygon>
</agm-map>

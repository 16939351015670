import { Component, OnInit, OnDestroy } from "@angular/core";
import { Field } from "src/app/core/models/field.model";
import { Store, select } from "@ngrx/store";
import { FieldState } from "src/app/core/store/reducers/field.reducer";
import { LayoutUtilsService, MessageType } from "src/app/core/_base/crud";
import {
  selectFieldsLoading,
  selectFieldsByCustomerId,
  selectFieldsSelector,
} from "src/app/core/store/selectors/field.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  loadFields,
  deleteField,
} from "src/app/core/store/actions/field.actions";
import { ActivatedRoute } from "@angular/router";
import {
  selectCurrentCustomer,
  selectCustomerById,
} from "src/app/core/store/selectors/customer.selectors";

@Component({
  selector: "kt-fields-list",
  templateUrl: "./fields-list.component.html",
  styleUrls: ["./fields-list.component.scss"],
})
export class FieldsListComponent implements OnInit, OnDestroy {
  loading;
  fields: Field[];
  customerId;
  title = "Fields";
  allFields: Field[];

  constructor(
    private store: Store<FieldState>,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectFieldsLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    let customerId = this.route.snapshot.params["customerId"];

    if (customerId) {
      // Load customer fields
      this.title = "Customer fields";
      this.store
        .pipe(select(selectCustomerById(customerId)), untilDestroyed(this))
        .subscribe((c) => {
          if (c) {
            this.title = c.name + "'s Fields";
          }
        });

      this.store
        .pipe(
          select(selectFieldsByCustomerId(customerId)),
          untilDestroyed(this)
        )
        .subscribe((fields) => {
          this.fields = fields;
          this.allFields = fields;
        });
    } else {
      // Load all fields
      this.store
        .pipe(select(selectFieldsSelector), untilDestroyed(this))
        .subscribe((fields) => {
          this.fields = fields;
          this.allFields = fields;
        });
    }

    this.store.dispatch(loadFields());
  }

  deleteField(_item: Field) {
    const _title = "Field Delete";
    const _description = "Are you sure to permanently delete this field?";
    const _waitDesciption = "Field is deleting...";
    const _deleteMessage = `Field has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteField({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  isLoadedChanged($event) {
    if ($event) {
      this.fields = this.fields.filter((f) => !f.isFullyLoaded);
    } else {
      this.fields = this.allFields;
    }
  }

  ngOnDestroy(): void {}
}

<kt-generic-portlet [title]="'Customers'" [actionsTemplate]="actionsTemplate">
  <kt-customers-table
    [customers]="customers"
    [loading]="loading"
    (deleteCustomer)="deleteCustomer($event)"
  ></kt-customers-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a color="primary" mat-raised-button [routerLink]="['add']">
    New Customer
  </a>
</ng-template>

import { PartialsModule } from "./../../partials/partials.module";
import { MatCoreModule } from "./../../../core/utils/mat-core.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EntitySelectComponent } from "./entity-select.component";

@NgModule({
  declarations: [EntitySelectComponent],
  imports: [CommonModule, PartialsModule],
  exports: [EntitySelectComponent],
})
export class EntitySelectModule {}

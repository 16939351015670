import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { Drone } from "src/app/core/models/drone.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-drones-table",
  templateUrl: "./drones-table.component.html",
  styleUrls: ["./drones-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DronesTableComponent implements OnInit, AfterViewInit {
  @Input() drones: Drone[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deleteDrone = new EventEmitter<Drone>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild("modelTemplate", { static: false }) modelTemplate: TemplateRef<
    any
  >;
  @ViewChild("payloadTemplate", { static: false }) payloadTemplate: TemplateRef<
    any
  >;
  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "tailNumber",
          clientFieldName: "tailNumber",
          displayNameKey: "Tail Number",
          sortable: true,
        },
        {
          serverFieldName: "model",
          clientFieldName: "model",
          displayNameKey: "Model",
          sortable: true,
          template: this.modelTemplate,
        },
        {
          serverFieldName: "payload",
          clientFieldName: "payload",
          displayNameKey: "Payload",
          template: this.payloadTemplate,
          sortable: true,
        },
        {
          serverFieldName: "blackBoxId",
          clientFieldName: "blackBoxId",
          displayNameKey: "Black box Id",
          sortable: true,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  editDroneClick(drone: Drone) {
    this.router.navigate([drone.droneId], { relativeTo: this.route });
  }
}

import { Action, createReducer, on } from "@ngrx/store";
import * as DronePilotsActions from "../actions/drone-pilots.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { DronePilot } from "../../models/drone-pilot.model";
import { PilotType } from "../../models/pilot-type.model";

export const dronePilotsFeatureKey = "dronePilots";

export interface DronePilotsState extends EntityState<DronePilot> {
  loading: boolean;
  currentDronePilot: DronePilot;
  pilotTypes: PilotType[];
}

export const adapter: EntityAdapter<DronePilot> = createEntityAdapter<
  DronePilot
>({
  selectId: (dronePilot: DronePilot) => dronePilot.pilotId,
});

export const initialDronePilotState: DronePilotsState = adapter.getInitialState(
  {
    loading: false,
    currentDronePilot: null,
    pilotTypes: null,
  }
);

const dronePilotsReducer = createReducer(
  initialDronePilotState,

  on(DronePilotsActions.loadDronePilots, (state) => ({
    ...state,
    loading: true,
  })),
  on(DronePilotsActions.loadDronePilotsSuccess, (state, action) => {
    return { ...adapter.setAll(action.data, state), loading: false };
  }),
  on(DronePilotsActions.loadDronePilotsFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronePilotsActions.loadPilotTypesSuccess, (state, action) => {
    return { ...state, pilotTypes: action.data };
  }),

  on(DronePilotsActions.addDronePilot, (state) => ({
    ...state,
    loading: true,
  })),
  on(DronePilotsActions.addDronePilotSuccess, (state, action) => {
    return { ...adapter.addOne(action.data, state), loading: false };
  }),
  on(DronePilotsActions.addDronePilotFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronePilotsActions.updateDronePilot, (state) => ({
    ...state,
    loading: true,
  })),
  on(DronePilotsActions.updateDronePilotSuccess, (state, action) => {
    return { ...adapter.updateOne(action.data, state), loading: false };
  }),
  on(DronePilotsActions.updateDronePilotFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronePilotsActions.deleteDronePilot, (state) => ({
    ...state,
    loading: true,
  })),
  on(DronePilotsActions.deleteDronePilotSuccess, (state, action) => {
    return {
      ...adapter.removeOne(action.data.pilotId, state),
      loading: false,
    };
  }),
  on(DronePilotsActions.addDronePilotFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronePilotsActions.loadDronePilot, (state) => ({
    ...state,
    loading: true,
  })),
  on(DronePilotsActions.loadDronePilotSuccess, (state, action) => {
    return { ...state, currentDronePilot: action.data, loading: false };
  }),
  on(DronePilotsActions.loadDronePilotFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: DronePilotsState | undefined, action: Action) {
  return dronePilotsReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

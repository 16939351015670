import { Component, OnInit, OnDestroy } from "@angular/core";
import { Customer } from "src/app/core/models/customer.model";
import { Store, select } from "@ngrx/store";
import { LayoutUtilsService, MessageType } from "src/app/core/_base/crud";
import {
  selectCustomersLoading,
  selectCustomersSelector,
} from "src/app/core/store/selectors/customer.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  loadCustomers,
  deleteCustomer,
} from "src/app/core/store/actions/customer.actions";
import { CustomersState } from "src/app/core/store/reducers/customer.reducer";

@Component({
  selector: "kt-customers-list",
  templateUrl: "./customers-list.component.html",
  styleUrls: ["./customers-list.component.scss"],
})
export class CustomersListComponent implements OnInit, OnDestroy {
  loading;
  customers: Customer[];

  constructor(
    private store: Store<CustomersState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectCustomersLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.store
      .pipe(select(selectCustomersSelector), untilDestroyed(this))
      .subscribe((customers) => {
        this.customers = customers;
      });

    this.store.dispatch(loadCustomers());
  }

  deleteCustomer(_item: Customer) {
    const _title = "Customer Delete";
    const _description = "Are you sure to permanently delete this customer?";
    const _waitDesciption = "Customer is deleting...";
    const _deleteMessage = `Customer has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteCustomer({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  ngOnDestroy(): void {}
}

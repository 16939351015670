<kt-generic-portlet
  [title]="'Assign selected tasks'"
  [loading]="loading$"
  [actionsTemplate]="actionsTemplate"
>
  <form [formGroup]="assignForm" class="form form-group-seperator-dashed">
    <div class="form-section form-section-first">
      <div class="row">
        <!-- Status -->
        <div class="col-lg-12 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select
              matInput
              placeholder="Select a status"
              formControlName="status"
              #statusSelectControl
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.statusId"
                >{{ status.name }}</mat-option
              >
            </mat-select>

            <mat-hint align="start"
              >Please select a
              <strong>status</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
    [disabled]="loading$ | async"
  >
    <span class="hidden-mobile">Assign</span>
  </a>
</ng-template>

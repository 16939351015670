import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import {
  selectTasksLoading,
  selectCompletedTasks,
} from "src/app/core/store/selectors/task.selectors";
import { Task } from "src/app/core/models/task.model";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
  selector: "kt-completed-tasks",
  templateUrl: "./completed-tasks.component.html",
  styleUrls: ["./completed-tasks.component.scss"],
})
export class CompletedTasksComponent implements OnInit, OnDestroy {
  loading$;
  title = "Completed tasks";
  tasks: Task[];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksLoading));

    this.store
      .pipe(select(selectCompletedTasks), untilDestroyed(this))
      .subscribe((tasks) => {
        if (tasks) {
          this.tasks = tasks;
        }
      });
  }

  ngOnDestroy(): void {}
}

import { createAction, props } from "@ngrx/store";
import { Customer } from "../../models/customer.model";

export const loadCustomers = createAction("[Customer] Load Customers");

export const loadCustomersSuccess = createAction(
  "[Customer] Load Customers Success",
  props<{ data: any }>()
);

export const loadCustomersFailure = createAction(
  "[Customer] Load Customers Failure",
  props<{ error: any }>()
);

export const loadCustomer = createAction(
  "[Customer] Load Customer",
  props<{ data: any }>()
);

export const loadCustomerSuccess = createAction(
  "[Customer] Load Customer Success",
  props<{ data: any }>()
);

export const loadCustomerFailure = createAction(
  "[Customer] Load Customer Failure",
  props<{ error: any }>()
);

export const addCustomer = createAction(
  "[Customer] AddCustomer",
  props<{ data: any }>()
);

export const addCustomerSuccess = createAction(
  "[Customer] AddCustomer Success",
  props<{ data: Customer }>()
);

export const addCustomerFailure = createAction(
  "[Customer] AddCustomer Failure",
  props<{ error: any }>()
);

export const updateCustomer = createAction(
  "[Customer] UpdateCustomer",
  props<{ data: any }>()
);

export const updateCustomerSuccess = createAction(
  "[Customer] UpdateCustomer Success",
  props<{ data: any }>()
);

export const updateCustomerFailure = createAction(
  "[Customer] UpdateCustomer Failure",
  props<{ error: any }>()
);

export const deleteCustomer = createAction(
  "[Customer] DeleteCustomer",
  props<{ data: any }>()
);

export const deleteCustomerSuccess = createAction(
  "[Customer] DeleteCustomer Success",
  props<{ data: any }>()
);

export const deleteCustomerFailure = createAction(
  "[Customer] DeleteCustomer Failure",
  props<{ error: any }>()
);

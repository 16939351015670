import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromField from "../reducers/field.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectFieldState = createFeatureSelector<fromField.FieldState>(
  fromField.fieldFeatureKey
);

export const selectFieldsSelector = createSelector(
  selectFieldState,
  fromField.selectAll
);

//export const selectFields = pipe(select(selectFieldsSelector), skip(1));

export const selectFieldsLoading = createSelector(
  selectFieldState,
  (state) => state.loading
);

export const selectCurrentField = createSelector(
  selectFieldState,
  (state) => state.currentField
);

export const selectFieldsByCustomerId = (customerId) =>
  createSelector(selectFieldsSelector, (fields) =>
    fields.filter((f) => f.customer.customerId == customerId)
  );

export const selectFieldById = (id) =>
  createSelector(selectFieldState, (state) => state.entities[id]);

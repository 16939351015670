import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TasksComponent } from "./tasks.component";
import { Routes, RouterModule } from "@angular/router";
import { MatCoreModule } from "src/app/core/utils/mat-core.module";
import { HttpClientModule } from "@angular/common/http";
import { PartialsModule } from "../../partials/partials.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import * as fromTask from "../../../core/store/reducers/task.reducer";
import { EffectsModule } from "@ngrx/effects";
import { TaskEffects } from "../../../core/store/effects/task.effects";

import { TasksListComponent } from "./tasks-list/tasks-list.component";
import { ImportTaskComponent } from "./import-task/import-task.component";
import { TasksTableComponent } from "./tasks-list/tasks-table/tasks-table.component";
import { FileUploadModule } from "@iplab/ngx-file-upload";

import { AssignTasksComponent } from "./assign-tasks/assign-tasks.component";
import { TasksFilterComponent } from "./assign-tasks/tasks-filter/tasks-filter.component";
import { TaskFieldsTableComponent } from "./assign-tasks/task-fields-table/task-fields-table.component";
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { AssignSelectedFormComponent } from "./assign-tasks/assign-selected-form/assign-selected-form.component";
import { TaskEditComponent } from "./task-edit/task-edit.component";
import { TaskControllerComponent } from "./task-controller/task-controller.component";
import { ControllerMapComponent } from "./task-controller/controller-map/controller-map.component";
import { ControllerTableComponent } from "./task-controller/controller-table/controller-table.component";
import { ControllerFilterComponent } from "./task-controller/controller-filter/controller-filter.component";
import { RouteListComponent } from "./task-controller/controller-map/route-list/route-list.component";
import { TaskFieldsMapComponent } from "./assign-tasks/task-fields-map/task-fields-map.component";
import { ControllerAssignSelectedComponent } from "./task-controller/controller-assign-selected/controller-assign-selected.component";
import { TasksRoutingModule } from "./tasks-routing.module";
import { CompletedTasksComponent } from "./completed-tasks/completed-tasks.component";
import { CompletedTasksFilterComponent } from "./completed-tasks/completed-tasks-filter/completed-tasks-filter.component";
import { CompletedTasksTableComponent } from "./completed-tasks/completed-tasks-table/completed-tasks-table.component";
//import { PilotTasksComponent } from './pilot-tasks/pilot-tasks.component';

@NgModule({
  declarations: [
    TasksComponent,
    AssignTasksComponent,
    TasksListComponent,
    ImportTaskComponent,
    TasksTableComponent,
    TasksFilterComponent,
    TaskFieldsTableComponent,
    TaskFieldsMapComponent,
    AssignSelectedFormComponent,
    TaskEditComponent,
    TaskControllerComponent,
    ControllerMapComponent,
    ControllerTableComponent,
    ControllerFilterComponent,
    RouteListComponent,
    ControllerAssignSelectedComponent,
    CompletedTasksComponent,
    CompletedTasksFilterComponent,
    CompletedTasksTableComponent,
    // PilotTasksComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    MatCoreModule,
    FormsModule,
    ReactiveFormsModule,
    TasksRoutingModule,
    StoreModule.forFeature(fromTask.taskFeatureKey, fromTask.reducer),
    EffectsModule.forFeature([TaskEffects]),
    FileUploadModule,
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDMWp19lJGUMI_DoASc_mOCf7qFcwu81vA",
      libraries: ["places", "drawing", "geometry"],
    }),
  ],
})
export class TasksModule {}

import { TaskFilterWrapper } from "./../models/task-filter-wrapper.model";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Task } from "../models/task.model";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MapTask } from "../models/map-task.model";
import { AssignTaskWrapper } from "../models/assignTaskWrapper.model";
import { DroneRoute } from "../models/drone-route-model";

@Injectable({
  providedIn: "root",
})
export class TasksService {
  constructor(private api: ApiService, private http: HttpClient) {}

  getAllTasks() {
    return this.api.get("tasks").pipe(
      map((result) => {
        const tasks = result.map((d) => Task.parse(d));

        return tasks;
      })
    );
  }

  getTaskById(id) {
    return this.api.get("tasks/" + id).pipe(
      map((result) => {
        const task = Task.parse(result);

        return task;
      })
    );
  }

  getStauses() {
    return this.api.get("status").pipe(
      map((result) => {
        const statuses = result.map((d) => JSON.parse(JSON.stringify(d)));

        return statuses;
      })
    );
  }

  deleteTask(task: Task) {
    return this.api.delete("tasks/" + task.taskId).pipe(
      map((result) => {
        return task;
      })
    );
  }

  updateTask(task: Task) {
    return this.api
      .put("tasks/" + task.taskId, Task.parseBackUpdate(task))
      .pipe(
        map((result) => {
          return task;
        })
      );
  }

  importTasks(task: Task, files, isPreview = false) {
    let file = files[0];
    const formData: FormData = new FormData();

    formData.append("file", file, file.name);

    if (isPreview) {
      formData.append("isPreview", "true");
    } else {
      formData.append("task", JSON.stringify(Task.parseBack(task)));
    }

    return this.http.post(`${environment.api_url}tasks`, formData).pipe(
      map((result) => {
        return result;
      })
    );
  }

  filterTasks(taskFilterWrapper: TaskFilterWrapper) {
    return this.api.post("TaskSearch", null, taskFilterWrapper).pipe(
      map((result) => {
        let mapTasks = result.map((t) => MapTask.parse(t));
        return mapTasks;
      })
    );
  }

  assignTasks(taskAssignWrapper: AssignTaskWrapper) {
    return this.api.post("taskassign", null, taskAssignWrapper).pipe(
      map((result) => {
        return result;
      })
    );
  }

  filterControllerTasks(taskFilterWrapper: TaskFilterWrapper) {
    return this.api.post("TaskControl", null, taskFilterWrapper).pipe(
      map((result) => {
        let mapTasks = result.tasksList.map((t) => MapTask.parse(t));
        let droneRoutes = result.droneRoutes.map((d) => DroneRoute.parse(d));

        return { mapTasks, droneRoutes };
      })
    );
  }

  assignControllerTasks(taskAssignWrapper: AssignTaskWrapper) {
    return this.api.post("TaskControlAssign", null, taskAssignWrapper).pipe(
      map((result) => {
        return result;
      })
    );
  }

  filterCompletedTasks(taskFilterWrapper: TaskFilterWrapper) {
    return this.api.post("completedTasks", null, taskFilterWrapper).pipe(
      map((result) => {
        let tasks = result.map((t) => Task.parse(t));
        return tasks;
      })
    );
  }
}

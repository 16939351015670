import { PilotTasksModule } from "./pilot-tasks/pilot-tasks.module";
import { FieldsModule } from "./fields/fields.module";
import { TasksModule } from "./tasks/tasks.module";
import { CustomersModule } from "./customers/customers.module";
import { ProtocolsModule } from "./protocols/protocols.module";
import { MaterialsModule } from "./materials/materials.module";
// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
// Partials
import { PartialsModule } from "../partials/partials.module";
// Pages
import { CoreModule } from "../../core/core.module";
import { UserManagementModule } from "./user-management/user-management.module";
import { DronesModule } from "./drones/drones.module";
import { DronePilotsModule } from "./drone-pilots/drone-pilots.module";
import { MaterialsComponent } from "./materials/materials.component";
import { ProtocolsComponent } from "./protocols/protocols.component";
import { FieldsComponent } from "./fields/fields.component";
import { TasksComponent } from "./tasks/tasks.component";

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    UserManagementModule,
    TasksModule,
    DronesModule,
    DronePilotsModule,
    MaterialsModule,
    ProtocolsModule,
    CustomersModule,
    FieldsModule,
  ],
  providers: [],
})
export class PagesModule {}

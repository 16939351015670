import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { Status } from "src/app/core/models/status.model";
import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { MapTask } from "src/app/core/models/map-task.model";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store, select } from "@ngrx/store";
import { AssignSelectedFormComponent } from "../../assign-tasks/assign-selected-form/assign-selected-form.component";
import {
  selectTasksModalLoading,
  selectStatuses,
} from "src/app/core/store/selectors/task.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { selectPilotsSelector } from "src/app/core/store/selectors/drone-pilots.selectors";
import {
  assignTasks,
  assignControllerTasks,
} from "src/app/core/store/actions/task.actions";
import { AssignTaskWrapper } from "src/app/core/models/assignTaskWrapper.model";

@Component({
  selector: "kt-controller-assign-selected",
  templateUrl: "./controller-assign-selected.component.html",
  styleUrls: ["./controller-assign-selected.component.scss"],
})
export class ControllerAssignSelectedComponent implements OnInit, OnDestroy {
  loading$;
  statuses: Status[];
  selectedTasks: MapTask[];

  assignForm: FormGroup;

  submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store,
    private assignFb: FormBuilder,
    public dialogRef: MatDialogRef<ControllerAssignSelectedComponent>
  ) {
    this.selectedTasks = data;
  }

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksModalLoading));

    this.store
      .pipe(select(selectTasksModalLoading), untilDestroyed(this))
      .subscribe((loading) => {
        if (loading === false && this.submitting) {
          this.dialogRef.close();
        }
      });

    this.store
      .pipe(select(selectStatuses), untilDestroyed(this))
      .subscribe((s) => {
        this.statuses = s.filter(
          (s) => s.name == "Completed" || s.name == "Uncompleted"
        );
      });

    this.createForm();
  }

  createForm() {
    this.assignForm = this.assignFb.group({
      status: [null, Validators.required],
    });
  }

  onSumbit() {
    const controls = this.assignForm.controls;
    /** check form */
    if (this.assignForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      return;
    }

    let assignTaskWrapper = this.prepareAssignTasks();

    this.submitting = true;
    this.store.dispatch(assignControllerTasks({ data: assignTaskWrapper }));
  }

  prepareAssignTasks() {
    let taskIds = this.selectedTasks.map((t) => t.task.taskId);

    return {
      tasksToAssign: taskIds,
      statusId: this.assignForm.controls.status.value,
    };
  }

  ngOnDestroy(): void {}
}

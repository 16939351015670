<kt-generic-portlet
  [title]="title"
  [actionsTemplate]="actionsTemplate"
  [loading]="loading$"
>
  <form [formGroup]="taskForm" class="form form-group-seperator-dashed">
    <kt-alert
      *ngIf="hasFormErrors"
      type="warn"
      [showCloseButton]="true"
      [duration]="10000"
      (close)="onAlertClose($event)"
    >
      Oh snap! Change a few things up and try submitting again.
    </kt-alert>

    <div class="form-section form-section-first">
      <div class="form-group row">
        <div class="col-lg-12 kt-margin-bottom-20-mobile">
          <file-upload
            accept=".xlsx,.csv"
            formControlName="fileUploadControl"
            [multiple]="'false'"
            [animation]="'true'"
          ></file-upload>
          <mat-error *ngIf="filesControl.invalid && filesControl.touched"
            >File is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>

      <div class="form-group row">
        <!-- pilot -->
        <!-- <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="pilots"
            [filterBy]="'name'"
            [valueProperty]="'pilotId'"
            [selectFormControl]="pilotsSelectFormControl"
            [hint]="'Choose a pilot to filter by'"
            [placeHolder]="'Select a pilot'"
          >
          </kt-mat-select-filter>
        </div> -->

        <!-- Customer -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="customers"
            [filterBy]="'name'"
            [valueProperty]="'customerId'"
            [selectFormControl]="customersSelectFormControl"
            [error]="'Customer is required'"
            [hint]="'Please select a customer'"
            [placeHolder]="'Select a customer'"
          >
          </kt-mat-select-filter>
        </div>

        <!-- Material -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="materials"
            [filterBy]="'name'"
            [valueProperty]="'materialId'"
            [selectFormControl]="materialsSelectFormControl"
            [error]="'Material is required'"
            [hint]="'Please select a material'"
            [placeHolder]="'Select a material'"
          >
          </kt-mat-select-filter>
        </div>

        <!-- Protocol -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="protocols"
            [filterBy]="'name'"
            [valueProperty]="'protocolId'"
            [selectFormControl]="protocolsSelectFormControl"
            [error]="'Protocol is required'"
            [hint]="'Please select a protocol'"
            [placeHolder]="'Select a protocol'"
          >
          </kt-mat-select-filter>
        </div>

        <!-- Status -->
        <!-- <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select
              matInput
              formControlName="status"
              placeholder="Select a status"
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.statusId"
                >{{ status.name }}</mat-option
              >
            </mat-select>
            <mat-error
              >Status is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please select a
              <strong>status</strong>
            </mat-hint>
          </mat-form-field>
        </div> -->

        <!-- Execution Date -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <div class="row">
            <div class="col-lg-10">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="Enter requested date"
                  formControlName="executionDate"
                />
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                  >Requested date is
                  <strong>required</strong>
                </mat-error>
                <mat-hint align="start"
                  >Please enter
                  <strong>requested date</strong>
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-2">
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="preview">
    <div>
      <span style="font-weight: bold;">Customer name: </span>
      {{ preview.customerName }}
    </div>
    <div>
      <span style="font-weight: bold;">Material name:</span>
      {{ preview.materialName }}
    </div>
    <div>
      <span style="font-weight: bold;">Protocol name:</span>
      {{ preview.protocolName }}
    </div>
    <div>
      <span style="font-weight: bold;">Execution date: </span
      >{{ preview.requiredDateStr }}
    </div>
    <div>
      <span style="font-weight: bold;">Number of tasks in file: </span
      >{{ preview.totalRecords }}
    </div>
  </div>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
  >
    <span class="hidden-mobile">Submit</span>
  </a>

  <a
    [routerLink]="['../']"
    class="btn btn-secondary mr-2"
    mat-raised-button
    matTooltip="Back to the tasks list"
  >
    <i class="la la-arrow-left"></i>
    <span class="hidden-mobile">Back</span>
  </a>
</ng-template>

import { AssignTaskWrapper } from "./../../../../../core/models/assignTaskWrapper.model";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { DronePilot } from "./../../../../../core/models/drone-pilot.model";
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Status } from "src/app/core/models/status.model";
import { selectPilotsSelector } from "src/app/core/store/selectors/drone-pilots.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  selectStatuses,
  selectTasksModalLoading,
} from "src/app/core/store/selectors/task.selectors";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MapTask } from "src/app/core/models/map-task.model";
import { assignTasks } from "src/app/core/store/actions/task.actions";
import { ViewChild } from "@angular/core";

@Component({
  selector: "kt-assign-selected-form",
  templateUrl: "./assign-selected-form.component.html",
  styleUrls: ["./assign-selected-form.component.scss"],
})
export class AssignSelectedFormComponent implements OnInit, OnDestroy {
  loading$;
  statuses: Status[];
  pilots: DronePilot[];
  selectedTasks: MapTask[];

  assignForm: FormGroup;

  submitting = false;

  public pilotsSelectFormControl: FormControl = new FormControl();
  @ViewChild("assignExecutionDateFormControl") assignExecutionDateFormControl;


  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private store: Store,
    private assignFb: FormBuilder,
    public dialogRef: MatDialogRef<AssignSelectedFormComponent>
  ) {
    this.selectedTasks = data;
  }

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksModalLoading));

    this.store
      .pipe(select(selectTasksModalLoading), untilDestroyed(this))
      .subscribe((loading) => {
        if (loading === false && this.submitting) {
          this.dialogRef.close();
        }
      });

    this.store
      .pipe(select(selectPilotsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.pilots = p;
      });

    this.store
      .pipe(select(selectStatuses), untilDestroyed(this))
      .subscribe((s) => {
        this.statuses = s;
      });

    this.createForm();
  }

  createForm() {
    this.assignForm = this.assignFb.group({
      executionDate: [null, Validators.required],
      status: [null, Validators.required],
    });

    this.assignForm.addControl(
      "piltosSelectFormControl",
      this.pilotsSelectFormControl
    );
    this.pilotsSelectFormControl.setValidators(Validators.required);
  }

  onSumbit() {
    const controls = this.assignForm.controls;
    /** check form */
    if (this.assignForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      return;
    }

    let assignTaskWrapper = this.prepareAssignTasks();

    this.submitting = true;
    this.store.dispatch(assignTasks({ data: assignTaskWrapper }));
  }

  prepareAssignTasks(): AssignTaskWrapper {
    let taskIds = this.selectedTasks.map((t) => t.task.taskId);

    return {
      tasksToAssign: taskIds,
      statusId: this.assignForm.controls.status.value,
      assignToPilotId: this.pilotsSelectFormControl.value,
      executionDate: this.assignExecutionDateFormControl.nativeElement.value,
    };
  }

  ngOnDestroy(): void {}
}

import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { AppState } from "src/app/core/reducers";
import { Store, select } from "@ngrx/store";
import {
  loadDrones,
  deleteDrone,
} from "src/app/core/store/actions/drones.actions";
import {
  DroneState,
  selectEntities,
} from "src/app/core/store/reducers/drones.reducer";
import {
  selectDrones,
  selectDronesLoading,
} from "src/app/core/store/selectors/drones.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { Drone } from "src/app/core/models/drone.model";
import { Router } from "@angular/router";
import { LayoutUtilsService, MessageType } from "src/app/core/_base/crud";

@Component({
  selector: "kt-drones-list",
  templateUrl: "./drones-list.component.html",
  styleUrls: ["./drones-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DronesListComponent implements OnInit, OnDestroy {
  drones: Drone[];
  loading = false;

  constructor(
    private store: Store<DroneState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectDronesLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.store.pipe(selectDrones, untilDestroyed(this)).subscribe((drones) => {
      this.drones = drones;
    });

    this.store.dispatch(loadDrones());
  }

  deleteDrone(_item: Drone) {
    const _title = "Drone Delete";
    const _description = "Are you sure to permanently delete this drone?";
    const _waitDesciption = "Drone is deleting...";
    const _deleteMessage = `Drone has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteDrone({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  ngOnDestroy(): void {}
}

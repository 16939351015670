import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  updateCustomer,
  loadCustomer,
  addCustomer,
} from "src/app/core/store/actions/customer.actions";
import { Customer } from "src/app/core/models/customer.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import {
  selectCustomersLoading,
  selectCurrentCustomer,
} from "src/app/core/store/selectors/customer.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { cloneDeep } from "lodash";
import { CustomersState } from "src/app/core/store/reducers/customer.reducer";
import { RegEx } from "src/app/core/_base/crud/utils/regex-utils";

@Component({
  selector: "kt-edit-customer",
  templateUrl: "./edit-customer.component.html",
  styleUrls: ["./edit-customer.component.scss"],
})
export class EditCustomerComponent implements OnInit, OnDestroy {
  title = "Add Customer";
  isEdit = false;
  customer: Customer;
  customerForm: FormGroup;
  hasFormErrors = false;
  loading$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<CustomersState>,
    private customerFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectCustomersLoading),
      untilDestroyed(this)
    );

    this.customer = Customer.createEmpty();

    this.store
      .pipe(select(selectCurrentCustomer), untilDestroyed(this))
      .subscribe((currentCustomer) => {
        if (currentCustomer && this.isEdit) {
          this.customer = cloneDeep(currentCustomer);
        }

        this.createForm();
      });

    let customerId = this.route.snapshot.params["id"];

    if (customerId && customerId != "add") {
      this.isEdit = true;
      this.title = "Edit Customer";

      this.store.dispatch(loadCustomer({ data: customerId }));
    }
  }

  createForm() {
    this.customerForm = this.customerFB.group({
      name: [this.customer.name, Validators.required],
      phone: [
        this.customer.phone,
        [Validators.pattern(new RegExp(RegEx.phone))],
      ],
      email: [this.customer.email, Validators.email],
    });
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.customerForm.controls;
    /** check form */
    if (this.customerForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.prepareCustomer();

    if (this.isEdit) {
      this.store.dispatch(updateCustomer({ data: this.customer }));
    } else {
      this.store.dispatch(addCustomer({ data: this.customer }));
    }
  }

  prepareCustomer() {
    this.customer.name = this.customerForm.controls.name.value;
    this.customer.phone = this.customerForm.controls.phone.value;
    this.customer.email = this.customerForm.controls.email.value;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

<kt-generic-portlet [title]="'Users'" [actionsTemplate]="actionsTemplate">
  <kt-users-table
    [users]="users"
    [loading]="loading"
    (deleteUser)="deleteUser($event)"
  ></kt-users-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a color="primary" mat-raised-button [routerLink]="['add']">
    New User
  </a>
</ng-template>

import { Injectable } from "@angular/core";
import { ApiService } from "src/app/core/services/api.service";

@Injectable({
  providedIn: "root",
})
export class EntitySelectService {
  constructor(private api: ApiService) {}

  getEntity(entityName: string) {
    return this.api.get(entityName);
  }
}

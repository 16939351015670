import { createAction, props } from "@ngrx/store";

export const login = createAction(
  "[Auth] Login",
  props<{ username: string; password: string }>()
);

export const loginSuccess = createAction(
  "[Auth] Login Success",
  props<{ data: any }>()
);

export const loginFailure = createAction(
  "[Auth] Login Failure",
  props<{ error: any }>()
);

export const logout = createAction("[Auth] Logout");

export const loadUserProfile = createAction("[Auth] LoadUser");

export const loadUserProfileSuccess = createAction(
  "[Auth] LoadUser Success",
  props<{ data: any }>()
);

export const loadUserProfileFailure = createAction(
  "[Auth] LoadUser Failure",
  props<{ error: any }>()
);

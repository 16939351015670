<kt-generic-portlet [title]="title" [actionsTemplate]="actionsTemplate">
  <kt-tasks-table
    [tasks]="tasks"
    [loading]="loading"
    (deleteTask)="deleteTask($event)"
  ></kt-tasks-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    color="primary"
    mat-raised-button
    [routerLink]="['import']"
    matTooltip="Import tasks from an xlsx or csv file"
  >
    <i class="fas fa-file-import import-button"></i> Import tasks
  </a>
</ng-template>

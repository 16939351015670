import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromProtocol from "../reducers/protocol.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectProtocolState = createFeatureSelector<
  fromProtocol.ProtocolState
>(fromProtocol.protocolFeatureKey);

export const selectProtocolsSelector = createSelector(
  selectProtocolState,
  fromProtocol.selectAll
);

//export const selectProtocols = pipe(select(selectProtocolsSelector), skip(1));

export const selectProtocolsLoading = createSelector(
  selectProtocolState,
  (state) => state.loading
);

export const selectCurrentProtocol = createSelector(
  selectProtocolState,
  (state) => state.currentProtocol
);

import { DronePayload } from "./drone-payload.model";
import { Material } from "src/app/core/models/material.model";

export class Protocol {
  constructor(
    public protocolId: string,
    public name: string,
    public material: Material,
    public payload: DronePayload,
    public nozzle: string,
    public marginSpace: number,
    public rowSpace: number,
    public dunamVolume: number,
    public waterVolume: number
  ) {}

  static parse(obj): Protocol {
    const material = Material.parse(obj.Material);
    const payload = DronePayload.parse(obj.Payload);

    const protocol = new Protocol(
      obj.protocolId,
      obj.name,
      material,
      payload,
      obj.nozzle,
      obj.marginSpace,
      obj.rowSpace,
      obj.dunamVolume,
      obj.waterVolume
    );
    return protocol;
  }

  static parseBack(protocol: Protocol): object {
    return {
      name: protocol.name,
      materialId: protocol.material.materialId,
      marginSpace: protocol.marginSpace,
      rowSpace: protocol.rowSpace,
      dunamVolume: protocol.dunamVolume,
      waterVolume: protocol.waterVolume,
      payloadId: protocol.payload.payloadId,
      nozzle: protocol.nozzle,
    };
  }

  static createEmpty() {
    return new Protocol(null, null, null, null, null, 0, 0, 0, 0);
  }
}

import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  concatMap,
  tap,
  withLatestFrom,
  filter,
} from "rxjs/operators";
import { EMPTY, of, pipe } from "rxjs";

import * as UserActions from "../actions/user.actions";
import { MessageType, LayoutUtilsService } from "../../_base/crud";
import { UsersService } from "../../services/users.service";
import { Router, ActivatedRoute } from "@angular/router";
import { selectRoleTypes } from "../selectors/user.selectors";
import { UserState } from "../reducers/user.reducer";

@Injectable()
export class UserEffects {
  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsers),
      concatMap(() =>
        this.usersService.getAllUsers().pipe(
          map((data) => {
            return UserActions.loadUsersSuccess({ data });
          }),
          catchError((error) => of(UserActions.loadUsersFailure({ error })))
        )
      )
    );
  });

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      concatMap((action) =>
        this.usersService.getUserById(action.data).pipe(
          map((data) => {
            return UserActions.loadUserSuccess({ data });
          }),
          catchError((error) => of(UserActions.loadUserFailure({ error })))
        )
      )
    );
  });

  loadRoleTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadRoleTypes),
      pipe(
        withLatestFrom(this.store.select(selectRoleTypes)),
        filter(([action, data]) => data == null)
      ),
      concatMap(() =>
        this.usersService.getAllRoleTypes().pipe(
          map((data) => {
            return UserActions.loadRoleTypesSuccess({ data });
          }),
          catchError((error) => of(UserActions.loadRoleTypesFailure({ error })))
        )
      )
    );
  });

  addUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.addUser),
      concatMap((action) =>
        this.usersService.addUser(action.data).pipe(
          map((data) => {
            return UserActions.addUserSuccess({ data });
          }),
          catchError((error) => of(UserActions.addUserFailure({ error })))
        )
      )
    );
  });

  addUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.addUserSuccess),
        tap((action) => {
          const message = `User successfully has been created.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );

          this.router.navigate(["user-management/users"], {
            relativeTo: this.route,
          });
        })
      ),
    { dispatch: false }
  );

  addUserFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.addUserFailure),
        tap((action) => {
          const message = `User add failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUser),
      concatMap((action) =>
        this.usersService.updateUser(action.data).pipe(
          map((data) => {
            return UserActions.updateUserSuccess({ data });
          }),
          catchError((error) => of(UserActions.updateUserFailure({ error })))
        )
      )
    );
  });

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.updateUserSuccess),
        tap((action) => {
          const message = `User successfully has been saved.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );

          this.router.navigate(["user-management/users"], {
            relativeTo: this.route,
          });
        })
      ),
    { dispatch: false }
  );

  updateUserFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.updateUserFailure),
        tap((action) => {
          const message = `User update failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUser),
      concatMap((action) =>
        this.usersService.deleteUser(action.data).pipe(
          map((data) => {
            return UserActions.deleteUserSuccess({ data });
          }),
          catchError((error) => of(UserActions.deleteUserFailure({ error })))
        )
      )
    );
  });

  deleteUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.deleteUserSuccess),
        tap((action) => {
          const message = `User successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<UserState>
  ) {}
}

<kt-generic-portlet [title]="'Protocols'" [actionsTemplate]="actionsTemplate">
  <kt-protocols-table
    [protocols]="protocols"
    [loading]="loading"
    (deleteProtocol)="deleteProtocol($event)"
  ></kt-protocols-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a color="primary" mat-raised-button [routerLink]="['add']">
    New Protocol
  </a>
</ng-template>

<div class="form kt-margin-b-30">
  <!-- start::FILTERS -->
  <div class="" *ngIf="showFilter">
    <div class="row align-items-center">
      <div class="col-6 kt-margin-bottom-10-mobile">
        <mat-form-field class="mat-form-field">
          <input
            (keyup)="applyFilter($event)"
            matInput
            placeholder="Search user"
            #input
            placeholder="Search"
          />
          <mat-hint align="start">
            <strong>Search</strong> in all fields
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- GROUP ACTIONS -->
  <div
    class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
    [ngClass]="{ show: selection.selected.length > 0 }"
  >
    <!-- We show 'Group Actions' div if smth are selected -->
    <div class="col-xl-12">
      <div class="form-group form-group-inline" *ngIf="selectActionsTemplate">
        <div class="form-label form-label-no-wrap">
          <label class="font-bold font-danger-">
            <span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
            Records selected count: {{ selection.selected.length }}
          </label>
        </div>
        <div>
          <ng-container
            [ngTemplateOutlet]="selectActionsTemplate"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- TABLE -->
  <mat-table [dataSource]="datasource" matSort>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
        <mat-checkbox
          *ngIf="hasMasterToggle"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [color]="'primary'"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="mat-column-checkbox">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="selectClicked(row)"
          [checked]="selection.isSelected(row)"
          [color]="'primary'"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container
      *ngFor="let col of columns"
      [matColumnDef]="col.clientFieldName"
    >
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="!col.sortable"
      >
        {{ col.displayNameKey }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-template
          [ngTemplateOutlet]="
            col.template != null ? col.template : genericTemplate
          "
          [ngTemplateOutletContext]="{
            row: row,
            field: col.clientFieldName
          }"
        ></ng-template>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>

    <mat-row
      [ngClass]="{ rowhover: hover }"
      *matRowDef="let row; columns: displayedColumns"
      [@rowsAnimation]=""
      (click)="rowClicked.emit(row)"
    ></mat-row>
  </mat-table>

  <ng-template #genericTemplate let-row="row" let-field="field">
    {{ row[field] }}
  </ng-template>

  <div *ngIf="loading">
    <mat-card class="table-spinner">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
        [diameter]="20"
      >
      </mat-progress-spinner>
    </mat-card>
  </div>

  <div>
    <mat-paginator
      #matPaginator
      [length]="datasourceOptions?.totalAmount"
      [pageSize]="datasourceOptions?.perPage"
      [pageSizeOptions]="[5, 10, 25, 50]"
    ></mat-paginator>
  </div>
</div>

import { Action, createReducer, on } from "@ngrx/store";
import * as MaterialActions from "../actions/material.actions";
import { Material } from "../../models/material.model";
import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";

export const materialFeatureKey = "material";

export interface MaterialState extends EntityState<Material> {
  loading: boolean;
  currentMaterial: Material;
}

export const adapter: EntityAdapter<Material> = createEntityAdapter<Material>({
  selectId: (material: Material) => material.materialId,
});

export const initialMaterialState: MaterialState = adapter.getInitialState({
  loading: false,
  currentMaterial: null,
});

const materialReducer = createReducer(
  initialMaterialState,

  on(MaterialActions.loadMaterials, (state) => state),
  on(MaterialActions.loadMaterialsSuccess, (state, action) => {
    return { ...adapter.setAll(action.data, state), loading: false };
  }),
  on(MaterialActions.loadMaterialsFailure, (state, action) => state),

  on(MaterialActions.addMaterial, (state) => ({
    ...state,
    loading: true,
  })),
  on(MaterialActions.addMaterialSuccess, (state, action) => {
    return { ...adapter.addOne(action.data, state), loading: false };
  }),
  on(MaterialActions.addMaterialFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(MaterialActions.updateMaterial, (state) => ({
    ...state,
    loading: true,
  })),
  on(MaterialActions.updateMaterialSuccess, (state, action) => {
    return { ...adapter.updateOne(action.data, state), loading: false };
  }),
  on(MaterialActions.updateMaterialFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(MaterialActions.deleteMaterial, (state) => ({
    ...state,
    loading: true,
  })),
  on(MaterialActions.deleteMaterialSuccess, (state, action) => {
    return {
      ...adapter.removeOne(action.data.materialId, state),
      loading: false,
    };
  }),
  on(MaterialActions.addMaterialFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(MaterialActions.loadMaterial, (state) => ({
    ...state,
    loading: true,
  })),
  on(MaterialActions.loadMaterialSuccess, (state, action) => {
    return { ...state, currentMaterial: action.data, loading: false };
  }),
  on(MaterialActions.loadMaterialFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: MaterialState | undefined, action: Action) {
  return materialReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

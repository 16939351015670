<kt-datatable
  *ngIf="mapTasks?.length > 0; else hint"
  [data]="mapTasks"
  (loadData)="loadData.emit($event)"
  [columns]="columns"
  showSelect="true"
  [showFilter]="false"
  [selectAllByDefault]="true"
  [hover]="true"
  [hasMasterToggle]="false"
  [selectActionsTemplate]="selectActionsTemplate"
  (selectionChanged)="mapTaskSelected($event)"
  (rowClicked)="mapTaskClicked($event)"
></kt-datatable>

<ng-template #selectActionsTemplate>
  <button mat-raised-button class="mat-button-mt-4" (click)="assignTasks()">
    <mat-icon>clear_all</mat-icon>
    Assign selected fields
  </button>
</ng-template>

<ng-template #hint>
  <div class="card-border hint text-center">
    <h2>Select fields on the map to see them here</h2>
    <h6>Use the rectangle tool <img width="25px" height="25px" src='./assets/media/misc/select-tool.PNG' /> to select multiple fields</h6>
  </div>
</ng-template>

<ng-template #cropNameTemplate let-row="row">
  {{ row.task.field.cropName }}
</ng-template>

<ng-template #dunamTemplate let-row="row">
  {{ row.task.protocol.dunamVolume }}
</ng-template>

<ng-template #actionsTemplate let-row="row">
  <button
    mat-icon-button
    color="primary"
    matTooltip="Edit task"
    (click)="editTaskClick(row)"
  >
    <mat-icon>create</mat-icon>
  </button>
</ng-template>

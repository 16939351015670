import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Protocol } from "src/app/core/models/protocol.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-protocols-table",
  templateUrl: "./protocols-table.component.html",
  styleUrls: ["./protocols-table.component.scss"],
})
export class ProtocolsTableComponent implements OnInit, AfterViewInit {
  @Input() protocols: Protocol[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deleteProtocol = new EventEmitter<Protocol>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild("payloadTemplate", { static: false }) payloadTemplate: TemplateRef<
    any
  >;
  @ViewChild("materialTemplate", { static: false })
  materialTemplate: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "name",
          clientFieldName: "name",
          displayNameKey: "Name",
          sortable: true,
        },
        {
          serverFieldName: "material",
          clientFieldName: "material",
          displayNameKey: "Material",
          sortable: true,
          template: this.materialTemplate,
        },
        {
          serverFieldName: "payload",
          clientFieldName: "payload",
          displayNameKey: "Payload",
          sortable: true,
          template: this.payloadTemplate,
        },
        {
          serverFieldName: "nozzle",
          clientFieldName: "nozzle",
          displayNameKey: "Nozzle",
          sortable: true,
        },
        {
          serverFieldName: "marginSpace",
          clientFieldName: "marginSpace",
          displayNameKey: "Margin Space",
          sortable: true,
        },
        {
          serverFieldName: "rowSpace",
          clientFieldName: "rowSpace",
          displayNameKey: "Row Space",
          sortable: true,
        },
        {
          serverFieldName: "dunamVolume",
          clientFieldName: "dunamVolume",
          displayNameKey: "Dunam Volume",
          sortable: true,
        },
        {
          serverFieldName: "waterVolume",
          clientFieldName: "waterVolume",
          displayNameKey: "Water Volume",
          sortable: true,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  editProtocolClick(protocol: Protocol) {
    this.router.navigate([protocol.protocolId], { relativeTo: this.route });
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { Customer } from "src/app/core/models/customer.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-customers-table",
  templateUrl: "./customers-table.component.html",
  styleUrls: ["./customers-table.component.scss"],
})
export class CustomersTableComponent implements OnInit, AfterViewInit {
  @Input() customers: Customer[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deleteCustomer = new EventEmitter<Customer>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;

  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "name",
          clientFieldName: "name",
          displayNameKey: "Name",
          sortable: true,
        },
        {
          serverFieldName: "phone",
          clientFieldName: "phone",
          displayNameKey: "Phone",
          sortable: true,
        },
        {
          serverFieldName: "email",
          clientFieldName: "email",
          displayNameKey: "Email",
          sortable: true,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  editCustomerClick(customer: Customer) {
    this.router.navigate([customer.customerId], { relativeTo: this.route });
  }

  goToCustomerFields(customer: Customer) {
    this.router.navigateByUrl("fields/" + customer.customerId);
  }
}

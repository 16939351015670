import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "../actions/auth.actions";
import { User } from "../../auth/_models/user.model";

export const authFeatureKey = "auth";

export interface AuthState {
  loggedIn: boolean;
  currentUser: User;
  error: any;
  loading: boolean;
}

export const initialState: AuthState = {
  loggedIn: false,
  currentUser: null,
  error: null,
  loading: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.login, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.loginSuccess, (state) => ({
    ...state,
    loading: false,
    loggedIn: true,
  })),
  on(AuthActions.loginFailure, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    loggedIn: false,
    currentUser: null,
  })),

  on(AuthActions.loadUserProfile, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.loadUserProfileSuccess, (state, action) => {
    return {
      ...state,
      currentUser: action.data,
      loading: false,
    };
  }),
  on(AuthActions.loadUserProfileFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DronePilotsComponent } from './drone-pilots.component';
import { EditDronePilotComponent } from './edit-drone-pilot/edit-drone-pilot.component';
import { DronePilotsTableComponent } from './drone-pilots-list/drone-pilots-table/drone-pilots-table.component';
import { DronePilotsListComponent } from './drone-pilots-list/drone-pilots-list.component';
import { MatCoreModule } from 'src/app/core/utils/mat-core.module';
import { HttpClientModule } from '@angular/common/http';
import { PartialsModule } from '../../partials/partials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import * as fromDronePilots from '../../../core/store/reducers/drone-pilots.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DronePilotsEffects } from '../../../core/store/effects/drone-pilots.effects';


const routes: Routes = [
  {
    path: "",
    component: DronePilotsComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        component: DronePilotsListComponent,
      },
      {
        path: ":id",
        component: EditDronePilotComponent,
      },
      {
        path: "add",
        component: EditDronePilotComponent,
      },
    ],
  },
];


@NgModule({
  declarations: [DronePilotsComponent, EditDronePilotComponent, DronePilotsTableComponent, DronePilotsListComponent],
  imports: [
    CommonModule,
    MatCoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(fromDronePilots.dronePilotsFeatureKey, fromDronePilots.reducer),
    EffectsModule.forFeature([DronePilotsEffects])
  ]
})
export class DronePilotsModule { }

<kt-generic-portlet
  [title]="title"
  [actionsTemplate]="actionsTemplate"
  [loading]="loading$"
>
  <form
    [formGroup]="materialForm"
    class="form form-group-seperator-dashed"
    *ngIf="material"
  >
    <kt-alert
      *ngIf="hasFormErrors"
      type="warn"
      [showCloseButton]="true"
      [duration]="10000"
      (close)="onAlertClose($event)"
    >
      Oh snap! Change a few things up and try submitting again.
    </kt-alert>
    <div class="form-section form-section-first">
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Enter name" formControlName="name" />
            <mat-error
              >Name is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>name</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <!-- <a
    href="javascript:;"
    class="btn btn-secondary mr-2"
    (click)="reset()"
    [disabled]="selectedTab !== 0"
    mat-raised-button
    matTooltip="Reset changes"
  >
    <i class="la la-cog"></i>
    <span class="hidden-mobile">Reset</span>
  </a> -->
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
  >
    <span class="hidden-mobile">Save</span>
  </a>

  <a
    [routerLink]="['../']"
    class="btn btn-secondary mr-2"
    mat-raised-button
    matTooltip="Back to the materials list"
  >
    <i class="la la-arrow-left"></i>
    <span class="hidden-mobile">Back</span>
  </a>
  <!-- <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item color="primary" (click)="onSumbit(true)">
      Save & Exit
    </button>
    <button mat-menu-item color="primary">Save & Duplicate</button>
    <button mat-menu-item color="primary" (click)="onSumbit(false)">
      Save & Continue
    </button>
  </mat-menu> -->
</ng-template>

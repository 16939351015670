import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as ProtocolActions from "../actions/protocol.actions";
import { MessageType, LayoutUtilsService } from "../../_base/crud";
import { ProtocolsService } from "../../services/protocols.service";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable()
export class ProtocolEffects {
  loadProtocols$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.loadProtocols),
      concatMap(() =>
        this.protocolsService.getAllProtocols().pipe(
          map((data) => {
            return ProtocolActions.loadProtocolsSuccess({ data });
          }),
          catchError((error) =>
            of(ProtocolActions.loadProtocolsFailure({ error }))
          )
        )
      )
    );
  });

  loadProtocol$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.loadProtocol),
      concatMap((action) =>
        this.protocolsService.getProtocolById(action.data).pipe(
          map((data) => {
            return ProtocolActions.loadProtocolSuccess({ data });
          }),
          catchError((error) =>
            of(ProtocolActions.loadProtocolFailure({ error }))
          )
        )
      )
    );
  });

  addProtocol$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.addProtocol),
      concatMap((action) =>
        this.protocolsService.addProtocol(action.data).pipe(
          map((data) => {
            return ProtocolActions.addProtocolSuccess({ data });
          }),
          catchError((error) =>
            of(ProtocolActions.addProtocolFailure({ error }))
          )
        )
      )
    );
  });

  addProtocolSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtocolActions.addProtocolSuccess),
        tap((action) => {
          const message = `Protocol successfully has been created.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );

          this.router.navigate(["protocols"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  addProtocolFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtocolActions.addProtocolFailure),
        tap((action) => {
          const message = `Protocol add failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  updateProtocol$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.updateProtocol),
      concatMap((action) =>
        this.protocolsService.updateProtocol(action.data).pipe(
          map((data) => {
            return ProtocolActions.updateProtocolSuccess({ data });
          }),
          catchError((error) =>
            of(ProtocolActions.updateProtocolFailure({ error }))
          )
        )
      )
    );
  });

  updateProtocolSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtocolActions.updateProtocolSuccess),
        tap((action) => {
          const message = `Protocol successfully has been saved.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );

          this.router.navigate(["protocols"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  updateProtocolFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtocolActions.updateProtocolFailure),
        tap((action) => {
          const message = `Protocol update failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  deleteProtocol$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProtocolActions.deleteProtocol),
      concatMap((action) =>
        this.protocolsService.deleteProtocol(action.data).pipe(
          map((data) => {
            return ProtocolActions.deleteProtocolSuccess({ data });
          }),
          catchError((error) =>
            of(ProtocolActions.deleteProtocolFailure({ error }))
          )
        )
      )
    );
  });

  deleteProtocolSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtocolActions.deleteProtocolSuccess),
        tap((action) => {
          const message = `Protocol successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private protocolsService: ProtocolsService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { Store, select } from "@ngrx/store";
import { LayoutUtilsService, MessageType } from "src/app/core/_base/crud";
import { DronePilotsState } from "src/app/core/store/reducers/drone-pilots.reducer";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  selectPilotsLoading,
  selectPilotsSelector,
} from "src/app/core/store/selectors/drone-pilots.selectors";
import {
  loadDronePilots,
  deleteDronePilot,
  loadPilotTypes,
} from "src/app/core/store/actions/drone-pilots.actions";

@Component({
  selector: "kt-drone-pilots-list",
  templateUrl: "./drone-pilots-list.component.html",
  styleUrls: ["./drone-pilots-list.component.scss"],
})
export class DronePilotsListComponent implements OnInit, OnDestroy {
  pilots: DronePilot[];
  loading = false;

  constructor(
    private store: Store<DronePilotsState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectPilotsLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.store
      .pipe(select(selectPilotsSelector), untilDestroyed(this))
      .subscribe((pilots) => {
        this.pilots = pilots;
      });

    this.store.dispatch(loadDronePilots());
  }

  deletePilot(_item: DronePilot) {
    const _title = "Drone Pilot Delete";
    const _description = "Are you sure to permanently delete this pilot?";
    const _waitDesciption = "Drone Pilot is deleting...";
    const _deleteMessage = `Drone Pilot has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteDronePilot({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  ngOnDestroy(): void {}
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialsListComponent } from "./materials-list/materials-list.component";
import { EditMaterialsComponent } from "./edit-materials/edit-materials.component";
import { MaterialsTableComponent } from "./materials-list/materials-table/materials-table.component";
import { MatCoreModule } from "src/app/core/utils/mat-core.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PartialsModule } from "../../partials/partials.module";
import { Routes, RouterModule } from "@angular/router";
import { MaterialsComponent } from "./materials.component";
import { StoreModule } from "@ngrx/store";
import * as fromMaterial from "../../../core/store/reducers/material.reducer";
import { EffectsModule } from "@ngrx/effects";
import { MaterialEffects } from "../../../core/store/effects/material.effects";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ModuleGuard } from "src/app/core/auth";

const routes: Routes = [
  {
    path: "",
    component: MaterialsComponent,
    canActivate: [ModuleGuard],
    data: {
      permissions: {
        only: "Admin",
        redirectTo: "dashboard",
      },
    },
    children: [
      {
        path: "",
        pathMatch: "full",
        component: MaterialsListComponent,
      },
      {
        path: ":id",
        component: EditMaterialsComponent,
      },
      {
        path: "add",
        component: EditMaterialsComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    MaterialsComponent,
    MaterialsListComponent,
    EditMaterialsComponent,
    MaterialsTableComponent,
  ],
  imports: [
    CommonModule,
    MatCoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(
      fromMaterial.materialFeatureKey,
      fromMaterial.reducer
    ),
    EffectsModule.forFeature([MaterialEffects]),
  ],
})
export class MaterialsModule {}

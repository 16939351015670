<kt-generic-portlet
  [title]="title"
  [actionsTemplate]="actionsTemplate"
  [loading]="loading$"
>
  <form
    [formGroup]="userForm"
    class="form form-group-seperator-dashed"
    *ngIf="user"
  >
    <kt-alert
      *ngIf="hasFormErrors"
      type="warn"
      [showCloseButton]="true"
      [duration]="10000"
      (close)="onAlertClose($event)"
    >
      Oh snap! Change a few things up and try submitting again.
    </kt-alert>
    <div class="form-section form-section-first">
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              autocomplete="off"
              matInput
              placeholder="Enter username"
              formControlName="username"
            />
            <mat-error
              >Username is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>username</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="tel"
              placeholder="Enter phone"
              formControlName="phone"
            />
            <mat-error
              >phone is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter a valid
              <strong>phone</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="email"
              placeholder="Enter email"
              formControlName="email"
            />
            <mat-error
              >Email is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter a valid
              <strong>email</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select
              matInput
              formControlName="roleType"
              placeholder="Select a role"
            >
              <mat-option
                *ngFor="let type of roleTypes"
                [value]="type.roleTypeId"
                >{{ type.name }}</mat-option
              >
            </mat-select>
            <mat-error
              >Role is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please select a
              <strong>role type</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <!-- <kt-mat-select-filter
            [dataSet]="customers"
            [filterBy]="'name'"
            [valueProperty]="'customerId'"
            [selectFormControl]="customersSelectFormControl"
            [error]="'Customer is required'"
            [hint]="'Please select a customer'"
            [placeHolder]="'Select a customer'"
          >
          </kt-mat-select-filter> -->
          <kt-entity-select
            ngDefaultControl
            [entityName]="'customers'"
            [filterBy]="'name'"
            [valueProperty]="'customerId'"
            [formControl]="customersSelectFormControl"
            [error]="'Customer is required'"
            [hint]="'Please select a customer'"
            [placeHolder]="'Select a customer'"
          ></kt-entity-select>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <!-- <kt-mat-select-filter
            [dataSet]="pilots"
            [filterBy]="'name'"
            [valueProperty]="'pilotId'"
            [selectFormControl]="pilotsSelectFormControl"
            [hint]="'Choose a pilot to filter by'"
            [placeHolder]="'Select a pilot'"
          >
          </kt-mat-select-filter> -->
          <kt-entity-select
            ngDefaultControl
            [entityName]="'pilots'"
            [filterBy]="'name'"
            [valueProperty]="'pilotId'"
            [formControl]="pilotsSelectFormControl"
            [error]="'Pilot is required'"
            [hint]="'Choose a pilot to filter by'"
            [placeHolder]="'Select a pilot'"
          ></kt-entity-select>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="password"
              placeholder="Enter password"
              formControlName="password"
            />
            <mat-error
              >Password is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>password</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-checkbox formControlName="viewFromAnyDate"
            >View tasks from any date (Pilots)</mat-checkbox
          >
        </div>
      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <!-- <a
    href="javascript:;"
    class="btn btn-secondary mr-2"
    (click)="reset()"
    [disabled]="selectedTab !== 0"
    mat-raised-button
    matTooltip="Reset changes"
  >
    <i class="la la-cog"></i>
    <span class="hidden-mobile">Reset</span>
  </a> -->
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
  >
    <span class="hidden-mobile">Save</span>
  </a>

  <a
    [routerLink]="['../']"
    class="btn btn-secondary mr-2"
    mat-raised-button
    matTooltip="Back to the users list"
  >
    <i class="la la-arrow-left"></i>
    <span class="hidden-mobile">Back</span>
  </a>
  <!-- <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item color="primary" (click)="onSumbit(true)">
      Save & Exit
    </button>
    <button mat-menu-item color="primary">Save & Duplicate</button>
    <button mat-menu-item color="primary" (click)="onSumbit(false)">
      Save & Continue
    </button>
  </mat-menu> -->
</ng-template>

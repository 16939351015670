import { loadDronePilots } from "./../../../core/store/actions/drone-pilots.actions";
import { loadMaterials } from "./../../../core/store/actions/material.actions";
import { loadProtocols } from "./../../../core/store/actions/protocol.actions";
import { loadStatuses } from "./../../../core/store/actions/task.actions";
import { loadFields } from "./../../../core/store/actions/field.actions";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { loadCustomers } from "src/app/core/store/actions/customer.actions";
import { loadDrones } from "src/app/core/store/actions/drones.actions";

@Component({
  selector: "kt-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.scss"],
})
export class TasksComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(loadCustomers());
    //this.store.dispatch(loadDrones());
    this.store.dispatch(loadStatuses());
    this.store.dispatch(loadProtocols());
    this.store.dispatch(loadMaterials());
    this.store.dispatch(loadDronePilots());
  }
}

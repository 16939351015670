<mat-form-field class="mat-form-field-fluid">
  <mat-select
    [multiple]="multiple"
    matInput
    [formControl]="selectFormControl"
    [placeholder]="placeHolder"
    (selectionChange)="selectionChange($event.value)"
  >
    <kt-mat-select-search
      [formControl]="searchFormControl"
    ></kt-mat-select-search>
    <mat-option [value]="null" *ngIf="selectFormControl.value && !multiple">
      <span [ngStyle]="{ color: 'gray' }">None</span>
    </mat-option>
    <mat-option
      *ngFor="let data of filteredData | async"
      [value]="data[valueProperty]"
      >{{ data[filterBy] }}</mat-option
    >
  </mat-select>
  <mat-error>{{ error }} </mat-error>
  <mat-hint align="start">{{ hint }} </mat-hint>
</mat-form-field>

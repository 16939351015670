import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Task } from "src/app/core/models/task.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-tasks-table",
  templateUrl: "./tasks-table.component.html",
  styleUrls: ["./tasks-table.component.scss"],
})
export class TasksTableComponent implements OnInit, AfterViewInit {
  @Input() tasks: Task[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deleteTask = new EventEmitter<Task>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild("materialTemplate", { static: false })
  materialTemplate: TemplateRef<any>;

  @ViewChild("customerTemplate", { static: false })
  customerTemplate: TemplateRef<any>;

  @ViewChild("protocolTemplate", { static: false })
  protocolTemplate: TemplateRef<any>;

  @ViewChild("fieldTemplate", { static: false })
  fieldTemplate: TemplateRef<any>;

  @ViewChild("statusTemplate", { static: false })
  statusTemplate: TemplateRef<any>;

  @ViewChild("createdAtTemplate", { static: false })
  createdAtTemplate: TemplateRef<any>;

  @ViewChild("executionDate", { static: false })
  executionDate: TemplateRef<any>;

  @ViewChild("pilotTemplate", { static: false })
  pilotTemplate: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {

          clientFieldName: "customer",
          displayNameKey: "Customer",
          sortBy: "customer.name",
          sortable: true,
          template: this.customerTemplate,
        },
        {
          clientFieldName: "material",
          displayNameKey: "Material",
          sortBy: "material.name",
          sortable: true,
          template: this.materialTemplate,
        },
        {
          clientFieldName: "protocol",
          displayNameKey: "Protocol",
          sortBy: "protocol.name",
          sortable: true,
          template: this.protocolTemplate,
        },
        {
          clientFieldName: "field",
          displayNameKey: "Field (Plot number)",
          sortable: true,
          sortBy: "field.plotNumber",
          template: this.fieldTemplate,
        },
        {
          clientFieldName: "pilot",
          displayNameKey: "Pilot",
          sortable: true,
          sortBy: "pilot.name",
          template: this.pilotTemplate,
        },
        {
          clientFieldName: "status",
          displayNameKey: "Scheduled Status",
          sortable: true,
          sortBy: "status.name",
          template: this.statusTemplate,
        },
        {
          clientFieldName: "createdAt",
          displayNameKey: "Created",
          sortable: true,
          template: this.createdAtTemplate,
        },
        {
          clientFieldName: "executionDate",
          displayNameKey: "Scheduled Date",
          sortable: true,
          template: this.executionDate,
        },
        {
          clientFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  editTaskClick(task: Task) {
    this.router.navigate(["edit", task.taskId], { relativeTo: this.route });
  }
}

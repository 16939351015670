import { ControllerAssignSelectedComponent } from "./../controller-assign-selected/controller-assign-selected.component";
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { MapTask } from "src/app/core/models/map-task.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Store } from "@ngrx/store";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {
  mapTaskSelected,
  mapTaskTableClick,
  controllerMapTaskSelected,
} from "src/app/core/store/actions/task.actions";
import { AssignSelectedFormComponent } from "../../assign-tasks/assign-selected-form/assign-selected-form.component";

@Component({
  selector: "kt-controller-table",
  templateUrl: "./controller-table.component.html",
  styleUrls: ["./controller-table.component.scss"],
})
export class ControllerTableComponent implements OnInit, AfterViewInit {
  @Input() mapTasks: MapTask[];
  @Output() loadData = new EventEmitter<DatasourceOptions>();

  @ViewChild("cropNameTemplate") cropNameTemplate: TemplateRef<any>;
  @ViewChild("dunamTemplate") dunamTemplate: TemplateRef<any>;

  @ViewChild("statusTemplate") statusTemplate: TemplateRef<any>;
  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;

  columns: DataTableColumn[];

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "cropName",
          clientFieldName: "cropName",
          displayNameKey: "Crop Name",
          sortable: true,
          template: this.cropNameTemplate,
        },
        {
          serverFieldName: "dunamVolume",
          clientFieldName: "dunamVolume",
          displayNameKey: "Dunam Volume",
          sortable: true,
          template: this.dunamTemplate,
        },

        {
          serverFieldName: "status",
          clientFieldName: "status",
          displayNameKey: "Status",
          sortable: true,
          template: this.statusTemplate,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  mapTaskSelected($event) {
    this.store.dispatch(
      controllerMapTaskSelected({
        mapTask: $event.row,
        selected: !$event.row.isSelected,
      })
    );
  }

  mapTaskClicked(mapTask: MapTask) {
    this.store.dispatch(mapTaskTableClick({ data: mapTask }));
  }

  assignTaskStatus() {
    let dialogRef = this.dialog.open(ControllerAssignSelectedComponent, {
      data: this.mapTasks,
      width: "350px",
    });
  }

  editTaskClick(mapTask: MapTask) {
    this.router.navigate(["edit/" + mapTask.task.taskId], {
      relativeTo: this.route,
    });
  }
}

import { createAction, props } from "@ngrx/store";
import { Field } from "../../models/field.model";
import { FieldImportWrapper } from "../../models/field-import-wrapper.model";

export const loadFields = createAction("[Field] Load Fields");

export const loadFieldsSuccess = createAction(
  "[Field] Load Fields Success",
  props<{ data: any }>()
);

export const loadFieldsFailure = createAction(
  "[Field] Load Fields Failure",
  props<{ error: any }>()
);

export const loadField = createAction(
  "[Field] Load Field",
  props<{ data: any }>()
);

export const loadFieldSuccess = createAction(
  "[Field] Load Field Success",
  props<{ data: any }>()
);

export const loadFieldFailure = createAction(
  "[Field] Load Field Failure",
  props<{ error: any }>()
);

export const addField = createAction(
  "[Field] AddField",
  props<{ data: any }>()
);

export const addFieldSuccess = createAction(
  "[Field] AddField Success",
  props<{ data: Field }>()
);

export const addFieldFailure = createAction(
  "[Field] AddField Failure",
  props<{ error: any }>()
);

export const updateField = createAction(
  "[Field] UpdateField",
  props<{ data: any }>()
);

export const updateFieldSuccess = createAction(
  "[Field] UpdateField Success",
  props<{ data: any }>()
);

export const updateFieldFailure = createAction(
  "[Field] UpdateField Failure",
  props<{ error: any }>()
);

export const deleteField = createAction(
  "[Field] DeleteField",
  props<{ data: any }>()
);

export const deleteFieldSuccess = createAction(
  "[Field] DeleteField Success",
  props<{ data: any }>()
);

export const deleteFieldFailure = createAction(
  "[Field] DeleteField Failure",
  props<{ error: any }>()
);

export const importFields = createAction(
  "[Field] ImportFields",
  props<{ data: FieldImportWrapper }>()
);

export const importFieldsSuccess = createAction(
  "[Field] ImportFields Success",
  props<{ data: any }>()
);

export const importFieldsFailure = createAction(
  "[Field] ImportFields Failure",
  props<{ error: any }>()
);

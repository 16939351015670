import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Drone } from "src/app/core/models/drone.model";
import { Store, select } from "@ngrx/store";
import { DroneState } from "src/app/core/store/reducers/drones.reducer";
import {
  selectDroneById,
  selectDronesLoading,
  selectCurrentDrone,
  selectModelsSelector,
  selectPayloadsSelector,
} from "src/app/core/store/selectors/drones.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import * as _ from "lodash";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  addDrone,
  updateDrone,
  loadDrone,
} from "src/app/core/store/actions/drones.actions";
import { Observable, combineLatest, pipe } from "rxjs";
import { filter, zip, debounceTime } from "rxjs/operators";
import { DroneModel } from "src/app/core/models/drone-model.model";
import { DronePayload } from "src/app/core/models/drone-payload.model";
import { cloneDeep } from "lodash";

@Component({
  selector: "kt-edit-drone",
  templateUrl: "./edit-drone.component.html",
  styleUrls: ["./edit-drone.component.scss"],
})
export class EditDroneComponent implements OnInit, OnDestroy {
  title = "Add Drone";
  isEdit = false;
  drone: Drone;
  droneForm: FormGroup;
  hasFormErrors = false;

  droneModels: DroneModel[];
  dronePayloads: DronePayload[];

  loading$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<DroneState>,
    private droneFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectDronesLoading),
      untilDestroyed(this)
    );

    this.drone = Drone.createEmpty();

    combineLatest(
      this.store.pipe(select(selectModelsSelector), untilDestroyed(this)),
      this.store.pipe(select(selectPayloadsSelector), untilDestroyed(this)),
      this.store.pipe(select(selectCurrentDrone), untilDestroyed(this))
    ).subscribe(([models, payloads, currentDrone]) => {
      this.droneModels = models;
      this.dronePayloads = payloads;

      if (currentDrone && this.isEdit) {
        this.drone = cloneDeep(currentDrone);
      }

      this.createForm();
    });

    let droneId = this.route.snapshot.params["id"];

    if (droneId && droneId != "add") {
      this.isEdit = true;
      this.title = "Edit Drone";

      this.store.dispatch(loadDrone({ data: droneId }));
    }
  }

  createForm() {
    this.droneForm = this.droneFB.group({
      tailNumber: [this.drone.tailNumber, Validators.required],
      payload: [this.drone.payload?.payloadId, Validators.required],
      model: [this.drone.model?.modelId, Validators.required],
      blackBoxId: [this.drone.blackBoxId, Validators.required],
    });
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.droneForm.controls;
    /** check form */
    if (this.droneForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.prepareDrone();

    if (this.isEdit) {
      this.store.dispatch(updateDrone({ data: this.drone }));
    } else {
      this.store.dispatch(addDrone({ data: this.drone }));
    }
  }

  prepareDrone() {
    this.drone.tailNumber = this.droneForm.controls.tailNumber.value;
    this.drone.blackBoxId = this.droneForm.controls.blackBoxId.value;
    this.drone.payload = this.dronePayloads.find(
      (p) => p.payloadId == this.droneForm.controls.payload.value
    );
    this.drone.model = this.droneModels.find(
      (m) => m.modelId == this.droneForm.controls.model.value
    );
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { CustomersComponent } from "./customers.component";
import { CustomersListComponent } from "./customers-list/customers-list.component";
import { EditCustomerComponent } from "./edit-customer/edit-customer.component";
import { FieldsListComponent } from "../fields/fields-list/fields-list.component";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ModuleGuard } from "src/app/core/auth";

const routes: Routes = [
  {
    path: "",
    component: CustomersComponent,
    canActivate: [ModuleGuard],
    data: {
      permissions: {
        only: "Admin",
        redirectTo: "dashboard",
      },
    },
    children: [
      {
        path: "",
        pathMatch: "full",
        component: CustomersListComponent,
      },
      {
        path: ":id",
        component: EditCustomerComponent,
      },
      // {
      //   path: "field-list/:id",
      //   component: FieldsListComponent,
      // },
      {
        path: "add",
        component: EditCustomerComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomersRoutingModule {}

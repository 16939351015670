import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ReplaySubject } from "rxjs";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
  selector: "kt-mat-select-filter",
  templateUrl: "./mat-select-filter.component.html",
  styleUrls: ["./mat-select-filter.component.scss"],
})
export class MatSelectFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dataSet;
  @Input() filterBy: string;
  @Input() valueProperty: string;
  @Input() selectFormControl: FormControl = new FormControl();
  @Input() error = "Incorrect input";
  @Input() hint = "Please enter data";
  @Input() placeHolder = "Select";
  @Input() multiple = false;
  @Input() primitiveValue = true;
  searchFormControl: FormControl = new FormControl();

  @Output() valueChanged = new EventEmitter();

  filteredData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  value = [];

  constructor() {}

  ngOnInit(): void {
    // Subscribe to filter event
    this.searchFormControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.filterData();
      });
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes?.dataSet && this.dataSet) {
      this.filteredData.next(this.dataSet.slice());
    }
  }

  private filterData() {
    if (!this.dataSet) {
      return;
    }
    // get the search keyword
    let search = this.searchFormControl.value;
    if (!search) {
      this.filteredData.next(this.dataSet.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter
    this.filteredData.next(
      this.dataSet.filter(
        (d) => d[this.filterBy].toLowerCase().indexOf(search) > -1
      )
    );
  }

  selectionChange($event) {
    let value = $event;

    if (!this.primitiveValue) {
      value = this.dataSet.find((i) => i[this.valueProperty] == $event);
    }

    if (this.multiple) {
      if (!this.searchFormControl.value) {
        this.value.push(...value);
      } else {
        let values = [...value, ...this.value];
        this.selectFormControl.setValue(
          values.filter((value, index, self) => self.indexOf(value) === index)
        );
        let val = this.selectFormControl.value;
      }
    }

    this.valueChanged.emit(value);
  }

  ngOnDestroy(): void {}
}

import { createAction, props } from "@ngrx/store";
import { Protocol } from "../../models/protocol.model";

export const loadProtocols = createAction("[Protocol] Load Protocols");

export const loadProtocolsSuccess = createAction(
  "[Protocol] Load Protocols Success",
  props<{ data: any }>()
);

export const loadProtocolsFailure = createAction(
  "[Protocol] Load Protocols Failure",
  props<{ error: any }>()
);

export const loadProtocol = createAction(
  "[Protocol] Load Protocol",
  props<{ data: any }>()
);

export const loadProtocolSuccess = createAction(
  "[Protocol] Load Protocol Success",
  props<{ data: any }>()
);

export const loadProtocolFailure = createAction(
  "[Protocol] Load Protocol Failure",
  props<{ error: any }>()
);

export const addProtocol = createAction(
  "[Protocol] AddProtocol",
  props<{ data: any }>()
);

export const addProtocolSuccess = createAction(
  "[Protocol] AddProtocol Success",
  props<{ data: Protocol }>()
);

export const addProtocolFailure = createAction(
  "[Protocol] AddProtocol Failure",
  props<{ error: any }>()
);

export const updateProtocol = createAction(
  "[Protocol] UpdateProtocol",
  props<{ data: any }>()
);

export const updateProtocolSuccess = createAction(
  "[Protocol] UpdateProtocol Success",
  props<{ data: any }>()
);

export const updateProtocolFailure = createAction(
  "[Protocol] UpdateProtocol Failure",
  props<{ error: any }>()
);

export const deleteProtocol = createAction(
  "[Protocol] DeleteProtocol",
  props<{ data: any }>()
);

export const deleteProtocolSuccess = createAction(
  "[Protocol] DeleteProtocol Success",
  props<{ data: any }>()
);

export const deleteProtocolFailure = createAction(
  "[Protocol] DeleteProtocol Failure",
  props<{ error: any }>()
);

<kt-generic-portlet
  [title]="title"
  [actionsTemplate]="actionsTemplate"
  [loading]="loading$"
>
  <form
    [formGroup]="protocolForm"
    class="form form-group-seperator-dashed"
    *ngIf="protocol"
  >
    <kt-alert
      *ngIf="hasFormErrors"
      type="warn"
      [showCloseButton]="true"
      [duration]="10000"
      (close)="onAlertClose($event)"
    >
      Oh snap! Change a few things up and try submitting again.
    </kt-alert>
    <div class="form-section form-section-first">
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Enter name" formControlName="name" />
            <mat-error
              >Name is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>name</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="materials"
            [filterBy]="'name'"
            [valueProperty]="'materialId'"
            [selectFormControl]="materialSelectControl"
            [error]="'Material is required'"
            [hint]="'Please select a material'"
            [placeHolder]="'Select a material'"
          >
          </kt-mat-select-filter>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="payloads"
            [filterBy]="'name'"
            [valueProperty]="'payloadId'"
            [selectFormControl]="payloadSelectControl"
            [error]="'Payload is required'"
            [hint]="'Please select a payload'"
            [placeHolder]="'Select a payload'"
          >
          </kt-mat-select-filter>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="number"
              placeholder="Enter margin space"
              formControlName="marginSpace"
            />
            <mat-error
              >Margin space is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>margin space</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="number"
              placeholder="Enter row space"
              formControlName="rowSpace"
            />
            <mat-error
              >Row space is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>row space</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="number"
              placeholder="Enter dunam volume"
              formControlName="dunamVolume"
            />
            <mat-error
              >Dunam volume space is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>dunam volume</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="number"
              placeholder="Enter water volume"
              formControlName="waterVolume"
            />
            <mat-error
              >Water volume space is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>water volume</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              placeholder="Enter nozzle"
              formControlName="nozzle"
            />
            <mat-error
              >Nozzle space is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>nozzle</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    [routerLink]="['../']"
    class="btn btn-secondary mr-2"
    mat-raised-button
    matTooltip="Back to the protocols list"
  >
    <i class="la la-arrow-left"></i>
    <span class="hidden-mobile">Back</span>
  </a>
  <a
    href="javascript:;"
    class="btn btn-secondary mr-2"
    mat-raised-button
    matTooltip="Reset changes"
  >
    <i class="la la-cog"></i>
    <span class="hidden-mobile">Reset</span>
  </a>
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
  >
    <span class="hidden-mobile">Save</span>
  </a>
  <!-- <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item color="primary" (click)="onSumbit(true)">
      Save & Exit
    </button>
    <button mat-menu-item color="primary">Save & Duplicate</button>
    <button mat-menu-item color="primary" (click)="onSumbit(false)">
      Save & Continue
    </button>
  </mat-menu> -->
</ng-template>

import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromCustomer from "../reducers/customer.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectCustomerState = createFeatureSelector<
  fromCustomer.CustomersState
>(fromCustomer.customerFeatureKey);

export const selectCustomersSelector = createSelector(
  selectCustomerState,
  fromCustomer.selectAll
);

//export const selectCustomers = pipe(select(selectCustomersSelector), skip(1));

export const selectCustomersLoading = createSelector(
  selectCustomerState,
  (state) => state.loading
);

export const selectCurrentCustomer = createSelector(
  selectCustomerState,
  (state) => state.currentCustomer
);

export const selectCustomerById = (id) =>
  createSelector(selectCustomerState, (state) => state.entities[id]);

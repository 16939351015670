<kt-generic-portlet [title]="'Task controller'" [loading]="loading$">
  <div class="row mb-6">
    <!-- FILTER -->
    <div class="col">
      <kt-controller-filter></kt-controller-filter>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12 px-0">
      <!-- TABLE -->
      <kt-controller-table [mapTasks]="selectedMapTasks"></kt-controller-table>
    </div>
    <div class="col-md-8 col-sm-12 pl-5 px-0">
      <!-- MAP -->
      <kt-controller-map
        [mapTasks]="mapTasks"
        [droneRoutes]="droneRoutes"
      ></kt-controller-map>
    </div>
  </div>
</kt-generic-portlet>

import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { DroneState } from "src/app/core/store/reducers/drones.reducer";
import {
  loadDroneModels,
  loadDronePayloads,
} from "src/app/core/store/actions/drones.actions";

@Component({
  selector: "kt-drones",
  templateUrl: "./drones.component.html",
  styleUrls: ["./drones.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DronesComponent implements OnInit {
  constructor(private store: Store<DroneState>) {}

  ngOnInit(): void {
    this.store.dispatch(loadDroneModels());
    this.store.dispatch(loadDronePayloads());
  }
}

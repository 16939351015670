import { PilotType } from "./pilot-type.model";

export class DronePilot {
  constructor(
    public pilotId?: string,
    public name?: string,
    public pilotType?: PilotType,
    public phone?: string,
    public isLicenseGimel?: boolean,
    public isLicenseRT?: boolean
  ) {}

  static parse(obj) {
    const pilotType = PilotType.parse(obj?.PilotType);
    const pilot = new DronePilot(
      obj?.pilotId,
      obj?.name,
      pilotType,
      obj?.phone,
      obj?.isLicenseGimel,
      obj?.isLicenseRT
    );

    return pilot;
  }

  static parseBack(pilot: DronePilot) {
    return {
      name: pilot.name,
      pilotTypeId: pilot.pilotType.pilotTypeId,
      phone: pilot.phone,
      isLicenseGimel: pilot.isLicenseGimel,
      isLicenseRT: pilot.isLicenseRT,
    };
  }

  static createEmpty(): DronePilot {
    return new DronePilot(null, null, null, null, false, false);
  }
}

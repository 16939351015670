//ng generate feature Drones -m ../../views/pages/drones/drones.module.ts --skipTest --group

import { Action, createReducer, on } from "@ngrx/store";
import * as DronesActions from "../actions/drones.actions";
import { Drone } from "../../models/drone.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { DroneModel } from '../../models/drone-model.model';
import { DronePayload } from '../../models/drone-payload.model';

export const dronesFeatureKey = "drones";

export interface DroneState extends EntityState<Drone> {
  loading: boolean;
  currentDrone: Drone;
  models: DroneModel[];
  payloads: DronePayload[];

}

export const adapter: EntityAdapter<Drone> = createEntityAdapter<Drone>({
  selectId: (drone: Drone) => drone.droneId,
});

export const initialDroneState: DroneState = adapter.getInitialState({
  loading: false,
  currentDrone: null,
  models: null,
  payloads: null
});

const dronesReducer = createReducer(
  initialDroneState,

  on(DronesActions.loadDrones, (state) => ({ ...state, loading: true })),
  on(DronesActions.loadDronesSuccess, (state, action) => {
    return { ...adapter.setAll(action.data, state), loading: false };
  }),
  on(DronesActions.loadDronesFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronesActions.loadDroneModelsSuccess, (state, action) => {
    return { ...state, models: action.data };
  }),

  on(DronesActions.loadDronePayloadsSuccess, (state, action) => {
    return { ...state, payloads: action.data };
  }),

  on(DronesActions.loadDrone, (state) => ({ ...state, loading: true })),
  on(DronesActions.loadDroneSuccess, (state, action) => {
    return { ...state, currentDrone: action.data, loading: false };
  }),
  on(DronesActions.loadDroneFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronesActions.addDrone, (state) => ({ ...state, loading: true })),
  on(DronesActions.addDroneSuccess, (state, action) => {
    return { ...adapter.addOne(action.data, state), loading: false };
  }),
  on(DronesActions.addDroneFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronesActions.updateDrone, (state) => ({ ...state, loading: true })),
  on(DronesActions.updateDroneSuccess, (state, action) => {
    return { ...adapter.updateOne(action.data, state), loading: false };
  }),
  on(DronesActions.updateDroneFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(DronesActions.deleteDrone, (state) => ({ ...state, loading: true })),
  on(DronesActions.deleteDroneSuccess, (state, action) => {
    return { ...adapter.removeOne(action.data.droneId, state), loading: false };
  }),
  on(DronesActions.deleteDroneFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: DroneState | undefined, action: Action) {
  return dronesReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

<kt-generic-portlet
  [title]="title"
  [actionsTemplate]="actionsTemplate"
  [loading]="loading$"
>
  <form
    [formGroup]="fieldForm"
    class="form form-group-seperator-dashed"
    *ngIf="field"
  >
    <kt-alert
      *ngIf="hasFormErrors"
      type="warn"
      [showCloseButton]="true"
      [duration]="10000"
      (close)="onAlertClose($event)"
    >
      Oh snap! Change a few things up and try submitting again.
    </kt-alert>
    <div class="form-section form-section-first">
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="number"
              placeholder="Enter plot number"
              formControlName="plotNumber"
            />
            <mat-error
              >Plot number is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter a
              <strong>plot number</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              autocomplete="off"
              matInput
              placeholder="Enter crop name"
              formControlName="cropName"
            />
            <mat-error
              >Crop name is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>crop name</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              type="number"
              placeholder="Enter planting year"
              formControlName="plantingYear"
            />
            <mat-error
              >Planting year is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter a valid
              <strong>planting year</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="customers"
            [filterBy]="'name'"
            [valueProperty]="'customerId'"
            [selectFormControl]="customersSelectFormControl"
            [error]="'Customer is required'"
            [hint]="'Please select a customer'"
            [placeHolder]="'Select a customer'"
          >
          </kt-mat-select-filter>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              autocomplete="off"
              matInput
              placeholder="Enter MOAG Id number"
              formControlName="moagId"
            />
            <mat-error
              >MOAG Id numner is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>MOAG Id number</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input
              autocomplete="off"
              matInput
              type="number"
              placeholder="Enter the area of the field in square meters"
              formControlName="areaSquareMeters"
            />
            <mat-error
              >area in square meters is
              <strong>required</strong>
            </mat-error>
            <mat-hint align="start"
              >Please enter
              <strong>area in square meters</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">
        <table class="table-responsive table-bordered" style="border-spacing: 1em;">
          <tr>
            <th></th>
            <th style="white-space: nowrap; " *ngFor="let date of dates" [attr.colspan]="maxEntriesPerDate[date]">{{ date | date:'EEEE d' }}</th>
          </tr>
          <tr>
            <td>Time (Hour)</td>
            <ng-container *ngFor="let date of dates">
              <td *ngFor="let _ of [].constructor(maxEntriesPerDate[date]); let i = index">{{ weatherData[i]?.date[date] || '0' }}</td>
            </ng-container>
          </tr>
          <tr *ngFor="let option of ['Wind (kt)', 'Gusts (kt)', 'Temperature (°C)', 'Rain (mm)']">
            <td>{{ option }}</td>
            <ng-container *ngFor="let date of dates">
              <td *ngFor="let _ of [].constructor(maxEntriesPerDate[date]); let i = index">
                {{ weatherData[i]?.data[i][option] || '0' }}
              </td>
            </ng-container>
          </tr>
        </table>
        
        
              
      </div>

      <div class="form-group row">
        <div class="col-lg-5 kt-margin-bottom-20-mobile">
          <div
            [ngStyle]="{ 'max-height': '555px' }"
            class="card-border coordinate-control"
          >
            <kt-coordinates-list
              [coordinates]="field.coordinates"
              (coordinateAdded)="mapCenter = $event"
            >
            </kt-coordinates-list>
          </div>
        </div>

        <div class="col-lg-7 kt-margin-bottom-20-mobile">
          <kt-field-map
            [center]="mapCenter"
            [fields]="[field]"
            [infoWindowTemplate]="infowindowTemplate"
          ></kt-field-map>
        </div>

        <ng-template #infowindowTemplate let-field="field">
          {{ field?.cropName }}
        </ng-template>
      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
  >
    <span class="hidden-mobile">Save</span>
  </a>

  <a
    [routerLink]="['/fields']"
    class="btn btn-secondary mr-2"
    mat-raised-button
    matTooltip="Back to the fields list"
  >
    <i class="la la-arrow-left"></i>
    <span class="hidden-mobile">Back</span>
  </a>
  <!-- <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
    <mat-icon>more_vert</mat-icon>

    <mat-menu #menu="matMenu">
      <button mat-menu-item color="primary" (click)="onSumbit(true)">
        Save & Exit
      </button>
      <button mat-menu-item color="primary">Save & Duplicate</button>
      <button mat-menu-item color="primary" (click)="onSumbit(false)">
        Save & Continue
      </button>
    </mat-menu>
  </button> -->
</ng-template>

<kt-generic-portlet [title]="'Materials'" [actionsTemplate]="actionsTemplate">
  <kt-materials-table
    [materials]="materials"
    [loading]="loading"
    (deleteMaterial)="deleteMaterial($event)"
  ></kt-materials-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a color="primary" mat-raised-button [routerLink]="['add']">
    New Material
  </a>
</ng-template>

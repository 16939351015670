export class Material {
  constructor(public materialId: string, public name: string) {}

  static parseBack(material: Material): object {
    return { name: material.name };
  }

  static parse(obj): Material {
    return new Material(obj.materialId, obj.name);
  }
}

import { NgxPermissionsService } from "ngx-permissions";
// Angular
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
// RxJS
import { Observable, of, pipe } from "rxjs";
import { tap, map, withLatestFrom, filter, concatMap } from "rxjs/operators";
// NGRX
import { select, Store } from "@ngrx/store";
// Module reducers and selectors
import { AppState } from "../../../core/reducers/";
import {
  currentUserPermissions,
  currentUser,
} from "../_selectors/auth.selectors";
import { Permission } from "../_models/permission.model";
import { find } from "lodash";

@Injectable()
export class ModuleGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const permission = route.data.permissions.only;
    const redirectTo = route.data.permissions.redirectTo;

    if (permission == "Everyone") {
      return of(true);
    }

    return this.store.pipe(
      select(currentUser),
      filter((v) => v != null),
      concatMap((user) => {
        let hasAccess = user.roleType.name == permission;
        if (!hasAccess) {
          this.router.navigateByUrl("/" + redirectTo);
        }

        return of(hasAccess);
      })
    );
  }
}

<kt-generic-portlet
  [title]="'Edit task'"
  [actionsTemplate]="actionsTemplate"
  [loading]="loading$"
>
  <form
    [formGroup]="taskForm"
    class="form form-group-seperator-dashed"
    *ngIf="task"
  >
    <kt-alert
      *ngIf="hasFormErrors"
      type="warn"
      [showCloseButton]="true"
      [duration]="10000"
      (close)="onAlertClose($event)"
    >
      Oh snap! Change a few things up and try submitting again.
    </kt-alert>
    <div class="form-section form-section-first">
      <div class="form-group row">
        <!-- Customer -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="customers"
            [filterBy]="'name'"
            [valueProperty]="'customerId'"
            [selectFormControl]="
              taskForm.controls['customersSelectFormControl']
            "
            [hint]="'Choose a customer to filter by'"
            [placeHolder]="'Select a customer'"
          >
          </kt-mat-select-filter>
        </div>

        <!-- Pilot -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="pilots"
            [filterBy]="'name'"
            [valueProperty]="'pilotId'"
            [selectFormControl]="taskForm.controls['pilotsSelectFormControl']"
            [hint]="'Choose a pilot to filter by'"
            [placeHolder]="'Select a pilot'"
          >
          </kt-mat-select-filter>
        </div>

        <!-- Protocol -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="protocols"
            [filterBy]="'name'"
            [valueProperty]="'protocolId'"
            [selectFormControl]="
              taskForm.controls['protocolsSelectFormControl']
            "
            [error]="'Protocol is required'"
            [hint]="'Please select a protocol'"
            [placeHolder]="'Select a protocol'"
          >
          </kt-mat-select-filter>
        </div>

        <!-- Material -->
        <div class="col-lg-3 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="materials"
            [filterBy]="'name'"
            [valueProperty]="'materialId'"
            [selectFormControl]="
              taskForm.controls['materialsSelectFormControl']
            "
            [error]="'Material is required'"
            [hint]="'Please select a material'"
            [placeHolder]="'Select a material'"
          >
          </kt-mat-select-filter>
        </div>
      </div>

      <div class="form-group row">
        <!-- Status -->
        <div class="col-lg-2 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select
              matInput
              formControlName="status"
              placeholder="Select a status"
              #statusSelectControl
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.statusId"
                >{{ status.name }}</mat-option
              >
            </mat-select>

            <mat-hint align="start"
              >Please select a
              <strong>status</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-2 kt-margin-bottom-20-mobile">
          <div class="row">
            <div class="col-lg-10">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  formControlName="scheduledDate"
                  matInput
                  [matDatepicker]="picker"
                  placeholder="Enter scheduled date"
                />
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                  >scheduled date is
                  <strong>required</strong>
                </mat-error>
                <mat-hint align="start"
                  >Please enter
                  <strong>scheduled date</strong>
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="col-lg-2">
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
            </div>
          </div>

        </div>

        <!--Approved Status -->
        <div class="col-lg-2 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select
              matInput
              formControlName="approvedStatus"
              placeholder="Select an approved status"
              #statusSelectControl
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.statusId"
                >{{ status.name }}</mat-option
              >
            </mat-select>

            <mat-hint align="start"
              >Please select an
              <strong>approved status</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-2 kt-margin-bottom-20-mobile">
          <div class="row">
            <div class="col-lg-10">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  formControlName="approvedDate"
                  matInput
                  [matDatepicker]="picker2"
                  placeholder="Enter approved date"
                />
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error
                  >approved date is
                  <strong>required</strong>
                </mat-error>
                <mat-hint align="start"
                  >Please enter
                  <strong>approved date</strong>
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="col-lg-2">
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
            </div>
          </div>

        </div>


        <!--Execution Status -->
        <div class="col-lg-2 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select
              matInput
              formControlName="executionStatus"
              placeholder="Select an execution status"
              #statusSelectControl
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.statusId"
                >{{ status.name }}</mat-option
              >
            </mat-select>

            <mat-hint align="start"
              >Please select an
              <strong>execution status</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="col-lg-2 kt-margin-bottom-20-mobile">
          <div class="row">
            <div class="col-lg-10">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  formControlName="executionDate"
                  matInput
                  [matDatepicker]="picker1"
                  placeholder="Enter execution date"
                />
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error
                  >execution date is
                  <strong>required</strong>
                </mat-error>
                <mat-hint align="start"
                  >Please enter
                  <strong>execution date</strong>
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="col-lg-2">
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
            </div>
          </div>

        </div>

      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
  >
    <span class="hidden-mobile">Save</span>
  </a>

  <a
    [routerLink]="['../../']"
    class="btn btn-secondary mr-2"
    mat-raised-button
    matTooltip="Back to the task list"
  >
    <i class="la la-arrow-left"></i>
    <span class="hidden-mobile">Back</span>
  </a>
</ng-template>

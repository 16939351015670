import {
  mapTaskSelected,
  mapTaskTableClick,
} from "./../../../../../core/store/actions/task.actions";
import { MapTask } from "./../../../../../core/models/map-task.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Field } from "src/app/core/models/field.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { AssignSelectedFormComponent } from "../assign-selected-form/assign-selected-form.component";
import { Task } from "src/app/core/models/task.model";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-task-fields-table",
  templateUrl: "./task-fields-table.component.html",
  styleUrls: ["./task-fields-table.component.scss"],
})
export class TaskFieldsTableComponent implements OnInit, AfterViewInit {
  @Input() mapTasks: MapTask[];
  @Output() loadData = new EventEmitter<DatasourceOptions>();

  @ViewChild("cropNameTemplate") cropNameTemplate: TemplateRef<any>;
  @ViewChild("dunamTemplate") dunamTemplate: TemplateRef<any>;
  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;

  columns: DataTableColumn[];

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "cropName",
          clientFieldName: "cropName",
          displayNameKey: "Crop Name",
          sortable: true,
          template: this.cropNameTemplate,
        },
        {
          serverFieldName: "dunamVolume",
          clientFieldName: "dunamVolume",
          displayNameKey: "Dunam Volume",
          sortable: true,
          template: this.dunamTemplate,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  mapTaskSelected($event) {
    this.store.dispatch(
      mapTaskSelected({
        mapTask: $event.row,
        selected: !$event.row.isSelected,
      })
    );
  }

  mapTaskClicked(mapTask: MapTask) {
    this.store.dispatch(mapTaskTableClick({ data: mapTask }));
  }

  assignTasks() {
    let dialogRef = this.dialog.open(AssignSelectedFormComponent, {
      data: this.mapTasks,
      width: "750px",
    });
  }

  editTaskClick(mapTask: MapTask) {
    this.router.navigate(["edit/" + mapTask.task.taskId], {
      relativeTo: this.route,
    });
  }
}

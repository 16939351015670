import { TaskFilterWrapper } from "./../../models/task-filter-wrapper.model";
import { createAction, props } from "@ngrx/store";
import { Task } from "../../models/task.model";
import { MapTask } from "../../models/map-task.model";
import { AssignTaskWrapper } from "../../models/assignTaskWrapper.model";
import { DroneRoute } from "../../models/drone-route-model";

export const loadTasks = createAction("[Task] Load Tasks");

export const loadTasksSuccess = createAction(
  "[Task] Load Tasks Success",
  props<{ data: any }>()
);

export const loadTasksFailure = createAction(
  "[Task] Load Tasks Failure",
  props<{ error: any }>()
);

export const deleteTask = createAction(
  "[Task] DeleteTask",
  props<{ data: any }>()
);

export const loadTask = createAction(
  "[Task] Load Task",
  props<{ data: any }>()
);

export const loadTaskSuccess = createAction(
  "[Task] Load Task Success",
  props<{ data: any }>()
);

export const loadTaskFailure = createAction(
  "[Task] Load Task Failure",
  props<{ error: any }>()
);

export const updateTask = createAction(
  "[Task] update Task",
  props<{ task: Task }>()
);

export const updateTaskSuccess = createAction(
  "[Task] update Task Success",
  props<{ data: any }>()
);

export const updateTaskFailure = createAction(
  "[Task] update Task Failure",
  props<{ error: any }>()
);

export const deleteTaskSuccess = createAction(
  "[Task] DeleteTask Success",
  props<{ data: any }>()
);

export const deleteTaskFailure = createAction(
  "[Task] DeleteTask Failure",
  props<{ error: any }>()
);

export const loadStatuses = createAction("[Task] loadStatuses");

export const loadStatusesSuccess = createAction(
  "[Task] loadStatuses Success",
  props<{ data: any }>()
);

export const loadStatusesFailure = createAction(
  "[Task] loadStatuses Failure",
  props<{ error: any }>()
);

export const importTasks = createAction(
  "[Task] importTasks",
  props<{ task: Task; file: File }>()
);

export const importTasksSuccess = createAction(
  "[Task] importTasks Success",
  props<{ data: any }>()
);

export const importTasksFailure = createAction(
  "[Task] importTasks Failure",
  props<{ error: any }>()
);

export const importTasksPreview = createAction(
  "[Task] importTasksPreview",
  props<{ task: Task; file: File }>()
);

export const importTasksPreviewSuccess = createAction(
  "[Task] importTasksPreview Success",
  props<{ data: any }>()
);

export const importTasksPreviewFailure = createAction(
  "[Task] importTasksPreview Failure",
  props<{ error: any }>()
);

export const filterTasks = createAction(
  "[Task] filter tasks",
  props<{ data: TaskFilterWrapper }>()
);

export const filterTasksSuccess = createAction(
  "[Task] filter tasks success",
  props<{ data: MapTask[] }>()
);

export const filterTasksFailed = createAction(
  "[Task] filter tasks failed",
  props<{ error: any }>()
);

export const mapTaskSelected = createAction(
  "[Task] map task selected",
  props<{ mapTask: MapTask; selected: boolean }>()
);

export const controllerMapTaskSelected = createAction(
  "[Task] controller map task selected",
  props<{ mapTask: MapTask; selected: boolean }>()
);

export const mapTaskTableClick = createAction(
  "[Task] map task table click",
  props<{ data: MapTask }>()
);

export const assignTasks = createAction(
  "[Task] assign tasks",
  props<{ data: AssignTaskWrapper }>()
);

export const assignTasksSuccess = createAction("[Task] assign tasks success");

export const assignTasksFailed = createAction(
  "[Task] assign tasks failed",
  props<{ error: any }>()
);

export const assignControllerTasks = createAction(
  "[Task] assign controller tasks",
  props<{ data: AssignTaskWrapper }>()
);

export const assignControllerTasksSuccess = createAction(
  "[Task] assign controller tasks success"
);

export const assignControllerTasksFailed = createAction(
  "[Task] assign controller tasks failed",
  props<{ error: any }>()
);

export const filterControllerTasks = createAction(
  "[Task] filter Controller tasks",
  props<{ data: TaskFilterWrapper }>()
);

export const filterControllerTasksSuccess = createAction(
  "[Task] filter Controller tasks success",
  props<{ mapTasks: MapTask[]; droneRoutes: DroneRoute[] }>()
);

export const filterControllerTasksFailed = createAction(
  "[Task] filter Controller tasks failed",
  props<{ error: any }>()
);

export const filterCompletedTasks = createAction(
  "[Task] filter Completed tasks",
  props<{ data: TaskFilterWrapper }>()
);

export const filterCompletedTasksSuccess = createAction(
  "[Task] filter Completed tasks success",
  props<{ tasks: Task[] }>()
);

export const filterCompletedTasksFailed = createAction(
  "[Task] filter Completed tasks failed",
  props<{ error: any }>()
);

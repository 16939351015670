import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Material } from "../models/material.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MaterialsService {
  constructor(private api: ApiService) {}

  getAllMaterials() {
    return this.api.get("materials").pipe(
      map((result) => {
        const materials = result.map((d) => Material.parse(d));

        return materials;
      })
    );
  }

  getMaterialById(id) {
    return this.api.get("materials/" + id).pipe(
      map((result) => {
        const material = Material.parse(result);

        return material;
      })
    );
  }

  addMaterial(material: Material) {
    return this.api.post("materials", null, Material.parseBack(material)).pipe(
      map((result) => {
        return material;
      })
    );
  }

  updateMaterial(material: Material) {
    return this.api
      .put("materials/" + material.materialId, Material.parseBack(material))
      .pipe(
        map((result) => {
          return material;
        })
      );
  }

  deleteMaterial(material: Material) {
    return this.api.delete("materials/" + material.materialId).pipe(
      map((result) => {
        return material;
      })
    );
  }
}

<div class="card-border">
  <agm-map
    #map
    (mapReady)="onMapReady($event)"
    [ngStyle]="{ height: '555px' }"
    [latitude]="center?.lat"
    [longitude]="center?.lng"
    [zoom]="zoom"
    (zoomChange)="zoom = $event"
    [mapTypeControl]="true"
    [fitBounds]="bounds"
    [fitBoundsPadding]="0"
  >
    <agm-polygon
      #polygon
      *ngFor="let mapTask of mapTasks"
      [paths]="mapTask.task.field.coordinates"
      [visible]="true"
      [strokeWeight]="mapTask.isSelected ? 4 : 1"
      [strokeColor]="mapTask.isSelected ? '#07b57a' : '#ffffff'"
      [fillColor]="mapTask.isSelected ? '#07b57a' : '#000000'"
      (polyMouseOver)="mouseOverPoly(infoWindow, mapTask)"
      (polyMouseOut)="infoWindow.close()"
      (polyClick)="mapTaskSelected(mapTask)"
    >
      <agm-marker
        [latitude]="mapTask.bounds.getCenter().lat()"
        [longitude]="mapTask.bounds.getCenter().lng()"
      >
      </agm-marker>
    </agm-polygon>

    <agm-info-window
      #infoWindow
      [isOpen]="true"
      [latitude]="selectedMapTask?.getNorth().lat"
      [longitude]="selectedMapTask?.getNorth().lng"
    >
      <div class="container-fluid">
        <div class="row">
          {{ selectedMapTask?.task.field.customer.name }}
        </div>
        <div class="row">
          {{ selectedMapTask?.task.field.cropName }}
        </div>
        <div class="row">
          {{ selectedMapTask?.task.field.plantingYear }}
        </div>
        <div class="row">
          {{ selectedMapTask?.task.field.plotNumber }}
        </div>
      </div>
    </agm-info-window>
  </agm-map>

  <button
    class="delete"
    color="warn"
    matTooltip="Remove selection rectangle"
    type="button"
    id="delete-button"
    (click)="deleteSelectedShape()"
    [hidden]="!selectedShape"
  >
    <mat-icon>settings_backup_restore</mat-icon>
  </button>

  <button
    class="select"
    color="warn"
    matTooltip="Select tasks in rectangle"
    type="button"
    id="select-button"
    (click)="selectTasksInRect(true)"
    [hidden]="!selectedShape"
  >
    Select
  </button>
  <button
    class="select"
    color="warn"
    matTooltip="Unselect tasks in rectangle"
    type="button"
    id="unselect-button"
    (click)="selectTasksInRect(false)"
    [hidden]="!selectedShape"
  >
    Unselect
  </button>
</div>

import { Action, createReducer, on } from "@ngrx/store";
import * as ProtocolActions from "../actions/protocol.actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Protocol } from "../../models/protocol.model";

export const protocolFeatureKey = "protocol";

export interface ProtocolState extends EntityState<Protocol> {
  loading: boolean;
  currentProtocol: Protocol;
}

export const adapter: EntityAdapter<Protocol> = createEntityAdapter<Protocol>({
  selectId: (protocol: Protocol) => protocol.protocolId,
});

export const initialState: ProtocolState = adapter.getInitialState({
  loading: false,
  currentProtocol: null,
});

const protocolReducer = createReducer(
  initialState,

  on(ProtocolActions.loadProtocols, (state) => state),
  on(ProtocolActions.loadProtocolsSuccess, (state, action) => {
    return { ...adapter.setAll(action.data, state), loading: false };
  }),
  on(ProtocolActions.loadProtocolsFailure, (state, action) => state),

  on(ProtocolActions.addProtocol, (state) => ({
    ...state,
    loading: true,
  })),
  on(ProtocolActions.addProtocolSuccess, (state, action) => {
    return { ...adapter.addOne(action.data, state), loading: false };
  }),
  on(ProtocolActions.addProtocolFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(ProtocolActions.updateProtocol, (state) => ({
    ...state,
    loading: true,
  })),
  on(ProtocolActions.updateProtocolSuccess, (state, action) => {
    return { ...adapter.updateOne(action.data, state), loading: false };
  }),
  on(ProtocolActions.updateProtocolFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(ProtocolActions.deleteProtocol, (state) => ({
    ...state,
    loading: true,
  })),
  on(ProtocolActions.deleteProtocolSuccess, (state, action) => {
    return {
      ...adapter.removeOne(action.data.protocolId, state),
      loading: false,
    };
  }),
  on(ProtocolActions.addProtocolFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(ProtocolActions.loadProtocol, (state) => ({
    ...state,
    loading: true,
  })),
  on(ProtocolActions.loadProtocolSuccess, (state, action) => {
    return { ...state, currentProtocol: action.data, loading: false };
  }),
  on(ProtocolActions.loadProtocolFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: ProtocolState | undefined, action: Action) {
  return protocolReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

<div class="row mb-5">
  <!-- Customer -->
  <div class="col-lg-4 kt-margin-bottom-20-mobile">
    <kt-mat-select-filter
      [dataSet]="customers"
      [filterBy]="'name'"
      [valueProperty]="'customerId'"
      [selectFormControl]="customersSelectFormControl"
      [hint]="'Choose a customer to filter by'"
      [placeHolder]="'Select a customer'"
    >
    </kt-mat-select-filter>
  </div>

  <!-- Pilot -->
  <div class="col-lg-4 kt-margin-bottom-20-mobile">
    <kt-mat-select-filter
      [dataSet]="pilots"
      [filterBy]="'name'"
      [valueProperty]="'pilotId'"
      [selectFormControl]="pilotsSelectFormControl"
      [hint]="'Choose a pilot to filter by'"
      [placeHolder]="'Select a pilot'"
    >
    </kt-mat-select-filter>
  </div>

  <!-- Status -->
  <div class="col-lg-4 kt-margin-bottom-20-mobile">
    <mat-form-field class="mat-form-field-fluid">
      <mat-select matInput placeholder="Select a status" #statusSelectControl>
        <mat-option *ngFor="let status of statuses" [value]="status.statusId">{{
          status.name
        }}</mat-option>
      </mat-select>

      <mat-hint align="start"
        >Please select a
        <strong>status</strong>
      </mat-hint>
    </mat-form-field>
  </div>
</div>

<div class="row">
  <!-- Protocol -->
  <div class="col-lg-4 kt-margin-bottom-20-mobile">
    <kt-mat-select-filter
      [dataSet]="protocols"
      [filterBy]="'name'"
      [valueProperty]="'protocolId'"
      [selectFormControl]="protocolsSelectFormControl"
      [error]="'Protocol is required'"
      [hint]="'Please select a protocol'"
      [placeHolder]="'Select a protocol'"
    >
    </kt-mat-select-filter>
  </div>

  <!-- Material -->
  <div class="col-lg-4 kt-margin-bottom-20-mobile">
    <kt-mat-select-filter
      [dataSet]="materials"
      [filterBy]="'name'"
      [valueProperty]="'materialId'"
      [selectFormControl]="materialsSelectFormControl"
      [error]="'Material is required'"
      [hint]="'Please select a material'"
      [placeHolder]="'Select a material'"
    >
    </kt-mat-select-filter>
  </div>
</div>
<div class="row no-gutters">
  <!-- <div class="col d-flex justify-content-start">
    Tasks filtered on map: 5
  </div> -->
  <div class="col d-flex justify-content-end">
    <button
      mat-raised-button
      color="primary"
      (click)="filterClick()"
      [disabled]="loading$ | async"
    >
      Filter
    </button>
  </div>
</div>

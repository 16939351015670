import { filterControllerTasksFailed } from "./../actions/task.actions";
import { TaskFilterWrapper } from "./../../models/task-filter-wrapper.model";
import { cloneDeep } from "lodash";
import { MapTask } from "./../../models/map-task.model";
import { Action, createReducer, on } from "@ngrx/store";
import * as TaskActions from "../actions/task.actions";
import { Task } from "../../models/task.model";
import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { Status } from "../../models/status.model";
import { state } from "@angular/animations";
import { DroneRoute } from "../../models/drone-route-model";

export const taskFeatureKey = "task";

export interface TaskState extends EntityState<Task> {
  loading: boolean;
  modalLoading: boolean;
  currentTask: Task;
  statuses: Status[];
  mapTasks: MapTask[];
  controllerMapTasks: MapTask[];
  droneRoutes: DroneRoute[];
  taskFilterWrapper: TaskFilterWrapper;
  controllerFilterWrapper: TaskFilterWrapper;
  completedTasksFilterWrapper: TaskFilterWrapper;
  completedTasks: Task[];
}

export const allTasksAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: (task: Task) => task.taskId,
});

export const initialTaskState: TaskState = allTasksAdapter.getInitialState({
  loading: false,
  modalLoading: null,
  currentTask: null,
  droneRoutes: null,
  statuses: null,
  mapTasks: null,
  controllerMapTasks: null,
  taskFilterWrapper: null,
  controllerFilterWrapper: null,
  completedTasksFilterWrapper: null,
  completedTasks: null,
});

const taskReducer = createReducer(
  initialTaskState,

  on(TaskActions.loadTasks, TaskActions.loadStatuses, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    TaskActions.loadTasksFailure,
    TaskActions.filterCompletedTasksFailed,
    TaskActions.loadStatusesFailure,
    (state, action) => ({
      ...state,
      loading: false,
    })
  ),

  on(TaskActions.loadTasksSuccess, (state, action) => {
    return { ...allTasksAdapter.setAll(action.data, state), loading: false };
  }),

  on(TaskActions.loadStatusesSuccess, (state, action) => {
    return { ...state, statuses: action.data, loading: false };
  }),

  on(TaskActions.deleteTask, (state) => ({
    ...state,
    loading: true,
  })),
  on(TaskActions.deleteTaskSuccess, (state, action) => {
    return {
      ...allTasksAdapter.removeOne(action.data.taskId, state),
      loading: false,
    };
  }),

  on(TaskActions.importTasks, (state) => ({
    ...state,
    loading: true,
  })),
  on(TaskActions.importTasksSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(TaskActions.importTasksFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(TaskActions.importTasksPreview, (state) => ({
    ...state,
    loading: true,
  })),
  on(TaskActions.importTasksPreviewSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(TaskActions.importTasksPreviewFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(TaskActions.assignTasks, TaskActions.assignControllerTasks, (state) => ({
    ...state,
    modalLoading: true,
  })),
  on(
    TaskActions.assignTasksSuccess,
    TaskActions.assignControllerTasksSuccess,
    (state) => ({
      ...state,
      modalLoading: false,
    })
  ),
  on(
    TaskActions.assignTasksFailed,
    TaskActions.assignControllerTasksFailed,
    (state) => ({
      ...state,
      modalLoading: false,
    })
  ),

  on(TaskActions.filterTasks, (state, action) => ({
    ...state,
    loading: true,
    taskFilterWrapper: action.data,
  })),
  on(TaskActions.filterTasksSuccess, (state, action) => {
    return { ...state, mapTasks: action.data, loading: false };
  }),
  on(TaskActions.filterTasksFailed, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(TaskActions.filterControllerTasks, (state, action) => ({
    ...state,
    loading: true,
    controllerFilterWrapper: action.data,
  })),
  on(TaskActions.filterControllerTasksSuccess, (state, action) => {
    return {
      ...state,
      controllerMapTasks: action.mapTasks,
      droneRoutes: action.droneRoutes,
      loading: false,
    };
  }),
  on(TaskActions.filterControllerTasksFailed, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(TaskActions.filterCompletedTasks, (state, action) => ({
    ...state,
    loading: true,
    completedTasksFilterWrapper: action.data,
  })),
  on(TaskActions.filterCompletedTasksSuccess, (state, action) => ({
    ...state,
    loading: false,
    completedTasks: action.tasks,
  })),

  on(TaskActions.mapTaskSelected, (state, action) => {
    let mapTasks: MapTask[];
    if (state.mapTasks) {
      mapTasks = [...state.mapTasks];
      let updateIndex = mapTasks.findIndex(
        (t) => t.task.taskId == action.mapTask.task.taskId
      );

      let updated = cloneDeep(action.mapTask);

      updated.isSelected = action.selected;
      mapTasks[updateIndex] = updated;
    }
    return {
      ...state,
      mapTasks: mapTasks,
    };
  }),

  on(TaskActions.controllerMapTaskSelected, (state, action) => {
    let mapTasks: MapTask[];
    if (state.controllerMapTasks) {
      mapTasks = [...state.controllerMapTasks];
      let updateIndex = mapTasks.findIndex(
        (t) => t.task.taskId == action.mapTask.task.taskId
      );

      let updated = cloneDeep(action.mapTask);

      updated.isSelected = action.selected;
      mapTasks[updateIndex] = updated;
    }
    return {
      ...state,
      controllerMapTasks: mapTasks,
    };
  }),

  on(TaskActions.loadTask, (state) => ({
    ...state,
    loading: true,
  })),
  on(TaskActions.loadTaskSuccess, (state, action) => {
    return { ...state, currentTask: action.data, loading: false };
  }),
  on(TaskActions.loadTaskFailure, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(TaskActions.updateTask, (state) => ({
    ...state,
    loading: true,
  })),
  on(TaskActions.updateTaskSuccess, (state, action) => {
    return {
      ...allTasksAdapter.updateOne(action.data, state),
      currentTask: action.data,
      loading: false,
      mapTasks: null,
    };
  }),
  on(TaskActions.updateTaskFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: TaskState | undefined, action: Action) {
  return taskReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = allTasksAdapter.getSelectors();

// Angular
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";

import { Store, select } from "@ngrx/store";

// Services
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from "../../../../../core/_base/crud";
// Models
import { User } from "../../../../../core/auth";

import { UserState } from "src/app/core/store/reducers/user.reducer";
import {
  selectUsersLoading,
  selectUsers,
} from "src/app/core/store/selectors/user.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { loadUsers, deleteUser } from "src/app/core/store/actions/user.actions";

@Component({
  selector: "kt-users-list",
  templateUrl: "./users-list.component.html",
})
export class UsersListComponent implements OnInit, OnDestroy {
  loading;
  users: User[];

  constructor(
    private store: Store<UserState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectUsersLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.store.pipe(selectUsers, untilDestroyed(this)).subscribe((users) => {
      this.users = users;
    });

    this.store.dispatch(loadUsers());
  }

  deleteUser(_item: User) {
    const _title = "User Delete";
    const _description = "Are you sure to permanently delete this user?";
    const _waitDesciption = "User is deleting...";
    const _deleteMessage = `User has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteUser({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  ngOnDestroy(): void {}
}

<div class="grid-container">
  <div class="card-title" [hidden]="noTitle">
    <span class="card-icon" #refIcon [hidden]="hideIcon || !icon">
      <ng-content *ngIf="!icon" select="[ktPortletIcon]"></ng-content>
      <i *ngIf="icon" [ngClass]="icon"></i>
    </span>
    <ng-content *ngIf="!title" select="[ktPortletTitle]"></ng-content>
    <h3 *ngIf="title" class="card-label" [innerHTML]="title"></h3>
  </div>
  <div class="card-toolbar" #refTools [hidden]="hideTools">
    <ng-content select="[ktPortletTools]"></ng-content>
  </div>
</div>

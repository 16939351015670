import { importFieldsFailure } from "./../actions/field.actions";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as FieldActions from "../actions/field.actions";
import { MessageType, LayoutUtilsService } from "../../_base/crud";
import { FieldsService } from "../../services/fields.service";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable()
export class FieldEffects {
  loadFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FieldActions.loadFields),
      concatMap(() =>
        this.fieldsService.getAllFields().pipe(
          map((data) => {
            return FieldActions.loadFieldsSuccess({ data });
          }),
          catchError((error) => of(FieldActions.loadFieldsFailure({ error })))
        )
      )
    );
  });

  loadField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FieldActions.loadField),
      concatMap((action) =>
        this.fieldsService.getFieldById(action.data).pipe(
          map((data) => {
            return FieldActions.loadFieldSuccess({ data });
          }),
          catchError((error) => of(FieldActions.loadFieldFailure({ error })))
        )
      )
    );
  });

  addField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FieldActions.addField),
      concatMap((action) =>
        this.fieldsService.addField(action.data).pipe(
          map((data) => {
            return FieldActions.addFieldSuccess({ data });
          }),
          catchError((error) => of(FieldActions.addFieldFailure({ error })))
        )
      )
    );
  });

  addFieldSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FieldActions.addFieldSuccess),
        tap((action) => {
          const message = `Field successfully has been created.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );

          this.router.navigate(["fields"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  addFieldFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FieldActions.addFieldFailure),
        tap((action) => {
          const message = `Field add failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  updateField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FieldActions.updateField),
      concatMap((action) =>
        this.fieldsService.updateField(action.data).pipe(
          map((data) => {
            return FieldActions.updateFieldSuccess({ data });
          }),
          catchError((error) => of(FieldActions.updateFieldFailure({ error })))
        )
      )
    );
  });

  updateFieldSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FieldActions.updateFieldSuccess),
        tap((action) => {
          const message = `Field successfully has been saved.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );

          this.router.navigate(["fields"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  updateFieldFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FieldActions.updateFieldFailure),
        tap((action) => {
          const message = `Field update failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  deleteField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FieldActions.deleteField),
      concatMap((action) =>
        this.fieldsService.deleteField(action.data).pipe(
          map((data) => {
            return FieldActions.deleteFieldSuccess({ data });
          }),
          catchError((error) => of(FieldActions.deleteFieldFailure({ error })))
        )
      )
    );
  });

  deleteFieldSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FieldActions.deleteFieldSuccess),
        tap((action) => {
          const message = `Field successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  importField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FieldActions.importFields),
      concatMap((action) =>
        this.fieldsService.importFields(action.data).pipe(
          map((data) => {
            return FieldActions.importFieldsSuccess({ data });
          }),
          catchError((error) => of(FieldActions.importFieldsFailure({ error })))
        )
      )
    );
  });

  importFieldsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FieldActions.importFieldsSuccess),
        tap((action) => {
          const message = `Fields imported from server successfully.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private fieldsService: FieldsService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

import { createAction, props } from "@ngrx/store";
import { DronePilot } from "../../models/drone-pilot.model";
import { PilotType } from "../../models/pilot-type.model";

export const loadDronePilots = createAction("[DronePilots] Load DronePilots");

export const loadDronePilotsSuccess = createAction(
  "[DronePilots] Load DronePilots Success",
  props<{ data: DronePilot[] }>()
);

export const loadDronePilotsFailure = createAction(
  "[DronePilots] Load DronePilots Failure",
  props<{ error: any }>()
);

export const loadPilotTypes = createAction("[DronePilots] Load PilotTypes");

export const loadPilotTypesSuccess = createAction(
  "[DronePilots] Load PilotTypes Success",
  props<{ data: PilotType[] }>()
);

export const lloadPilotTypesFailure = createAction(
  "[DronePilots] Load PilotTypes Failure",
  props<{ error: any }>()
);

export const loadDronePilot = createAction(
  "[DronePilots] load DronePilot",
  props<{ data: string }>()
);

export const loadDronePilotSuccess = createAction(
  "[DronePilots] load DronePilot Success",
  props<{ data: DronePilot }>()
);

export const loadDronePilotFailure = createAction(
  "[DronePilots] load DronePilot Failure",
  props<{ error: any }>()
);

export const addDronePilot = createAction(
  "[DronePilots] Add DronePilots",
  props<{ data: DronePilot }>()
);

export const addDronePilotSuccess = createAction(
  "[DronePilots] Add DronePilots Success",
  props<{ data: DronePilot }>()
);

export const addDronePilotFailure = createAction(
  "[DronePilots] Add DronePilots Failure",
  props<{ error: any }>()
);

export const updateDronePilot = createAction(
  "[DronePilots] UpdateDronePilot",
  props<{ data: DronePilot }>()
);

export const updateDronePilotSuccess = createAction(
  "[DronePilots] UpdateDronePilot Success",
  props<{ data: any }>()
);

export const updateDronePilotFailure = createAction(
  "[DronePilots] UpdateDronePilot Failure",
  props<{ error: any }>()
);

export const deleteDronePilot = createAction(
  "[DronePilots] DeleteDronePilot",
  props<{ data: DronePilot }>()
);

export const deleteDronePilotSuccess = createAction(
  "[DronePilots] DeleteDronePilot Success",
  props<{ data: DronePilot }>()
);

export const deleteDronePilotFailure = createAction(
  "[DronePilots] DeleteDronePilot Failure",
  props<{ error: any }>()
);

import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromUser from "../reducers/user.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectUserState = createFeatureSelector<fromUser.UserState>(
  fromUser.userFeatureKey
);

const selectUsersSelector = createSelector(selectUserState, fromUser.selectAll);

export const selectUsers = pipe(select(selectUsersSelector), skip(1));

export const selectUsersLoading = createSelector(
  selectUserState,
  (state) => state.loading
);

export const selectCurrentUser = createSelector(
  selectUserState,
  (state) => state.currentUser
);

export const selectRoleTypes = createSelector(
  selectUserState,
  (state) => state.roleTypes
);

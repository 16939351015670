// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
// Auth
import { AuthGuard } from "./core/auth";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "user-management",
        loadChildren: () =>
          import("./views/pages/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: "drones",
        loadChildren: () =>
          import("./views/pages/drones/drones.module").then(
            (m) => m.DronesModule
          ),
      },
      {
        path: "materials",
        loadChildren: () =>
          import("./views/pages/materials/materials.module").then(
            (m) => m.MaterialsModule
          ),
      },
      {
        path: "drone-pilots",
        loadChildren: () =>
          import("./views/pages/drone-pilots/drone-pilots.module").then(
            (m) => m.DronePilotsModule
          ),
      },
      {
        path: "protocols",
        loadChildren: () =>
          import("./views/pages/protocols/protocols.module").then(
            (m) => m.ProtocolsModule
          ),
      },
      {
        path: "customers",
        loadChildren: () =>
          import("./views/pages/customers/customers.module").then(
            (m) => m.CustomersModule
          ),
      },
      {
        path: "fields",
        loadChildren: () =>
          import("./views/pages/fields/fields.module").then(
            (m) => m.FieldsModule
          ),
      },
      {
        path: "tasks",
        loadChildren: () =>
          import("./views/pages/tasks/tasks.module").then((m) => m.TasksModule),
      },
      {
        path: "pilot-tasks",
        loadChildren: () =>
          import("./views/pages/pilot-tasks/pilot-tasks.module").then(
            (m) => m.PilotTasksModule
          ),
      },
      {
        path: "builder",
        loadChildren: () =>
          import("./views/theme/content/builder/builder.module").then(
            (m) => m.BuilderModule
          ),
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "**", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  { path: "**", redirectTo: "error/403", pathMatch: "full" },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<kt-generic-portlet
  [title]="'Assign selected tasks'"
  [loading]="loading$"
  [actionsTemplate]="actionsTemplate"
>
  <form [formGroup]="assignForm" class="form form-group-seperator-dashed">
    <div class="form-section form-section-first">
      <div class="row">
        <!-- Pilot -->
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="pilots"
            [filterBy]="'name'"
            [valueProperty]="'pilotId'"
            [selectFormControl]="pilotsSelectFormControl"
            [hint]="'Choose a pilot to filter by'"
            [placeHolder]="'Select a pilot'"
          >
          </kt-mat-select-filter>
        </div>

        <!-- Status -->
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select
              matInput
              placeholder="Select a status"
              formControlName="status"
              #statusSelectControl
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.statusId"
                >{{ status.name }}</mat-option
              >
            </mat-select>

            <mat-hint align="start"
              >Please select a
              <strong>status</strong>
            </mat-hint>
          </mat-form-field>
        </div>

        <!-- Execution Date -->
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <div class="row">
            <div class="col-lg-10">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  #assignExecutionDateFormControl
                  [matDatepicker]="picker"
                  placeholder="Enter execution date"
                  formControlName="executionDate"
                />
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                  >Execution date is
                  <strong>required</strong>
                </mat-error>
                <mat-hint align="start"
                  >Please enter
                  <strong>Execution date</strong>
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-lg-2">
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
    [disabled]="loading$ | async"
  >
    <span class="hidden-mobile">Assign</span>
  </a>
</ng-template>

import { ModuleGuard } from "./../../../core/auth/_guards/module.guard";
//import { PilotTasksComponent } from "./pilot-tasks/pilot-tasks.component";
import { CompletedTasksComponent } from "./completed-tasks/completed-tasks.component";
import { Routes, RouterModule } from "@angular/router";
import { TasksComponent } from "./tasks.component";
import { TasksListComponent } from "./tasks-list/tasks-list.component";
import { AssignTasksComponent } from "./assign-tasks/assign-tasks.component";
import { TaskControllerComponent } from "./task-controller/task-controller.component";
import { TaskEditComponent } from "./task-edit/task-edit.component";
import { ImportTaskComponent } from "./import-task/import-task.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxPermissionsGuard } from "ngx-permissions";

const routes: Routes = [
  {
    path: "",
    component: TasksComponent,
    canActivate: [ModuleGuard],
    data: {
      permissions: {
        only: "Admin",
        redirectTo: "dashboard",
      },
    },
    children: [
      {
        path: "list",
        pathMatch: "full",
        component: TasksListComponent,
      },
      {
        path: "completed",
        pathMatch: "full",
        component: CompletedTasksComponent,
      },
      {
        path: "map",
        component: AssignTasksComponent,
      },
      {
        path: "controller",
        component: TaskControllerComponent,
      },
      {
        path: ":fieldId",
        pathMatch: "full",
        component: TasksListComponent,
      },
      {
        path: ":fieldId/import",
        component: ImportTaskComponent,
      },
      {
        path: "controller/edit/:id",
        component: TaskEditComponent,
        pathMatch: "full",
      },
      {
        path: "map/edit/:id",
        component: TaskEditComponent,
        pathMatch: "full",
      },
      {
        path: "list/edit/:id",
        component: TaskEditComponent,
        pathMatch: "full",
      },
      {
        path: "list/import",
        component: ImportTaskComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TasksRoutingModule {}

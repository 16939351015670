import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Customer } from "../models/customer.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CustomersService {
  constructor(private api: ApiService) {}

  getAllCustomers() {
    return this.api.get("customers").pipe(
      map((result) => {
        const customers = result.map((d) => JSON.parse(JSON.stringify(d)));

        return customers;
      })
    );
  }

  getCustomerById(id) {
    return this.api.get("customers/" + id).pipe(
      map((result) => {
        const customer = JSON.parse(JSON.stringify(result));

        return customer;
      })
    );
  }

  addCustomer(customer: Customer) {
    return this.api.post("customers", null, Customer.parseBack(customer)).pipe(
      map((result) => {
        return customer;
      })
    );
  }

  updateCustomer(customer: Customer) {
    return this.api
      .put("customers/" + customer.customerId, Customer.parseBack(customer))
      .pipe(
        map((result) => {
          return customer;
        })
      );
  }

  deleteCustomer(customer: Customer) {
    return this.api.delete("customers/" + customer.customerId).pipe(
      map((result) => {
        return customer;
      })
    );
  }
}

import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { selectCustomersSelector } from "./../../../../../core/store/selectors/customer.selectors";
import { RoleType } from "./../../../../../core/auth/_models/role-type.model";
// Angular
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
// RxJS
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  combineLatest,
  ReplaySubject,
} from "rxjs";
// NGRX
import { Store, select } from "@ngrx/store";
import { Update } from "@ngrx/entity";
import { AppState } from "../../../../../core/reducers";
// Layout
import {
  SubheaderService,
  LayoutConfigService,
} from "../../../../../core/_base/layout";
import {
  LayoutUtilsService,
  MessageType,
} from "../../../../../core/_base/crud";
// Services and Models
import {
  User,
  UserUpdated,
  Address,
  SocialNetworks,
  selectHasUsersInStore,
  selectUserById,
  UserOnServerCreated,
  selectLastCreatedUserId,
  selectUsersActionLoading,
} from "../../../../../core/auth";
import { UserState } from "src/app/core/store/reducers/user.reducer";
import {
  selectUsersLoading,
  selectCurrentUser,
  selectRoleTypes,
} from "src/app/core/store/selectors/user.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { cloneDeep } from "lodash";
import {
  loadUser,
  updateUser,
  addUser,
} from "src/app/core/store/actions/user.actions";
import { loadMaterials } from "src/app/core/store/actions/material.actions";
import { RegEx } from "src/app/core/_base/crud/utils/regex-utils";
import { Customer } from "src/app/core/models/customer.model";
import { selectPilotsSelector } from "src/app/core/store/selectors/drone-pilots.selectors";

@Component({
  selector: "kt-user-edit",
  templateUrl: "./user-edit.component.html",
})
export class UserEditComponent implements OnInit, OnDestroy {
  @Input() title = "Add User";
  isEdit = false;
  user: User;
  userForm: FormGroup;
  hasFormErrors = false;
  roleTypes: RoleType[];
  // customers: Customer[];
  // pilots: DronePilot[];
  loading$: Observable<boolean>;

  @Input() userId;

  public customersSelectFormControl: FormControl = new FormControl();
  public pilotsSelectFormControl: FormControl = new FormControl();

  constructor(
    private route: ActivatedRoute,
    private store: Store<UserState>,
    private userFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectUsersLoading),
      untilDestroyed(this)
    );

    this.user = User.createEmpty();
    this.createForm();

    combineLatest(
      this.store.pipe(select(selectRoleTypes), untilDestroyed(this)),
      this.store.pipe(select(selectCurrentUser), untilDestroyed(this))
    ).subscribe(([roleTypes, currentUser]) => {
      this.roleTypes = roleTypes;

      if (currentUser && (this.isEdit || this.userId)) {
        this.user = cloneDeep(currentUser);
        this.createForm();
      }
    });

    let userId = this.route.snapshot.params["id"];

    if (userId && userId != "add") {
      this.isEdit = true;
      this.title = "Edit User";

      this.store.dispatch(loadUser({ data: userId }));
    }

    if (this.userId) {
      this.title = "Edit My Profile";
      this.store.dispatch(loadUser({ data: this.userId }));
    }
  }

  createForm() {
    this.userForm = this.userFB.group({
      username: [this.user.username, Validators.required],
      phone: [
        this.user.phone,
        [Validators.required, Validators.pattern(new RegExp(RegEx.phone))],
      ],
      email: [this.user.email, [Validators.email, Validators.required]],
      roleType: [this.user.roleType?.roleTypeId, Validators.required],
      password: [this.user.password, Validators.required],
      viewFromAnyDate: [this.user.viewFromAnyDate],
    });

    this.userForm.addControl(
      "customersSelectFormControl",
      this.customersSelectFormControl
    );

    if (this.user.customer) {
      this.customersSelectFormControl.setValue(this.user.customer?.customerId);
    }

    this.userForm.addControl(
      "pilotsSelectFormControl",
      this.pilotsSelectFormControl
    );

    if (this.user.pilot) {
      this.pilotsSelectFormControl.setValue(this.user.pilot?.pilotId);
    }
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.userForm.controls;
    /** check form */
    if (this.userForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.prepareUser();

    if (this.isEdit) {
      this.store.dispatch(updateUser({ data: this.user }));
    } else {
      this.store.dispatch(addUser({ data: this.user }));
    }
  }

  prepareUser() {
    this.user.username = this.userForm.controls.username.value;
    this.user.roleType = this.roleTypes.find(
      (r) => r.roleTypeId == this.userForm.controls.roleType.value
    );
    this.user.email = this.userForm.controls.email.value;
    this.user.phone = this.userForm.controls.phone.value;
    this.user.password = this.userForm.controls.password.value;
    this.user.customer.customerId = this.customersSelectFormControl.value;
    this.user.pilot.pilotId = this.pilotsSelectFormControl.value;
    this.user.viewFromAnyDate = this.userForm.controls.viewFromAnyDate.value;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

export class DatasourceOptions {
  constructor(
    public totalAmount: number,
    public page: number,
    public search: string,
    public totalPages: number,
    public perPage: number,
    public sortBy: string,
    public sortOrder: string
  ) {}

  static parse(obj): DatasourceOptions {
    let options;

    options = new DatasourceOptions(
      obj.total_amount,
      obj.page,
      obj.search,
      obj.total_pages,
      obj.per_page,
      obj.sort_by,
      obj.sort_order
    );

    return options;
  }
}

<form [formGroup]="coorForm" class="form">
  <div class="row">
    <div class="col-lg-4">
      <mat-form-field class="mat-form-field-fluid">
        <input
          matInput
          type="number"
          placeholder="Enter lat"
          formControlName="lat"
        />
        <mat-error
          >Latitude is
          <strong>required</strong>
        </mat-error>
        <mat-hint>
          <strong>latitude coordinate</strong>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-lg-4">
      <mat-form-field class="mat-form-field-fluid">
        <input
          matInput
          type="number"
          placeholder="Enter long"
          formControlName="lng"
        />
        <mat-error
          >Longtitude is
          <strong>required</strong>
        </mat-error>
        <mat-hint>
          <strong>longtitude coordinate</strong>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-auto">
      <a
        href="javascript:;"
        class="btn btn-primary mr-2"
        color="accent"
        (click)="addCoor()"
        mat-raised-button
      >
        <span class="hidden-mobile">Add</span>
      </a>
    </div>
  </div>
</form>
<div class="row">
  <mat-list>
    <mat-list-item *ngFor="let coor of coordinates">
      <button
        mat-icon-button
        color="warn"
        type="button"
        (click)="deleteCoor(coor)"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <h4 mat-line>{{ coor.lat }}, {{ coor.lng }}</h4>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>

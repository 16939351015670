import { DroneModel } from "./drone-model.model";
import { DronePayload } from "./drone-payload.model";

export class Drone {
  constructor(
    public droneId: string,
    public payload: DronePayload,
    public tailNumber: string,
    public model: DroneModel,
    public blackBoxId: string
  ) {}

  static parse(obj) {
    const model = DroneModel.parse(obj.Model);
    const payload = DronePayload.parse(obj.Payload);
    const drone = new Drone(
      obj.droneId,
      payload,
      obj.tailNumber,
      model,
      obj.blackBoxId
    );
    return drone;
  }

  static parseBack(drone: Drone) {
    return {
      payloadId: drone.payload.payloadId,
      modelId: drone.model.modelId,
      tailNumber: drone.tailNumber,
      blackBoxId: drone.blackBoxId,
    };
  }

  static createEmpty(): Drone {
    return new Drone(null, null, "", null, null);
  }
}

// export enum DronePayloads {
//   scss = "scss",
//   splat = "splat",
// }

// export enum DroneModels {
//   M6EX = "M6E-X",
//   M6EY = "M6E-Y",
//   M6EZ = "M6E-Z",
// }

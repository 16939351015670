import {
  selectTasksLoading,
  selectMapTasks,
  selectSelectedMapTasks,
} from "./../../../../core/store/selectors/task.selectors";
import { MapTask } from "./../../../../core/models/map-task.model";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { selectFieldsSelector } from "src/app/core/store/selectors/field.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { mapTaskSelected } from "src/app/core/store/actions/task.actions";
import { cloneDeep } from "lodash";

@Component({
  selector: "kt-assign-tasks",
  templateUrl: "./assign-tasks.component.html",
  styleUrls: ["./assign-tasks.component.scss"],
})
export class AssignTasksComponent implements OnInit, OnDestroy {
  loading$;
  mapTasks: MapTask[];
  selectedMapTasks: MapTask[];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksLoading));

    this.store
      .pipe(select(selectMapTasks), untilDestroyed(this))
      .subscribe((mapTasks) => {
        this.mapTasks = cloneDeep(mapTasks);
      });

    this.store
      .pipe(select(selectSelectedMapTasks), untilDestroyed(this))
      .subscribe((selectedMapTasks) => {
        this.selectedMapTasks = cloneDeep(selectedMapTasks);
      });
  }

  ngOnDestroy(): void {}
}

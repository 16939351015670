<kt-generic-portlet [title]="'Drones'" [actionsTemplate]="actionsTemplate">
  <kt-drones-table
    [drones]="drones"
    [loading]="loading"
    (deleteDrone)="deleteDrone($event)"
  ></kt-drones-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a color="primary" mat-raised-button [routerLink]="['add']">
    New Drone
  </a>
</ng-template>

import { createAction, props } from "@ngrx/store";
import { User } from "../../auth/_models/user.model";

export const loadUsers = createAction("[User] Load Users !!!");

export const loadUsersSuccess = createAction(
  "[User] Load Users Success",
  props<{ data: any }>()
);

export const loadUsersFailure = createAction(
  "[User] Load Users Failure",
  props<{ error: any }>()
);

export const loadRoleTypes = createAction("[User] Load RoleTypes");

export const loadRoleTypesSuccess = createAction(
  "[User] Load RoleTypes Success",
  props<{ data: any }>()
);

export const loadRoleTypesFailure = createAction(
  "[User] Load RoleTypes Failure",
  props<{ error: any }>()
);

export const loadUser = createAction(
  "[User] Load User",
  props<{ data: any }>()
);

export const loadUserSuccess = createAction(
  "[User] Load User Success",
  props<{ data: any }>()
);

export const loadUserFailure = createAction(
  "[User] Load User Failure",
  props<{ error: any }>()
);

export const addUser = createAction("[User] AddUser", props<{ data: any }>());

export const addUserSuccess = createAction(
  "[User] AddUser Success",
  props<{ data: User }>()
);

export const addUserFailure = createAction(
  "[User] AddUser Failure",
  props<{ error: any }>()
);

export const updateUser = createAction(
  "[User] UpdateUser",
  props<{ data: any }>()
);

export const updateUserSuccess = createAction(
  "[User] UpdateUser Success",
  props<{ data: any }>()
);

export const updateUserFailure = createAction(
  "[User] UpdateUser Failure",
  props<{ error: any }>()
);

export const deleteUser = createAction(
  "[User] DeleteUser",
  props<{ data: any }>()
);

export const deleteUserSuccess = createAction(
  "[User] DeleteUser Success",
  props<{ data: any }>()
);

export const deleteUserFailure = createAction(
  "[User] DeleteUser Failure",
  props<{ error: any }>()
);

import { ProtocolsTableComponent } from "./protocols-list/protocols-table/protocols-table.component";
import { EditProtocolComponent } from "./edit-protocol/edit-protocol.component";
import { ProtocolsListComponent } from "./protocols-list/protocols-list.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProtocolsComponent } from "./protocols.component";
import { MatCoreModule } from "src/app/core/utils/mat-core.module";
import { HttpClientModule } from "@angular/common/http";
import { PartialsModule } from "../../partials/partials.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { StoreModule } from '@ngrx/store';
import * as fromProtocol from '../../../core/store/reducers/protocol.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProtocolEffects } from '../../../core/store/effects/protocol.effects';

const routes: Routes = [
  {
    path: "",
    component: ProtocolsComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ProtocolsListComponent,
      },
      {
        path: ":id",
        component: EditProtocolComponent,
      },
      {
        path: "add",
        component: EditProtocolComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    ProtocolsComponent,
    ProtocolsListComponent,
    EditProtocolComponent,
    ProtocolsTableComponent,
  ],
  imports: [
    CommonModule,
    MatCoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(fromProtocol.protocolFeatureKey, fromProtocol.reducer),
    EffectsModule.forFeature([ProtocolEffects]),
  ],
})
export class ProtocolsModule {}

<kt-generic-portlet [title]="'Filter and assign tasks'" [loading]="loading$">
  <div class="row mb-6 card-border">
    <!-- FILTER -->
    <div class="col">
      <kt-tasks-filter></kt-tasks-filter>
    </div>
  </div>
  <div class="row">
    <div class="col-4 px-0">
      <!-- TABLE -->
      <kt-task-fields-table
        [mapTasks]="selectedMapTasks"
      ></kt-task-fields-table>
    </div>
    <div class="col-8 pl-5 px-0">
      <!-- MAP -->
      <kt-task-fields-map [mapTasks]="mapTasks"></kt-task-fields-map>
    </div>
  </div>
</kt-generic-portlet>

import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { RoleType } from "./role-type.model";
import { BaseModel } from "../../_base/crud";
import { Address } from "./address.model";
import { SocialNetworks } from "./social-networks.model";
import { Customer } from "../../models/customer.model";

export class User extends BaseModel {
  userId: number;
  username: string;
  password: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  phone: string;
  roleType: RoleType;
  customer: Customer;
  pilot?: DronePilot;
  viewFromAnyDate?: Boolean;

  // use this in the future maybe
  roles: number[];

  clear(): void {
    this.userId = undefined;
    this.username = "";
    this.password = "";
    this.email = "";
    this.roles = [];
    // this.fullname = '';
    this.accessToken = "access-token-" + Math.random();
    this.refreshToken = "access-token-" + Math.random();
    // this.pic = './assets/media/users/default.jpg';
    // this.occupation = '';
    // this.companyName = '';
    this.phone = "";
    // this.address = new Address();
    // this.address.clear();
    // this.socialNetworks = new SocialNetworks();
    // this.socialNetworks.clear();
  }

  static createEmpty(): User {
    return new User();
  }
  static parseBack(user: User): object {
    return {
      username: user.username,
      email: user.email,
      roleTypeId: user.roleType?.roleTypeId,
      phone: user.phone,
      password: user.password,
      customerId: user.customer?.customerId,
      pilotId: user.pilot?.pilotId,
      viewTasksFromAnyDateForPilot: user.viewFromAnyDate,
    };
  }

  static parse(obj: any) {
    const roleType = JSON.parse(JSON.stringify(obj.RoleType));
    const pilot = DronePilot.parse(obj.Pilot) ?? {};
    const user = new User();
    user.userId = obj.userId;
    user.username = obj.username;
    user.email = obj.email;
    user.phone = obj.phone;
    user.roleType = roleType;
    user.password = obj.password;
    user.pilot = pilot;
    user.viewFromAnyDate = obj.viewTasksFromAnyDateForPilot;
    user.customer = obj.Customer ?? {};

    return user;
  }
}

import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "kt-generic-portlet",
  templateUrl: "./generic-portlet.component.html",
  styleUrls: ["./generic-portlet.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class GenericPortletComponent implements OnInit {
  @Input() title = "Title";
  @Input() actionsTemplate: TemplateRef<any>;
  @Input() loading;

  constructor() {}

  ngOnInit(): void {}
}

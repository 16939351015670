import { switchMap, mergeMap } from "rxjs/operators";
import {
  selectTasksSelector,
  selectTasksByFieldId,
} from "./../../../../core/store/selectors/task.selectors";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Task } from "src/app/core/models/task.model";
import { constructor, cloneDeep } from "lodash";
import { Store, select } from "@ngrx/store";
import { LayoutUtilsService, MessageType } from "src/app/core/_base/crud";
import { selectTasksLoading } from "src/app/core/store/selectors/task.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { loadTasks, deleteTask } from "src/app/core/store/actions/task.actions";
import { TaskState } from "src/app/core/store/reducers/task.reducer";
import { ActivatedRoute } from "@angular/router";
import { selectFieldById } from "src/app/core/store/selectors/field.selectors";
import { from, of, combineLatest } from "rxjs";

@Component({
  selector: "kt-tasks-list",
  templateUrl: "./tasks-list.component.html",
  styleUrls: ["./tasks-list.component.scss"],
})
export class TasksListComponent implements OnInit, OnDestroy {
  loading;
  tasks: Task[];
  title = "Tasks";

  constructor(
    private store: Store<TaskState>,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectTasksLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    let fieldId = this.route.snapshot.params["fieldId"];

    if (fieldId) {
      this.title = "Tasks";
      combineLatest(
        this.store.pipe(select(selectFieldById(fieldId)), untilDestroyed(this)),
        this.store.pipe(
          select(selectTasksByFieldId(fieldId), untilDestroyed(this))
        )
      ).subscribe(([field, tasks]) => {
        this.title = "Tasks";

        if (field) {
          this.title = "Plot number " + field.plotNumber + " tasks";

          this.tasks = cloneDeep(tasks);
        }
      });
    } else {
      this.store
        .pipe(select(selectTasksSelector), untilDestroyed(this))
        .subscribe((tasks) => {
          this.tasks = cloneDeep(tasks);
        });
    }

    this.store.dispatch(loadTasks());
  }

  deleteTask(_item: Task) {
    const _title = "Task Delete";
    const _description = "Are you sure to permanently delete this task?";
    const _waitDesciption = "Task is deleting...";
    const _deleteMessage = `Task has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteTask({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  ngOnDestroy(): void {}
}

import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromTask from "../reducers/task.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectTaskState = createFeatureSelector<fromTask.TaskState>(
  fromTask.taskFeatureKey
);

export const selectTasksSelector = createSelector(
  selectTaskState,
  fromTask.selectAll
);

//export const selectTasks = pipe(select(selectTasksSelector), skip(1));

export const selectTasksLoading = createSelector(
  selectTaskState,
  (state) => state.loading
);

export const selectTasksModalLoading = createSelector(
  selectTaskState,
  (state) => state.modalLoading
);

export const selectCurrentTask = createSelector(
  selectTaskState,
  (state) => state.currentTask
);

export const selectStatuses = createSelector(
  selectTaskState,
  (state) => state.statuses
);

export const selectMapTasks = createSelector(
  selectTaskState,
  (state) => state.mapTasks
);

export const selectSelectedMapTasks = createSelector(selectTaskState, (state) =>
  state.mapTasks?.filter((t) => t.isSelected)
);

export const selectControllerMapTasks = createSelector(
  selectTaskState,
  (state) => state.controllerMapTasks
);

export const selectSelectedControllerMapTasks = createSelector(
  selectTaskState,
  (state) => state.controllerMapTasks?.filter((t) => t.isSelected)
);

export const selectTaskFilterWrapper = createSelector(
  selectTaskState,
  (state) => state.taskFilterWrapper
);

export const selectControllerFilterWrapper = createSelector(
  selectTaskState,
  (state) => state.controllerFilterWrapper
);

export const selectDroneRoutes = createSelector(
  selectTaskState,
  (state) => state.droneRoutes
);

export const selectTasksByFieldId = (fieldId) =>
  createSelector(selectTasksSelector, (tasks) =>
    tasks.filter((t) => t.field.fieldId == fieldId)
  );

export const selectCompletedTasks = createSelector(
  selectTaskState,
  (state) => state.completedTasks
);

export const selectCompletedTasksFilterWrapper = createSelector(
  selectTaskState,
  (state) => state.completedTasksFilterWrapper
);

import { combineLatest } from "rxjs";
import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { Store } from "@ngrx/store";
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Drone } from "src/app/core/models/drone.model";
import { select } from "@ngrx/store";
import {
  selectDronesLoading,
  selectDrones,
} from "src/app/core/store/selectors/drones.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { loadDrones } from "src/app/core/store/actions/drones.actions";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import {
  selectTasksLoading,
  selectControllerFilterWrapper,
} from "src/app/core/store/selectors/task.selectors";
import {
  filterTasks,
  filterControllerTasks,
} from "src/app/core/store/actions/task.actions";
import { TaskFilterWrapper } from "src/app/core/models/task-filter-wrapper.model";
import { FormControl } from "@angular/forms";
import { selectPilotsSelector } from "src/app/core/store/selectors/drone-pilots.selectors";
import { filter } from "rxjs/operators";

@Component({
  selector: "kt-controller-filter",
  templateUrl: "./controller-filter.component.html",
  styleUrls: ["./controller-filter.component.scss"],
})
export class ControllerFilterComponent
  implements OnInit, OnDestroy, AfterViewInit {
  drones: Drone[];
  pilots: DronePilot[];
  // selectedDrones = [];
  loading$;
  columns: DataTableColumn[];

  public pilotsSelectFormControl: FormControl = new FormControl();
  public dronesSelectFormControl: FormControl = new FormControl();

  @ViewChild("executionDateFormControl") executionDateFormControl;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksLoading));

    combineLatest(
      this.store.pipe(selectDrones, untilDestroyed(this)),
      this.store.pipe(
        select(selectControllerFilterWrapper),
        untilDestroyed(this)
      )
    ).subscribe(([drones, filter]) => {
      this.drones = drones;
      if (filter) {
        this.dronesSelectFormControl.setValue(filter.dronesList);
        this.pilotsSelectFormControl.setValue(filter.pilotId);
      }
    });

    this.store
      .pipe(select(selectPilotsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.pilots = p;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.executionDateFormControl.nativeElement.value = new Date().toLocaleDateString();

      this.columns = [
        {
          serverFieldName: "tailNumber",
          clientFieldName: "tailNumber",
          displayNameKey: "Tail Number",
          sortable: true,
        },
      ];
    });

    this.store.dispatch(loadDrones());
  }

  filterClick() {
    let filterWrapper = this.prepareFilterWrapper();
    this.store.dispatch(filterControllerTasks({ data: filterWrapper }));
  }

  prepareFilterWrapper(): TaskFilterWrapper {
    return {
      pilotId: this.pilotsSelectFormControl.value,
      executionDate: this.executionDateFormControl.nativeElement.value,
      dronesList: this.dronesSelectFormControl.value,
    };
  }

  ngOnDestroy(): void {}
}

import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  TemplateRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  MatDataSource,
  rowsAnimation,
} from "src/app/core/utils/mat-datasource";
import { SelectionModel } from "@angular/cdk/collections";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { merge, fromEvent } from "rxjs";
import { tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "lodash";

@Component({
  selector: "kt-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"],
  animations: [rowsAnimation],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DatatableComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() data: any[];
  @Input() showFilter = true;
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Input() columns: DataTableColumn[];
  @Input() showSelect = false;
  @Input() selectActionsTemplate: TemplateRef<any>;
  @Input() selectAllByDefault = false;
  @Input() hover = false;
  @Output() rowClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Input() hasMasterToggle = true;
  @Input() selectedItems;

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("input", { static: false }) input: ElementRef;

  datasource: MatTableDataSource<any>;
  displayedColumns;
  selection = new SelectionModel(true, []);
  columnsDict = {};

  constructor() {
    this.datasource = new MatTableDataSource();

    this.datasourceOptions = new DatasourceOptions(
      0,
      1,
      "",
      1,
      10,
      "id",
      "desc"
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.data && this.data != null) {
      this.datasource.data = this.data;
      this.datasource.paginator = this.matPaginator;
      this.datasource.sort = this.sort;

      if (this.selectAllByDefault) {
        this.selection.clear();
        this.data.forEach((row) => this.selection.select(row));
      }
    }

    if (changes.columns && this.columns != null) {
      this.displayedColumns = this.columns.map((c) => c.clientFieldName);
      if (this.showSelect) {
        this.displayedColumns.splice(0, 0, "select");
      }

      this.columnsDict = this.columns.reduce((dict, curr) => {
        let colName = curr.clientFieldName;
        dict[colName] = curr;
        return dict;
      }, {});
    }

    if (this.selectedItems != null) {
      this.selection.select(...this.selectedItems);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // debugger;
      // this.datasource.paginator = this.matPaginator;
      // this.datasource.sort = this.sort;
    });

    // Un comment for server side support
    // this.sort.sortChange.subscribe(() => (this.matPaginator.pageIndex = 0));

    // fromEvent(this.input.nativeElement, "keyup")
    //   .pipe(
    //     debounceTime(150),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.matPaginator.pageIndex = 0;
    //       const options = new DatasourceOptions(
    //         -1,
    //         this.matPaginator.pageIndex + 1,
    //         this.input.nativeElement.value,
    //         -1,
    //         this.matPaginator.pageSize,
    //         this.sort.active != undefined ? this.sort.active : "id",
    //         this.sort.direction != "" ? this.sort.direction : "desc"
    //       );
    //       this.loadData.emit(options);
    //     })
    //   )
    //   .subscribe();

    // merge(this.sort.sortChange, this.matPaginator.page)
    //   .pipe(
    //     tap(() => {
    //       debugger;
    //       const options = new DatasourceOptions(
    //         -1,
    //         this.matPaginator.pageIndex + 1,
    //         this.input.nativeElement.value,
    //         -1,
    //         this.matPaginator.pageSize,
    //         this.sort.active != undefined ? this.sort.active : "id",
    //         this.sort.direction != "" ? this.sort.direction : "desc"
    //       );
    //       this.loadData.emit(options);
    //     })
    //   )
    //   .subscribe();

    this.datasource.sortingDataAccessor = (item, property) => {
      let sortBy = this.columnsDict[property].sortBy ?? property;
      return _.get(item, sortBy)
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.data.length) {
      this.selection.clear();
    } else {
      this.data.forEach((row) => this.selection.select(row));
    }
  }

  selectClicked(row) {
    this.selection.toggle(row);
    this.selectionChanged.emit({
      row: row,
      isSelected: this.selection.isSelected(row),
    });
  }

  ngOnDestroy() {}
}

export interface DataTableColumn {
  serverFieldName?: string;
  clientFieldName: string;
  displayNameKey: string;
  sortable: boolean;
  template?: TemplateRef<any>;
  width?: number;
  sortBy?: string;
}

import { updateTask } from "./../actions/task.actions";
import { Action, createReducer, on } from "@ngrx/store";
import * as PilotTasksActions from "../actions/pilot-tasks.actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Task } from "../../models/task.model";
import { MapTask } from "../../models/map-task.model";

export const pilotTasksFeatureKey = "pilotTasks";

export interface State extends EntityState<Task> {
  loading: boolean;
  currentTask: Task;
  mapTasks: MapTask[];
  selectedDate: Date;
}

export const allTasksAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: (task: Task) => task.taskId,
});

export const initialState: State = allTasksAdapter.getInitialState({
  loading: false,
  currentTask: null,
  mapTasks: null,
  selectedDate: new Date(),
});

const pilotTasksReducer = createReducer(
  initialState,

  on(PilotTasksActions.loadPilotTasks, (state, action) => {
    return {
      ...state,
      selectedDate: action.date,
      loading: true,
    };
  }),
  on(PilotTasksActions.loadPilotTasksSuccess, (state, action) => {
    return {
      ...allTasksAdapter.setAll(action.tasks, state),
      mapTasks: action.mapTasks,
      loading: false,
    };
  }),

  on(PilotTasksActions.updateTask, (state) => ({
    ...state,
    loading: true,
  })),

  on(
    PilotTasksActions.loadPilotTasksFailure,
    PilotTasksActions.updateTaskFailure,
    (state, action) => ({
      ...state,
      loading: false,
    })
  ),
  on(PilotTasksActions.loadTaskById, (state, action) => ({
    ...state,
    currentTask: null,
  })),
  on(PilotTasksActions.loadTaskByIdSuccess, (state, action) => ({
    ...state,
    currentTask: action.task,
  })),

  on(PilotTasksActions.updateTaskSuccess, (state, action) => {
    let mapTasks = [...state.mapTasks];
    let index = mapTasks.findIndex((m) => m.task.taskId == action.task.taskId);
    mapTasks[index] = new MapTask(action.task, false);

    return {
      ...allTasksAdapter.updateOne(
        { id: action.task.taskId, changes: action.task },
        state
      ),
      mapTasks: mapTasks,
      //currentTask: action.task,
      loading: false,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return pilotTasksReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = allTasksAdapter.getSelectors();

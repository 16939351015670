import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Field } from "../models/field.model";
import { map } from "rxjs/operators";
import { FieldImportWrapper } from "../models/field-import-wrapper.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FieldsService {
  constructor(private api: ApiService, private http: HttpClient) {}

  getAllFields() {
    return this.api.get("fields").pipe(
      map((result) => {
        const fields = result.map((d) => Field.parse(d));

        return fields;
      })
    );
  }

  getFieldById(id) {
    return this.api.get("fields/" + id).pipe(
      map((result) => {
        const field = Field.parse(result);

        return field;
      })
    );
  }

  addField(field: Field) {
    return this.api.post("fields", null, Field.parseBack(field)).pipe(
      map((result) => {
        return field;
      })
    );
  }

  updateField(field: Field) {
    return this.api.put("fields/" + field.fieldId, Field.parseBack(field)).pipe(
      map((result) => {
        return field;
      })
    );
  }

  deleteField(field: Field) {
    return this.api.delete("fields/" + field.fieldId).pipe(
      map((result) => {
        return field;
      })
    );
  }

  importFields(fieldImportWrapper: FieldImportWrapper) {
    let file = fieldImportWrapper.file[0];
    const formData: FormData = new FormData();

    formData.append("file", file, file.name);
    formData.append("customerId", fieldImportWrapper.customerId);
    return this.http.post(`${environment.api_url}kmlfile`, formData).pipe(
      map((result) => {
        return result;
      })
    );
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-drone-pilots-table",
  templateUrl: "./drone-pilots-table.component.html",
  styleUrls: ["./drone-pilots-table.component.scss"],
})
export class DronePilotsTableComponent implements OnInit, AfterViewInit {
  @Input() pilots: DronePilot[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deletePilot = new EventEmitter<DronePilot>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild("pilotTypeTemplate", { static: false })
  pilotTypeTemplate: TemplateRef<any>;

  @ViewChild("licenseGimelTemplate", { static: false })
  licenseGimelTemplate: TemplateRef<any>;

  @ViewChild("licenseRTTemplate", { static: false })
  licenseRtTemplate: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "name",
          clientFieldName: "name",
          displayNameKey: "Name",
          sortable: true,
        },
        {
          serverFieldName: "pilotType",
          clientFieldName: "pilotType",
          displayNameKey: "Pilot Type",
          sortable: true,
          template: this.pilotTypeTemplate,
        },
        {
          serverFieldName: "phone",
          clientFieldName: "phone",
          displayNameKey: "Phone",
          sortable: true,
        },
        {
          serverFieldName: "islicenseGimel",
          clientFieldName: "islicenseGimel",
          displayNameKey: "License Gimel",
          sortable: true,
          template: this.licenseGimelTemplate,
        },
        {
          serverFieldName: "islicenseRT",
          clientFieldName: "islicenseRT",
          displayNameKey: "License RT",
          sortable: true,
          template: this.licenseRtTemplate,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  editPilotClick(pilot: DronePilot) {
    this.router.navigate([pilot.pilotId], { relativeTo: this.route });
  }
}

import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Drone } from "../models/drone.model";
import { of } from "rxjs";
import { delay, map } from "rxjs/operators";
import * as _ from "lodash";
import { DronePayload } from "../models/drone-payload.model";
import { DroneModel } from "../models/drone-model.model";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class DronesService {
  constructor(private api: ApiService) {}

  getAllDrones() {
    return this.api.get("drones").pipe(
      map((result) => {
        const drones = result.map((d) => Drone.parse(d));

        return drones;
      })
    );
  }

  getAllModels() {
    return this.api.get("models").pipe(
      map((result) => {
        const models = result.map((m) => DroneModel.parse(m));

        return models;
      })
    );
  }

  getAllPayloads() {
    return this.api.get("payloads").pipe(
      map((result) => {
        const payloads = result.map((p) => DronePayload.parse(p));

        return payloads;
      })
    );
  }

  getDroneById(id) {
    return this.api.get("drones/" + id).pipe(
      map((result) => {
        const drone = Drone.parse(result);

        return drone;
      })
    );
  }

  addDrone(drone: Drone) {
    return this.api.post("drones", null, Drone.parseBack(drone)).pipe(
      map((result) => {
        return drone;
      })
    );
  }

  updateDrone(drone: Drone) {
    return this.api.put("drones/" + drone.droneId, Drone.parseBack(drone)).pipe(
      map((result) => {
        return drone;
      })
    );
  }

  deleteDrone(drone: Drone) {
    return this.api.delete("drones/" + drone.droneId).pipe(
      map((result) => {
        return drone;
      })
    );
  }
}

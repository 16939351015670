import { selectProtocolsSelector } from "./../../../../core/store/selectors/protocol.selectors";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Protocol } from "src/app/core/models/protocol.model";
import { Store, select } from "@ngrx/store";
import { ProtocolState } from "src/app/core/store/reducers/protocol.reducer";
import { LayoutUtilsService, MessageType } from "src/app/core/_base/crud";
import { selectProtocolsLoading } from "src/app/core/store/selectors/protocol.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  loadProtocols,
  deleteProtocol,
} from "src/app/core/store/actions/protocol.actions";

@Component({
  selector: "kt-protocols-list",
  templateUrl: "./protocols-list.component.html",
  styleUrls: ["./protocols-list.component.scss"],
})
export class ProtocolsListComponent implements OnInit, OnDestroy {
  loading;
  protocols: Protocol[];

  constructor(
    private store: Store<ProtocolState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectProtocolsLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.store
      .pipe(select(selectProtocolsSelector), untilDestroyed(this))
      .subscribe((protocols) => {
        this.protocols = protocols;
      });

    this.store.dispatch(loadProtocols());
  }

  deleteProtocol(_item: Protocol) {
    const _title = "Protocol Delete";
    const _description = "Are you sure to permanently delete this protocol?";
    const _waitDesciption = "Protocol is deleting...";
    const _deleteMessage = `Protocol has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteProtocol({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  ngOnDestroy(): void {}
}

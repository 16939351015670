<kt-datatable
  [loading]="loading"
  [data]="tasks"
  (loadData)="loadData.emit($event)"
  [columns]="columns"
  showSelect="true"
  [selectActionsTemplate]="selectActionsTemplate"
></kt-datatable>

<ng-template #materialTemplate let-row="row">
  <a [routerLink]="['../../materials/' + row.material.materialId]">{{
    row.material.name
  }}</a>
</ng-template>

<ng-template #fieldTemplate let-row="row">
  <a [routerLink]="['../../fields/' + row.field.fieldId]">{{
    row.field.plotNumber
  }}</a>
</ng-template>

<ng-template #statusTemplate let-row="row">
  {{ row.status.name }}
</ng-template>

<ng-template #customerTemplate let-row="row">
  <a
    [routerLink]="['../../customers/' + row.customer.customerId]"
    *ngIf="row.customer"
    >{{ row.customer.name }}</a
  >
</ng-template>

<ng-template #protocolTemplate let-row="row">
  <a
    [routerLink]="['../../protocols/' + row.protocol.protocolId]"
    *ngIf="row.protocol"
    >{{ row.protocol.name }}</a
  >
</ng-template>

<ng-template #createdAtTemplate let-row="row">
  {{ row.createdAt | date: "shortDate" }}
</ng-template>

<ng-template #pilotTemplate let-row="row">
  {{ row.pilot?.name }}
</ng-template>

<ng-template #executionDate let-row="row">
  {{ row.executionDate | date: "shortDate" }}
</ng-template>

<ng-template #selectActionsTemplate>
  <button
    mat-raised-button
    matTooltip="Fetch selected users"
    class="mat-button-mt-4"
  >
    <mat-icon>clear_all</mat-icon>
    Fetch Selected
  </button>
</ng-template>

<ng-template #actionsTemplate let-row="row">
  <button
    mat-icon-button
    color="warn"
    matTooltip="Delete task"
    type="button"
    (click)="deleteTask.emit(row)"
  >
    <mat-icon>delete</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    matTooltip="Edit task"
    (click)="editTaskClick(row)"
  >
    <mat-icon>create</mat-icon>
  </button>
</ng-template>

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Field } from "src/app/core/models/field.model";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Customer } from "src/app/core/models/customer.model";
import { Observable, ReplaySubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { FieldState } from "src/app/core/store/reducers/field.reducer";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  selectFieldsLoading,
  selectCurrentField,
} from "src/app/core/store/selectors/field.selectors";
import { RegEx } from "src/app/core/_base/crud/utils/regex-utils";
import { cloneDeep } from "lodash";
import { selectCustomersSelector } from "src/app/core/store/selectors/customer.selectors";
import {
  loadField,
  updateField,
  addField,
} from "src/app/core/store/actions/field.actions";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';

class WeatherService {
  private apiUrl = 'https://api.aksata360.com/api/weather';

  constructor(private http: HttpClient) {}

  getWeatherData(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }
}


@Component({
  selector: "kt-edit-field",
  templateUrl: "./edit-field.component.html",
  styleUrls: ["./edit-field.component.scss"],
})
export class EditFieldComponent implements OnInit, OnDestroy {
  title = "Add Field";
  isEdit = false;
  field: Field;
  fieldForm: FormGroup;
  hasFormErrors = false;
  customers: Customer[];
  loading$: Observable<boolean>;
  mapCenter;

  //weatherData: any[] = [];
  //private weatherService: WeatherService;

  dates: string[] = [];
  weatherData: any = {
    'Wind (kt)': {},
    'Gusts (kt)': {},
    'Temperature (°C)': {},
    'Rain (mm)': {},
    'Time (Hour)': {} // For storing times associated with each date
  };
  maxEntriesPerDate: { [date: string]: number } = {}; // Tracks the max number of entries for each date


  public customersSelectFormControl: FormControl = new FormControl();

  constructor(
    private route: ActivatedRoute,
    private store: Store<FieldState>,
    private fieldFB: FormBuilder,
    private http: HttpClient

  ) {
    //this.weatherService = new WeatherService(this.http);
  }

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectFieldsLoading),
      untilDestroyed(this)
    );

    this.field = Field.createEmpty();
    this.createForm();

    this.store
      .pipe(select(selectCurrentField), untilDestroyed(this))
      .subscribe((currentField) => {
        if (currentField && this.isEdit) {
          this.field = cloneDeep(currentField);
          this.createForm();
        }
      });

    this.store
      .pipe(select(selectCustomersSelector), untilDestroyed(this))
      .subscribe((c) => {
        this.customers = c;
      });

    let fieldId = this.route.snapshot.params["id"];
    if (fieldId && fieldId != "add") {
      this.isEdit = true;
      this.title = "Edit Field";

      this.store.dispatch(loadField({ data: fieldId }));

      this.http.get<any[]>('https://api.aksata360.com/api/weather?fieldId=' + fieldId).pipe(
      map(response => {
        const dateEntries: { [date: string]: any[] } = {}; // Temporary storage for processing

        response.forEach(item => {
          const [date, time] = item.ts.split('T');
          const formattedTime = time.slice(0, 2); // Extract hour and minute
          
          if (!dateEntries[date]) {
            dateEntries[date] = [];
          }

          // Push the entire item along with formatted time for later processing
          dateEntries[date].push({ ...item, time: formattedTime });

          // Update max entries per date
          this.maxEntriesPerDate[date] = Math.max(this.maxEntriesPerDate[date] || 0, dateEntries[date].length);
        });

        this.dates = Object.keys(dateEntries).sort();
        this.processDataForDisplay(dateEntries);
      })
    ).subscribe();
      
    }
  }
  processDataForDisplay(dateEntries: { [date: string]: any[] }) {
    // Process and structure data for display
    Object.entries(dateEntries).forEach(([date, entries]) => {
      entries.forEach((entry, index) => {
        if (!this.weatherData[index]) {
          this.weatherData[index] = { date: {}, data: [] }; // Initialize if not already
        }
        this.weatherData[index].date[date] = entry.time;

        this.weatherData[index].data.push({
          'Wind (kt)': entry['wind_v-surface'],
          'Gusts (kt)': entry['gusts-surface'],
          'Temperature (°C)': entry['temp-surface'],
          'Rain (mm)': entry['past3hconvprecip-surface'],
        });
      });
    });
  }

  createForm() {
    this.fieldForm = this.fieldFB.group({
      cropName: [this.field.cropName, Validators.required],
      plotNumber: [this.field.plotNumber, Validators.required],
      plantingYear: [this.field.plantingYear],
      moagId: [this.field.moagId],
      areaSquareMeters: [this.field.areaSquareMeters],
    });

    this.fieldForm.addControl(
      "customersSelectFormControl",
      this.customersSelectFormControl
    );
    this.customersSelectFormControl.setValidators(Validators.required);
    if (this.field.customer) {
      this.customersSelectFormControl.setValue(this.field.customer.customerId);
    }
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.fieldForm.controls;
    /** check form */
    if (this.fieldForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.prepareField();

    if (this.isEdit) {
      this.store.dispatch(updateField({ data: this.field }));
    } else {
      this.store.dispatch(addField({ data: this.field }));
    }
  }

  prepareField() {
    this.field.cropName = this.fieldForm.controls.cropName.value;
    this.field.plotNumber = this.fieldForm.controls.plotNumber.value;
    this.field.plantingYear = this.fieldForm.controls.plantingYear.value;
    this.field.moagId = this.fieldForm.controls.moagId.value;
    this.field.areaSquareMeters = this.fieldForm.controls.areaSquareMeters.value;
    this.field.customer = this.customers.find(
      (c) => c.customerId == this.customersSelectFormControl.value
    );
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

import { Action, createReducer, on, State } from "@ngrx/store";
import * as CustomerActions from "../actions/customer.actions";
import { Customer } from "../../models/customer.model";
import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";

export const customerFeatureKey = "customer";

export interface CustomersState extends EntityState<Customer> {
  loading: boolean;
  currentCustomer: Customer;
}

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>({
  selectId: (customer: Customer) => customer.customerId,
});

export const initialState: CustomersState = adapter.getInitialState({
  loading: false,
  currentCustomer: null,
});

const customerReducer = createReducer(
  initialState,

  on(CustomerActions.loadCustomers, (state) => state),
  on(CustomerActions.loadCustomersSuccess, (state, action) => {
    return { ...adapter.setAll(action.data, state), loading: false };
  }),
  on(CustomerActions.loadCustomersFailure, (state, action) => state),

  on(CustomerActions.addCustomer, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerActions.addCustomerSuccess, (state, action) => {
    return { ...adapter.addOne(action.data, state), loading: false };
  }),
  on(CustomerActions.addCustomerFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(CustomerActions.updateCustomer, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerActions.updateCustomerSuccess, (state, action) => {
    return { ...adapter.updateOne(action.data, state), loading: false };
  }),
  on(CustomerActions.updateCustomerFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(CustomerActions.deleteCustomer, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerActions.deleteCustomerSuccess, (state, action) => {
    return {
      ...adapter.removeOne(action.data.customerId, state),
      loading: false,
    };
  }),
  on(CustomerActions.addCustomerFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(CustomerActions.loadCustomer, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerActions.loadCustomerSuccess, (state, action) => {
    return { ...state, currentCustomer: action.data, loading: false };
  }),
  on(CustomerActions.loadCustomerFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: CustomersState | undefined, action: Action) {
  return customerReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

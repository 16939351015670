<kt-generic-portlet
  [title]="'Import Fields'"
  [actionsTemplate]="actionsTemplate"
  [loading]="loading$"
>
  <form [formGroup]="fieldForm" class="form form-group-seperator-dashed">
    <kt-alert
      *ngIf="hasFormErrors"
      type="warn"
      [showCloseButton]="true"
      [duration]="10000"
      (close)="onAlertClose($event)"
    >
      Oh snap! Change a few things up and try submitting again.
    </kt-alert>
    <div class="form-section form-section-first">
      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <kt-mat-select-filter
            [dataSet]="customers"
            [filterBy]="'name'"
            [valueProperty]="'customerId'"
            [selectFormControl]="customersSelectFormControl"
            [error]="'Customer is required'"
            [hint]="'Please select a customer'"
            [placeHolder]="'Select a customer'"
          >
          </kt-mat-select-filter>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-12 kt-margin-bottom-20-mobile">
          <file-upload
            accept=".kml"
            formControlName="fileUploadControl"
            [multiple]="'false'"
            [animation]="'true'"
          ></file-upload>
          <mat-error *ngIf="filesControl.invalid && filesControl.touched"
            >File is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
    </div>
  </form>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a
    href="javascript:;"
    class="btn btn-primary mr-2"
    color="primary"
    (click)="onSumbit(false)"
    mat-raised-button
    matTooltip="Save & Continue"
  >
    <span class="hidden-mobile">Save</span>
  </a>

  <a [routerLink]="['../']" class="btn btn-secondary mr-2" mat-raised-button>
    <i class="la la-arrow-left"></i>
    <span class="hidden-mobile">Back</span>
  </a>
</ng-template>

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as DronePilotsActions from "../actions/drone-pilots.actions";
import { DronePilotsService } from "../../services/drone-pilots.service";
import { LayoutUtilsService, MessageType } from "../../_base/crud";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class DronePilotsEffects {
  loadDronePilots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronePilotsActions.loadDronePilots),
      concatMap(() =>
        this.dronePilotsService.getAllDronePilots().pipe(
          map((data) => {
            return DronePilotsActions.loadDronePilotsSuccess({ data });
          }),
          catchError((error) =>
            of(DronePilotsActions.loadDronePilotsFailure({ error }))
          )
        )
      )
    );
  });

  loadPilotTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronePilotsActions.loadPilotTypes),
      concatMap(() =>
        this.dronePilotsService.getAllPilotTypes().pipe(
          map((data) => {
            return DronePilotsActions.loadPilotTypesSuccess({ data });
          }),
          catchError((error) =>
            of(DronePilotsActions.loadDronePilotsFailure({ error }))
          )
        )
      )
    );
  });

  loadDronePilot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronePilotsActions.loadDronePilot),
      concatMap((action) =>
        this.dronePilotsService.getPilotById(action.data).pipe(
          map((data) => {
            return DronePilotsActions.loadDronePilotSuccess({ data });
          }),
          catchError((error) =>
            of(DronePilotsActions.loadDronePilotFailure({ error }))
          )
        )
      )
    );
  });

  addDronePilot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronePilotsActions.addDronePilot),
      concatMap((action) =>
        this.dronePilotsService.addDronePilot(action.data).pipe(
          map((data) => {
            return DronePilotsActions.addDronePilotSuccess({ data });
          }),
          catchError((error) =>
            of(DronePilotsActions.addDronePilotFailure({ error }))
          )
        )
      )
    );
  });

  addDronePilotSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronePilotsActions.addDronePilotSuccess),
        tap((action) => {
          const message = `Drone pilot successfully has been created.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );

          this.router.navigate(["drone-pilots"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  addDronePilotFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronePilotsActions.addDronePilotFailure),
        tap((action) => {
          const message = `Drone pilot add failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  updatePilot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronePilotsActions.updateDronePilot),
      concatMap((action) =>
        this.dronePilotsService.updatePilot(action.data).pipe(
          map((data) => {
            return DronePilotsActions.updateDronePilotSuccess({ data });
          }),
          catchError((error) =>
            of(DronePilotsActions.updateDronePilotFailure({ error }))
          )
        )
      )
    );
  });

  updatePilotSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronePilotsActions.updateDronePilotSuccess),
        tap((action) => {
          const message = `Drone pilot successfully has been saved.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );

          this.router.navigate(["drone-pilots"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  deletePilot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronePilotsActions.deleteDronePilot),
      concatMap((action) =>
        this.dronePilotsService.deletePilot(action.data).pipe(
          map((data) => {
            return DronePilotsActions.deleteDronePilotSuccess({ data });
          }),
          catchError((error) =>
            of(DronePilotsActions.deleteDronePilotFailure({ error }))
          )
        )
      )
    );
  });

  deletePilotSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronePilotsActions.deleteDronePilotFailure),
        tap((action) => {
          const message = `Drone pilot successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private dronePilotsService: DronePilotsService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

import { DronePilot } from "./drone-pilot.model";
import { Material } from "./material.model";
import { Field } from "./field.model";
import { Customer } from "./customer.model";
import { Protocol } from "./protocol.model";
import { Status } from "./status.model";

export class Task {
  static parseBack(task: Task): object {
    return {
      customerId: task.customer.customerId,
      protocolId: task.protocol.protocolId,
      materialId: task.material.materialId,
      //statusId: task.status.statusId,
      executionDate: task.executionDate.toDateString(),
      //pilotId: task.pilot.pilotId,
      fieldId: task.field?.fieldId,
    };
  }
  //added this because on update it didnt update statusid and pilotid
  static parseBackUpdate(task: Task): object {
    return {
      customerId: task.customer.customerId,
      protocolId: task.protocol.protocolId,
      materialId: task.material.materialId,
      statusId: task.status.statusId,
      scheduledDate: task.scheduledDate?.toDateString(),
      executionDate: task.executionDate?.toDateString(),
      approvedExecutionDate: task.approvedExecutionDate?.toDateString(),
      executionStatusId: task.executionStatus?.statusId,
      approvedStatusId: task.approvedStatus?.statusId,
      pilotId: task.pilot.pilotId,
      fieldId: task.field?.fieldId,
    };
  }
  static parse(d: any) {
    let field = Field.parse(d.Field);
    let customer = field.customer;
    let protocol = Protocol.parse(d.Protocol);
    let material = Material.parse(d.Material);
    let status = JSON.parse(JSON.stringify(d.Status));
    let approvedStatus = JSON.parse(JSON.stringify(d.ApprovedStatus));
    let executionStatus = JSON.parse(JSON.stringify(d.ExecutionStatus));
    let createdAt = new Date(d.createdDate);
    let executionDate = d.executionDate ? new Date(d.executionDate) : new Date();
    let scheduledDate = d.scheduledDate ? new Date(d.scheduledDate) : null;
    let approvedExecutionDate = d.approvedExecutionDate ? new Date(d.approvedExecutionDate) : null;
    let executionStatusId = d.executionStatusId;

    let pilot = DronePilot.parse(d.Pilot);

    return new Task(
      d.taskId,
      customer,
      field,
      material,
      protocol,
      pilot,
      status,
      createdAt,
      executionDate,
      scheduledDate,
      approvedExecutionDate,
      executionStatusId,
      executionStatus,
      approvedStatus
    );
  }
  constructor(
    public taskId: string,
    public customer: Customer,
    public field: Field,
    public material: Material,
    public protocol: Protocol,
    public pilot: DronePilot,
    public status: Status,
    public createdAt: Date,
    public executionDate: Date,
    public scheduledDate: Date,
    public approvedExecutionDate: Date,
    public executionStatusId: string,
    public executionStatus: Status,
    public approvedStatus: Status
  ) {}
}

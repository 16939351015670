import { createAction, props } from "@ngrx/store";

export const loadDrones = createAction("[Drones] Load Droness");

export const loadDronesSuccess = createAction(
  "[Drones] Load Droness Success",
  props<{ data: any }>()
);

export const loadDronesFailure = createAction(
  "[Drones] Load Droness Failure",
  props<{ error: any }>()
);

export const loadDroneModels = createAction("[Drones] Load Drones Models");

export const loadDroneModelsSuccess = createAction(
  "[Drones] Load Droness Models  Success",
  props<{ data: any }>()
);

export const loadDroneModelsFailure = createAction(
  "[Drones] Load Droness Models Failure",
  props<{ error: any }>()
);

export const loadDronePayloads = createAction("[Drones] Load Drones Payloads");

export const loadDronePayloadsSuccess = createAction(
  "[Drones] Load Droness Payloads  Success",
  props<{ data: any }>()
);

export const loadDronePayloadsFailure = createAction(
  "[Drones] Load Droness Models Failure",
  props<{ error: any }>()
);

export const loadDrone = createAction(
  "[Drones] Load Drone",
  props<{ data: any }>()
);

export const loadDroneSuccess = createAction(
  "[Drones] Load Drone Success",
  props<{ data: any }>()
);

export const loadDroneFailure = createAction(
  "[Drones] Load Drone Failure",
  props<{ error: any }>()
);

export const addDrone = createAction(
  "[Drones] AddDrone",
  props<{ data: any }>()
);

export const addDroneSuccess = createAction(
  "[Drones] AddDrone Success",
  props<{ data: any }>()
);

export const addDroneFailure = createAction(
  "[Drones] AddDrone Failure",
  props<{ error: any }>()
);

export const updateDrone = createAction(
  "[Drones] UpdateDrone",
  props<{ data: any }>()
);

export const updateDroneSuccess = createAction(
  "[Drones] UpdateDrone Success",
  props<{ data: any }>()
);

export const updateDroneFailure = createAction(
  "[Drones] UpdateDrone Failure",
  props<{ error: any }>()
);

export const deleteDrone = createAction(
  "[Drones] DeleteDrone",
  props<{ data: any }>()
);

export const deleteDroneSuccess = createAction(
  "[Drones] DeleteDrone Success",
  props<{ data: any }>()
);

export const deleteDroneFailure = createAction(
  "[Drones] DeleteDrone Failure",
  props<{ error: any }>()
);

 import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../auth';

@Injectable({providedIn: 'root'})
export class AuthService {
    constructor(private api: ApiService) {}

    getToken() {        
        return localStorage.getItem(environment.authTokenKey);
    }

    login(username: string, password: string){
        return this.api.post("login", null, {username: username, password: password})
      }

      getUserByToken() {          
          return this.api.get("login").pipe(map(result => {             
              const user = User.parse(result);
              return user;
          }))
      }
    
    
}
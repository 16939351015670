import { createAction, props } from "@ngrx/store";
import { Material } from "../../models/material.model";

export const loadMaterials = createAction("[Material] Load Materials");

export const loadMaterialsSuccess = createAction(
  "[Material] Load Materials Success",
  props<{ data: any }>()
);

export const loadMaterialsFailure = createAction(
  "[Material] Load Materials Failure",
  props<{ error: any }>()
);

export const loadMaterial = createAction(
  "[Material] Load Material",
  props<{ data: any }>()
);

export const loadMaterialSuccess = createAction(
  "[Material] Load Material Success",
  props<{ data: any }>()
);

export const loadMaterialFailure = createAction(
  "[Material] Load Material Failure",
  props<{ error: any }>()
);

export const addMaterial = createAction(
  "[Material] AddMaterial",
  props<{ data: any }>()
);

export const addMaterialSuccess = createAction(
  "[Material] AddMaterial Success",
  props<{ data: Material }>()
);

export const addMaterialFailure = createAction(
  "[Material] AddMaterial Failure",
  props<{ error: any }>()
);

export const updateMaterial = createAction(
  "[Material] UpdateMaterial",
  props<{ data: any }>()
);

export const updateMaterialSuccess = createAction(
  "[Material] UpdateMaterial Success",
  props<{ data: any }>()
);

export const updateMaterialFailure = createAction(
  "[Material] UpdateMaterial Failure",
  props<{ error: any }>()
);

export const deleteMaterial = createAction(
  "[Material] DeleteMaterial",
  props<{ data: any }>()
);

export const deleteMaterialSuccess = createAction(
  "[Material] DeleteMaterial Success",
  props<{ data: any }>()
);

export const deleteMaterialFailure = createAction(
  "[Material] DeleteMaterial Failure",
  props<{ error: any }>()
);

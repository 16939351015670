import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
import { User } from "../auth/_models/user.model";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private api: ApiService) {}

  getAllUsers() {
    return this.api.get("users").pipe(
      map((result) => {
        const users = result.map((d) => User.parse(d));

        return users;
      })
    );
  }

  getUserById(id) {
    return this.api.get("users/" + id).pipe(
      map((result) => {
        const user = User.parse(result);

        return user;
      })
    );
  }

  getAllRoleTypes() {
    return this.api.get("roleTypes").pipe(
      map((result) => {
        const roleTypes = result.map((r) => JSON.parse(JSON.stringify(r)));

        return roleTypes;
      })
    );
  }

  addUser(user: User) {
    return this.api.post("users", null, User.parseBack(user)).pipe(
      map((result) => {
        return user;
      })
    );
  }

  updateUser(user: User) {
    return this.api.put("users/" + user.userId, User.parseBack(user)).pipe(
      map((result) => {
        return user;
      })
    );
  }

  deleteUser(user: User) {
    return this.api.delete("users/" + user.userId).pipe(
      map((result) => {
        return user;
      })
    );
  }
}

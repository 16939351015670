import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpUtilsService } from '../_base/crud';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, httpUtils: HttpUtilsService) { }

  
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
        .get(`${environment.api_url}${path}`, { params })
        .pipe();
}

put(path: string, body: object = {}): Observable<any> {
    const headers = new HttpHeaders().set(
        "Content-Type",
        "application/json"
    );

    return this.http.put(
        `${environment.api_url}${path}`,
        JSON.stringify(body),
        { headers }
    );
}

post(
    path: string,
    params: HttpParams = new HttpParams(),
    body: object = {}
): Observable<any> {
    const headers = new HttpHeaders({
        "Content-Type": "application/json"
    });

    return this.http.post(
        `${environment.api_url}${path}`,
        JSON.stringify(body),
        { headers, params }
    );
}

delete(path: string,  params: HttpParams = new HttpParams()): Observable<any> {
  return this.http.delete(
      `${environment.api_url}${path}`, {params}
  );
}
}

import { cloneDeep } from "lodash";
import { selectCurrentTask } from "./../../../../core/store/selectors/task.selectors";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Status } from "src/app/core/models/status.model";
import { Customer } from "src/app/core/models/customer.model";
import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { Material } from "src/app/core/models/material.model";
import { Protocol } from "src/app/core/models/protocol.model";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Store, select } from "@ngrx/store";
import {
  selectTasksLoading,
  selectStatuses,
} from "src/app/core/store/selectors/task.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { selectCustomersSelector } from "src/app/core/store/selectors/customer.selectors";
import { selectMaterialsSelector } from "src/app/core/store/selectors/material.selectors";
import { selectProtocolsSelector } from "src/app/core/store/selectors/protocol.selectors";
import { selectPilotsSelector } from "src/app/core/store/selectors/drone-pilots.selectors";
import { Task } from "src/app/core/models/task.model";
import { ActivatedRoute, Router } from "@angular/router";
import { loadTask, updateTask } from "src/app/core/store/actions/task.actions";

@Component({
  selector: "kt-task-edit",
  templateUrl: "./task-edit.component.html",
  styleUrls: ["./task-edit.component.scss"],
})
export class TaskEditComponent implements OnInit, OnDestroy {
  loading$;
  task: Task;
  taskForm: FormGroup;
  hasFormErrors = false;

  statuses: Status[];
  customers: Customer[];
  pilots: DronePilot[];
  materials: Material[];
  protocols: Protocol[];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private taskFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksLoading));

    this.store
      .pipe(select(selectStatuses), untilDestroyed(this))
      .subscribe((s) => {
        this.statuses = s;
      });

    this.store
      .pipe(select(selectCustomersSelector), untilDestroyed(this))
      .subscribe((c) => {
        this.customers = c;
      });

    this.store
      .pipe(select(selectMaterialsSelector), untilDestroyed(this))
      .subscribe((m) => {
        this.materials = m;
      });

    this.store
      .pipe(select(selectProtocolsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.protocols = p;
      });

    this.store
      .pipe(select(selectPilotsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.pilots = p;
      });

    let taskId = this.route.snapshot.params["id"];

    if (taskId) {
      this.store.dispatch(loadTask({ data: taskId }));
    }

    this.store
      .pipe(select(selectCurrentTask), untilDestroyed(this))
      .subscribe((task) => {
        if (task) {
          this.store
            .pipe(select(selectCurrentTask), untilDestroyed(this))
            .subscribe((task) => {
              this.task = cloneDeep(task);
              this.createForm();
            });
        }
      });
  }

  createForm() {
    this.taskForm = this.taskFB.group({
      status: [this.task.status.statusId, Validators.required],
      executionDate: [this.task.executionDate],
      scheduledDate: [this.task.scheduledDate, Validators.required],
      executionStatus: [this.task.executionStatus?.statusId],
      approvedDate: [this.task.approvedExecutionDate],
      approvedStatus: [this.task.approvedStatus?.statusId],
      materialsSelectFormControl: [this.task.material?.materialId, Validators.required],
      pilotsSelectFormControl: [this.task.pilot?.pilotId, Validators.required],
      customersSelectFormControl: [this.task.customer?.customerId, Validators.required],
      protocolsSelectFormControl:  [this.task.protocol?.protocolId, Validators.required],
    });
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.taskForm.controls;

    if (this.taskForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.prepareTask();

    this.store.dispatch(updateTask({ task: this.task }));
  }

  prepareTask() {
    this.task.protocol = this.protocols.find(
      (p) => p.protocolId == this.taskForm.controls.protocolsSelectFormControl.value
    );
    this.task.material = this.materials.find(
      (m) => m.materialId == this.taskForm.controls.materialsSelectFormControl.value
    );
    this.task.customer = this.customers.find(
      (c) => c.customerId == this.taskForm.controls.customersSelectFormControl.value
    );
    this.task.status = this.statuses.find(
      (s) => s.statusId == this.taskForm.controls.status.value
    );
    this.task.executionStatus = this.statuses.find(
      (s) => s.statusId == this.taskForm.controls.executionStatus.value
    );
    this.task.approvedStatus = this.statuses.find(
      (s) => s.statusId == this.taskForm.controls.approvedStatus.value
    );
    this.task.pilot = this.pilots.find(
      (p) => p.pilotId == this.taskForm.controls.pilotsSelectFormControl.value
    );
    this.task.executionDate = this.taskForm.controls.executionDate.value;
    this.task.scheduledDate = this.taskForm.controls.scheduledDate.value;
    this.task.approvedExecutionDate = this.taskForm.controls.approvedDate.value;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

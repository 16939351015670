import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  concatMap,
  tap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as TaskActions from "../actions/task.actions";
import { TasksService } from "../../services/tasks.service";
import { LayoutUtilsService, MessageType } from "../../_base/crud";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TaskState } from "../reducers/task.reducer";
import { selectTaskFilterWrapper } from "../selectors/task.selectors";
import { filterControllerTasks } from "../actions/task.actions";

@Injectable()
export class TaskEffects {
  loadTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.loadTasks),
      concatMap(() =>
        this.tasksService.getAllTasks().pipe(
          map((data) => {
            return TaskActions.loadTasksSuccess({ data });
          }),
          catchError((error) => of(TaskActions.loadTasksFailure({ error })))
        )
      )
    );
  });

  loadTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.loadTask),
      concatMap((action) =>
        this.tasksService.getTaskById(action.data).pipe(
          map((data) => {
            return TaskActions.loadTaskSuccess({ data });
          }),
          catchError((error) => of(TaskActions.loadTaskFailure({ error })))
        )
      )
    );
  });

  updateTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.updateTask),
      concatMap((action) =>
        this.tasksService.updateTask(action.task).pipe(
          map((data) => {
            return TaskActions.updateTaskSuccess({ data: action.task });
          }),
          catchError((error) => of(TaskActions.updateTaskFailure({ error })))
        )
      )
    );
  });

  updateTaskSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskActions.updateTaskSuccess),
        tap((action) => {
          const message = "Task updated successfully";
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            false
          );
          //commented this because it redirects back to planning tool when updating from tasks list
          //this.router.navigateByUrl("tasks/map");
        })
      ),
    { dispatch: false }
  );

  filterTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.filterTasks),
      concatMap((action) =>
        this.tasksService.filterTasks(action.data).pipe(
          map((data) => {
            return TaskActions.filterTasksSuccess({ data });
          }),
          catchError((error) => of(TaskActions.filterTasksFailed({ error })))
        )
      )
    );
  });

  filterTaskSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskActions.filterTasksSuccess),
        tap((action) => {
          const message = action.data.length + ` tasks filtered`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  filterTaskControllerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskActions.filterControllerTasksSuccess),
        tap((action) => {
          const message =
            action.mapTasks.length +
            ` tasks filtered. ` +
            action.droneRoutes.length +
            ` drone routes filtered`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  filterControllerTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.filterControllerTasks),
      concatMap((action) =>
        this.tasksService.filterControllerTasks(action.data).pipe(
          map((data) => {
            return TaskActions.filterControllerTasksSuccess({
              mapTasks: data.mapTasks,
              droneRoutes: data.droneRoutes,
            });
          }),
          catchError((error) =>
            of(TaskActions.filterControllerTasksFailed({ error }))
          )
        )
      )
    );
  });

  filterCompletedTasksSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskActions.filterCompletedTasksSuccess),
        tap((action) => {
          const message = action.tasks.length + ` tasks filtered`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  filterCompletedTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.filterCompletedTasks),
      concatMap((action) =>
        this.tasksService.filterCompletedTasks(action.data).pipe(
          map((data) => {
            return TaskActions.filterCompletedTasksSuccess({ tasks: data });
          }),
          catchError((error) =>
            of(TaskActions.filterCompletedTasksFailed({ error }))
          )
        )
      )
    );
  });

  assignTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.assignTasks),
      concatMap((action) =>
        this.tasksService.assignTasks(action.data).pipe(
          map(() => {
            return TaskActions.assignTasksSuccess();
          }),
          catchError((error) => of(TaskActions.assignTasksFailed({ error })))
        )
      )
    );
  });

  assignControllerTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.assignControllerTasks),
      concatMap((action) =>
        this.tasksService.assignControllerTasks(action.data).pipe(
          map(() => {
            return TaskActions.assignControllerTasksSuccess();
          }),
          catchError((error) =>
            of(TaskActions.assignControllerTasksFailed({ error }))
          )
        )
      )
    );
  });

  assignTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.assignTasksSuccess),
      concatMap(() =>
        of([]).pipe(
          withLatestFrom(this.store$.select(selectTaskFilterWrapper)),
          map(([action, filterWrapper]) => {
            const message = `Tasks assign successfully`;
            this.layoutUtilsService.showActionNotification(
              message,
              MessageType.Read,
              5000,
              true,
              false
            );

            return TaskActions.filterTasks({
              data: filterWrapper,
            });
          })
        )
      )
    )
  );

  assignControllerTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.assignControllerTasksSuccess),
      concatMap(() =>
        of([]).pipe(
          withLatestFrom(this.store$.select(selectTaskFilterWrapper)),
          map(([action, filterWrapper]) => {
            const message = `Tasks assign successfully`;
            this.layoutUtilsService.showActionNotification(
              message,
              MessageType.Read,
              5000,
              true,
              false
            );

            return TaskActions.filterTasks({
              data: filterWrapper,
            });
          })
        )
      )
    )
  );

  loadStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.loadStatuses),
      concatMap(() =>
        this.tasksService.getStauses().pipe(
          map((data) => {
            return TaskActions.loadStatusesSuccess({ data });
          }),
          catchError((error) => of(TaskActions.loadStatusesFailure({ error })))
        )
      )
    );
  });

  deleteTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.deleteTask),
      concatMap((action) =>
        this.tasksService.deleteTask(action.data).pipe(
          map((data) => {
            return TaskActions.deleteTaskSuccess({ data });
          }),
          catchError((error) => of(TaskActions.deleteTaskFailure({ error })))
        )
      )
    );
  });

  deleteTaskSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskActions.deleteTaskSuccess),
        tap((action) => {
          const message = `Task successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  importTasksPreview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.importTasksPreview),
      concatMap((action) =>
        this.tasksService.importTasks(action.task, action.file, true).pipe(
          map((data) => {
            return TaskActions.importTasksPreviewSuccess({ data });
          }),
          catchError((error) =>
            of(TaskActions.importTasksPreviewFailure({ error }))
          )
        )
      )
    );
  });

  importTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.importTasks),
      concatMap((action) =>
        this.tasksService.importTasks(action.task, action.file).pipe(
          map((data) => {
            return TaskActions.importTasksSuccess({ data });
          }),
          catchError((error) => of(TaskActions.importTasksFailure({ error })))
        )
      )
    );
  });

  importTasksSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TaskActions.importTasksSuccess),
        tap((action) => {
          const message = `Task successfully has been imported.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private tasksService: TasksService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<TaskState>
  ) {}
}

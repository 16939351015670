import { importTasksPreviewSuccess } from "./../../../../core/store/actions/task.actions";
import { ofType, Actions } from "@ngrx/effects";
import { selectPilotsSelector } from "./../../../../core/store/selectors/drone-pilots.selectors";
import { DronePilot } from "./../../../../core/models/drone-pilot.model";
import { selectProtocolsSelector } from "./../../../../core/store/selectors/protocol.selectors";
import { selectMaterialsSelector } from "../../../../core/store/selectors/material.selectors";
import { Material } from "../../../../core/models/material.model";
import {
  selectTasksLoading,
  selectStatuses,
} from "../../../../core/store/selectors/task.selectors";
import { TaskState } from "../../../../core/store/reducers/task.reducer";
import { Field } from "../../../../core/models/field.model";
import { Protocol } from "../../../../core/models/protocol.model";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Task } from "src/app/core/models/task.model";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Observable, ReplaySubject } from "rxjs";
import { Customer } from "src/app/core/models/customer.model";
import { Status } from "src/app/core/models/status.model";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { selectCustomersSelector } from "src/app/core/store/selectors/customer.selectors";
import {
  importTasks,
  importTasksPreview,
} from "src/app/core/store/actions/task.actions";
import { ImportTasksPreview } from "src/app/core/models/import-tasks-preview.model";

@Component({
  selector: "kt-import-task",
  templateUrl: "./import-task.component.html",
  styleUrls: ["./import-task.component.scss"],
})
export class ImportTaskComponent implements OnInit, OnDestroy {
  title = "Import tasks";
  task: Task;
  taskForm: FormGroup;
  hasFormErrors = false;
  loading$: Observable<boolean>;

  //statuses: Status[];
  customers: Customer[];
  protocols: Protocol[];
  //pilots: DronePilot[];
  materials: Material[];

  preview: ImportTasksPreview;

  public customersSelectFormControl: FormControl = new FormControl();

  public materialsSelectFormControl: FormControl = new FormControl();

  public protocolsSelectFormControl: FormControl = new FormControl();

  //public pilotsSelectFormControl: FormControl = new FormControl();

  public filesControl = new FormControl(null, [Validators.required]);

  constructor(
    private store: Store<TaskState>,
    private taskFB: FormBuilder,
    private actions$: Actions
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectTasksLoading),
      untilDestroyed(this)
    );

    // this.store
    //   .pipe(select(selectStatuses), untilDestroyed(this))
    //   .subscribe((s) => {
    //     this.statuses = s;
    //   });

    this.store
      .pipe(select(selectCustomersSelector), untilDestroyed(this))
      .subscribe((c) => {
        this.customers = c;
      });

    this.store
      .pipe(select(selectMaterialsSelector), untilDestroyed(this))
      .subscribe((m) => {
        this.materials = m;
      });

    this.store
      .pipe(select(selectProtocolsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.protocols = p;
      });

    // this.store
    //   .pipe(select(selectPilotsSelector), untilDestroyed(this))
    //   .subscribe((p) => {
    //     this.pilots = p;
    //   });

    this.actions$
      .pipe(ofType(importTasksPreviewSuccess), untilDestroyed(this))
      .subscribe((action) => {
        this.preview = action.data;
      });

    this.createForm();
  }

  createForm() {
    this.taskForm = this.taskFB.group({
      executionDate: [null],
    });

    this.taskForm.addControl(
      "customersSelectFormControl",
      this.customersSelectFormControl
    );
    this.customersSelectFormControl.setValidators(Validators.required);

    this.taskForm.addControl(
      "protocolsSelectFormControl",
      this.protocolsSelectFormControl
    );
    this.protocolsSelectFormControl.setValidators(Validators.required);

    this.taskForm.addControl(
      "materialsSelectFormControl",
      this.materialsSelectFormControl
    );
    this.materialsSelectFormControl.setValidators(Validators.required);

    // this.taskForm.addControl(
    //   "pilotsSelectFormControl",
    //   this.pilotsSelectFormControl
    // );
    // this.pilotsSelectFormControl.setValidators(Validators.required);

    this.taskForm.addControl("fileUploadControl", this.filesControl);

    this.taskForm.valueChanges.subscribe((v) => {
      //if (this.taskForm.valid) {
      if (this.filesControl.valid) {
        let task = new Task(
          null,
          null,
          null,
          null,
          null,
          null, // pilot,
          null, // status,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );

        this.store.dispatch(
          importTasksPreview({ task: task, file: this.filesControl.value })
        );
      }
    });
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.taskForm.controls;
    /** check form */
    if (this.taskForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    let task = this.prepareTask();

    this.store.dispatch(
      importTasks({ task: task, file: this.filesControl.value })
    );
  }

  prepareTask() {
    let customer = this.customers.find(
      (c) => c.customerId == this.customersSelectFormControl.value
    );
    let protocol = this.protocols.find(
      (c) => c.protocolId == this.protocolsSelectFormControl.value
    );
    let material = this.materials.find(
      (c) => c.materialId == this.materialsSelectFormControl.value
    );

    let task = new Task(
      null,
      customer,
      null,
      material,
      protocol,
      null, // pilot,
      null, // status,
      null,
      this.taskForm.controls.executionDate.value,
      null,
      null,
      null,
      null,
      null
    );

    return task;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromDrones from "../reducers/drones.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectDronesState = createFeatureSelector<fromDrones.DroneState>(
  fromDrones.dronesFeatureKey
);

const selectDronesSelector = createSelector(
  selectDronesState,
  fromDrones.selectAll
);

export const selectDrones = pipe(select(selectDronesSelector), skip(1));

export const selectModelsSelector = createSelector(
  selectDronesState,
  (state) => state.models
);


export const selectPayloadsSelector = createSelector(
  selectDronesState,
  (state) => state.payloads
);

export const selectDronesLoading = createSelector(
  selectDronesState,
  (state) => state.loading
);

export const selectCurrentDrone = createSelector(
  selectDronesState,
  (state) => state.currentDrone
);

export const selectDroneById = (id) =>
  createSelector(selectDronesState, (state) => state.entities[id]);



<kt-datatable
  [loading]="loading"
  [data]="pilots"
  (loadData)="loadData.emit($event)"
  [columns]="columns"
  showSelect="true"
  [selectActionsTemplate]="selectActionsTemplate"
></kt-datatable>

<ng-template #actionsTemplate let-row="row">
  <button
    mat-icon-button
    color="primary"
    matTooltip="Edit drone pilot"
    (click)="editPilotClick(row)"
  >
    <mat-icon>create</mat-icon>
  </button>

  <button
    mat-icon-button
    color="warn"
    matTooltip="Delete drone pilot"
    type="button"
    (click)="deletePilot.emit(row)"
  >
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>

<ng-template #pilotTypeTemplate let-row="row">
  {{ row.pilotType.name }}
</ng-template>

<ng-template #licenseGimelTemplate let-row="row">
  {{ row.isLicenseGimel ? "Yes" : "No" }}
</ng-template>

<ng-template #licenseRTTemplate let-row="row">
  {{ row.isLicenseRT ? "Yes" : "No" }}
</ng-template>

<ng-template #selectActionsTemplate>
  <button
    mat-raised-button
    matTooltip="Fetch selected users"
    class="mat-button-mt-4"
  >
    <mat-icon>clear_all</mat-icon>
    Fetch Selected
  </button>
</ng-template>

<kt-datatable
  [loading]="loading"
  [data]="customers"
  (loadData)="loadData.emit($event)"
  [columns]="columns"
  showSelect="true"
  [selectActionsTemplate]="selectActionsTemplate"
></kt-datatable>

<ng-template #actionsTemplate let-row="row">
  <button
    mat-icon-button
    color="primary"
    matTooltip="Edit customer"
    (click)="editCustomerClick(row)"
  >
    <mat-icon>create</mat-icon>
  </button>

  <button
    mat-stroked-button
    color="primary"
    matTooltip="View customer's fields"
    (click)="goToCustomerFields(row)"
  >
    Fields
  </button>

  <button
    mat-icon-button
    color="warn"
    type="button"
    matTooltip="Delete customer"
    (click)="deleteCustomer.emit(row)"
  >
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>

<ng-template #selectActionsTemplate>
  <button
    mat-raised-button
    matTooltip="Fetch selected users"
    class="mat-button-mt-4"
  >
    <mat-icon>clear_all</mat-icon>
    Fetch Selected
  </button>
</ng-template>

import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { User } from "src/app/core/auth";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-users-table",
  templateUrl: "./users-table.component.html",
  styleUrls: ["./users-table.component.scss"],
})
export class UsersTableComponent implements OnInit, AfterViewInit {
  @Input() users: User[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deleteUser = new EventEmitter<User>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild("roleTemplate", { static: false }) roleTemplate: TemplateRef<any>;
  @ViewChild("customerTemplate", { static: false })
  customerTemplate: TemplateRef<any>;

  @ViewChild("pilotTemplate", { static: false })
  pilotTemplate: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "username",
          clientFieldName: "username",
          displayNameKey: "Username",
          sortable: true,
        },
        {
          serverFieldName: "phone",
          clientFieldName: "phone",
          displayNameKey: "Phone",
          sortable: true,
        },
        {
          serverFieldName: "email",
          clientFieldName: "email",
          displayNameKey: "Email",
          sortable: true,
        },
        {
          serverFieldName: "customer",
          clientFieldName: "customer",
          displayNameKey: "Customer",
          sortable: true,
          template: this.customerTemplate,
        },
        {
          serverFieldName: "roleType",
          clientFieldName: "roleType",
          displayNameKey: "Role",
          sortable: true,
          template: this.roleTemplate,
        },
        {
          serverFieldName: "pilot",
          clientFieldName: "pilot",
          displayNameKey: "Pilot",
          template: this.pilotTemplate,
          sortable: true,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  editUserClick(user: User) {
    this.router.navigate([user.userId], { relativeTo: this.route });
  }
}

<kt-datatable
  [loading]="loading"
  [data]="users"
  (loadData)="loadData.emit($event)"
  [columns]="columns"
  showSelect="true"
  [selectActionsTemplate]="selectActionsTemplate"
></kt-datatable>

<ng-template #roleTemplate let-row="row">
  {{ row.roleType?.name }}
</ng-template>

<ng-template #customerTemplate let-row="row">
  <a
    [routerLink]="['../../customers/' + row.customer.customerId]"
    *ngIf="row.customer"
    >{{ row.customer.name }}</a
  >
</ng-template>

<ng-template #pilotTemplate let-row="row">
  {{ row.pilot.name }}
</ng-template>

<ng-template #actionsTemplate let-row="row">
  <button
    mat-icon-button
    color="primary"
    matTooltip="Edit user"
    (click)="editUserClick(row)"
  >
    <mat-icon>create</mat-icon>
  </button>

  <button
    mat-icon-button
    color="warn"
    matTooltip="Delete user"
    type="button"
    (click)="deleteUser.emit(row)"
  >
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>

<ng-template #selectActionsTemplate>
  <button
    mat-raised-button
    matTooltip="Fetch selected users"
    class="mat-button-mt-4"
  >
    <mat-icon>clear_all</mat-icon>
    Fetch Selected
  </button>
</ng-template>

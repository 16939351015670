import { NgxPermissionsService } from "ngx-permissions";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import { EMPTY, of, defer } from "rxjs";

import * as AuthActions from "../actions/auth.actions";
import { AuthService } from "../../services/auth.service";
import { LayoutUtilsService } from "../../_base/crud";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { loadUserProfile } from "../actions/auth.actions";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      concatMap((action) =>
        this.authService.login(action.username, action.password).pipe(
          map((data) => {
            this.router.navigateByUrl("/dashboard");
            return AuthActions.loginSuccess({ data: data.authToken });
          }),
          catchError((error) => {
            let message = "error";

            if (error.status == 400 && error.error.Message == "login failed") {
              message = "Username or password incorrect.";
            } else {
              message = "Login failed, please try again later.";
            }

            return of(AuthActions.loginFailure({ error: message }));
          })
        )
      )
    );
  });

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((action) => {
          localStorage.setItem(environment.authTokenKey, action.data);
          this.store.dispatch(loadUserProfile());
        })
      ),
    { dispatch: false }
  );

  loadUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadUserProfile),
      concatMap((action) =>
        this.authService.getUserByToken().pipe(
          map((data) => AuthActions.loadUserProfileSuccess({ data })),
          catchError((error) =>
            of(AuthActions.loadUserProfileFailure({ error }))
          )
        )
      )
    );
  });

  loadUserProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loadUserProfileSuccess),
        tap((action) => {
          this.permissionService.loadPermissions([action.data.roleType.name]);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap((action) => {
          localStorage.clear();
          this.router.navigateByUrl("/auth/login");
        })
      ),
    { dispatch: false }
  );

  init$ = createEffect(() =>
    defer(() => {
      const userToken = localStorage.getItem(environment.authTokenKey);
      let observableResult = of({ type: "NO_ACTION" });
      if (userToken) {
        observableResult = of(AuthActions.loginSuccess({ data: userToken }));
        // this.store.dispatch(loadUserProfile());
      }
      return observableResult;
    })
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionService: NgxPermissionsService,
    private store: Store
  ) {}
}

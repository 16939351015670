import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromMaterial from "../reducers/material.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectMaterialState = createFeatureSelector<
  fromMaterial.MaterialState
>(fromMaterial.materialFeatureKey);

export const selectMaterialsSelector = createSelector(
  selectMaterialState,
  fromMaterial.selectAll
);

//export const selectMaterials = pipe(select(selectMaterialsSelector), skip(1));

export const selectMaterialsLoading = createSelector(
  selectMaterialState,
  (state) => state.loading
);

export const selectCurrentMaterial = createSelector(
  selectMaterialState,
  (state) => state.currentMaterial
);

import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Protocol } from "../models/protocol.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProtocolsService {
  constructor(private api: ApiService) {}

  getAllProtocols() {
    return this.api.get("protocols").pipe(
      map((result) => {
        const protocols = result.map((d) => Protocol.parse(d));

        return protocols;
      })
    );
  }

  getProtocolById(id) {
    return this.api.get("protocols/" + id).pipe(
      map((result) => {
        const protocol = Protocol.parse(result);

        return protocol;
      })
    );
  }

  addProtocol(protocol: Protocol) {
    return this.api.post("protocols", null, Protocol.parseBack(protocol)).pipe(
      map((result) => {
        return protocol;
      })
    );
  }

  updateProtocol(protocol: Protocol) {
    return this.api
      .put("protocols/" + protocol.protocolId, Protocol.parseBack(protocol))
      .pipe(
        map((result) => {
          return protocol;
        })
      );
  }

  deleteProtocol(protocol: Protocol) {
    return this.api.delete("protocols/" + protocol.protocolId).pipe(
      map((result) => {
        return protocol;
      })
    );
  }
}

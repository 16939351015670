import { TaskFilterWrapper } from "./../../../../../core/models/task-filter-wrapper.model";
import { selectPilotsSelector } from "./../../../../../core/store/selectors/drone-pilots.selectors";
import { selectMaterialsSelector } from "./../../../../../core/store/selectors/material.selectors";

import { Protocol } from "./../../../../../core/models/protocol.model";
import { Material } from "./../../../../../core/models/material.model";
import { DronePilot } from "./../../../../../core/models/drone-pilot.model";
import { MatSelect } from "@angular/material/select";
import { filterTasks } from "./../../../../../core/store/actions/task.actions";
import { Store, select } from "@ngrx/store";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  selectStatuses,
  selectTasksLoading,
  selectTaskFilterWrapper,
} from "src/app/core/store/selectors/task.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { selectCustomersSelector } from "src/app/core/store/selectors/customer.selectors";
import { Status } from "src/app/core/models/status.model";
import { Customer } from "src/app/core/models/customer.model";
import { FormControl } from "@angular/forms";
import { selectProtocolsSelector } from "src/app/core/store/selectors/protocol.selectors";

@Component({
  selector: "kt-tasks-filter",
  templateUrl: "./tasks-filter.component.html",
  styleUrls: ["./tasks-filter.component.scss"],
})
export class TasksFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  loading$;

  statuses: Status[];
  customers: Customer[];
  pilots: DronePilot[];
  materials: Material[];
  protocols: Protocol[];

  public customersSelectFormControl: FormControl = new FormControl();
  public pilotsSelectFormControl: FormControl = new FormControl();
  public protocolsSelectFormControl: FormControl = new FormControl();
  public materialsSelectFormControl: FormControl = new FormControl();

  @ViewChild("statusSelectControl") statusSelectControl: MatSelect;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksLoading));

    this.store
      .pipe(select(selectStatuses), untilDestroyed(this))
      .subscribe((s) => {
        this.statuses = s;
      });

    this.store
      .pipe(select(selectCustomersSelector), untilDestroyed(this))
      .subscribe((c) => {
        this.customers = c;
      });

    this.store
      .pipe(select(selectMaterialsSelector), untilDestroyed(this))
      .subscribe((m) => {
        this.materials = m;
      });

    this.store
      .pipe(select(selectProtocolsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.protocols = p;
      });

    this.store
      .pipe(select(selectPilotsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.pilots = p;
      });
  }

  ngAfterViewInit(): void {
    this.store
      .pipe(select(selectTaskFilterWrapper), untilDestroyed(this))
      .subscribe((wrapper) => {
        if (wrapper) {
          this.customersSelectFormControl.setValue(wrapper.customerId);
          this.pilotsSelectFormControl.setValue(wrapper.pilotId);
          this.statusSelectControl.value = wrapper.statusId;
          this.materialsSelectFormControl.setValue(wrapper.materialId);
          this.protocolsSelectFormControl.setValue(wrapper.protocolId);
        }
      });
  }

  filterClick() {
    let filterWrapper = this.prepareFilterWrapper();
    this.store.dispatch(filterTasks({ data: filterWrapper }));
  }

  prepareFilterWrapper(): TaskFilterWrapper {
    return {
      customerId: this.customersSelectFormControl.value,
      statusId: this.statusSelectControl.value,
      protocolId: this.protocolsSelectFormControl.value,
      materialId: this.materialsSelectFormControl.value,
      pilotId: this.pilotsSelectFormControl.value,
    };
  }

  ngOnDestroy(): void {}
}

<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
  <div
    class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
    id="kt_login"
  >
    <!--begin::Aside-->
    <div
      class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
      style="background-image: url(./assets/media//bg/bg-1.jpg)"
    >
      <!--begin: Aside Container-->
      <div class="d-flex flex-row-fluid flex-column justify-content-between">
        <!--begin: Aside header-->
        <a href="#" class="flex-column-auto mt-5">
          <img src="./assets/media/logos/AKSATA_TRANSPARENT.png" class="max-h-70px" />
        </a>
        <!--end: Aside header-->

        <!--begin: Aside content-->
        <div
          class="flex-column-fluid d-flex flex-column justify-content-center"
        >
          <h3 class="font-size-h1 mb-5 text-white"></h3>
          <!-- <p class="font-weight-lighter text-white opacity-80">
						The company has specialized in defining and building a ‘heavy’ drone configuration for agricultural and sanitary uses.
Agridrones provides its customers with a tailor-made solution, starts with a definition of the problem all the way to implementing an adequate solution.


					</p> -->
        </div>
        <!--end: Aside content-->

        <!--begin: Aside footer for desktop-->
        <div
          class="d-none flex-column-auto d-lg-flex justify-content-between mt-10"
        >
          <div class="opacity-70 font-weight-bold text-white">
            {{ "AUTH.GENERAL.TITLE" | translate }} {{ today | date: "yyyy" }}
          </div>
          <div class="d-flex">
            <a
              href="javascript:;"
              href="https://www.aksata360.com/contact-us"
              class="font-weight-bold ml-2"
              >{{ "AUTH.GENERAL.CONTACT" | translate }}</a
            >
          </div>
        </div>
        <!--end: Aside footer for desktop-->
      </div>
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div
      class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
    >
      <!--begin::Content header-->
      <div
        class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
      >
        <span class="font-weight-bold text-dark-50">
          Don't have an account yet?
        </span>
        <!-- <a
          href="javascript:;"
          routerLink="/auth/register"
          class="font-weight-bold ml-2"
          id="kt_login_signup"
          >{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a
        > -->

        <a
          href="javascript:;"
          href="https://www.aksata360.com/contact-us"
          class="font-weight-bold ml-2"
          id="kt_login_signup"
          >{{ "AUTH.GENERAL.CONTACT" | translate }}</a
        >
      </div>
      <!--end::Content header-->

      <router-outlet></router-outlet>
      <!--begin::Content footer for mobile-->
      <div
        class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5"
      >
        <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
          {{ "AUTH.GENERAL.TITLE" | translate }} {{ today | date: "yyyy" }}
        </div>
        <div class="d-flex order-1 order-sm-2 my-2">
          <a
            href="javascript:;"
            href="https://www.aksata360.com/contact-us"
            class="font-weight-bold ml-2"
            >{{ "AUTH.GENERAL.CONTACT" | translate }}</a
          >
        </div>
      </div>
      <!--end::Content footer for mobile-->
    </div>
    <!--end::Content-->
  </div>
</div>

import { MapTask } from "./../models/map-task.model";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Task } from "../models/task.model";

@Injectable({
  providedIn: "root",
})
export class PilotTasksService {
  constructor(private api: ApiService, private http: HttpClient) {}

  getPilotTasks(pilotId, date: Date) {
    //TODO: temp fix, parse all dates to ISO in prototype
    date = new Date(date.setTime(date.getTime() + 3 * 60 * 60 * 1000));
    return this.api
      .post("DailyTasks", null, { pilotId: pilotId.toString(), date: date })
      .pipe(
        map((result) => {
          const tasks = result.map((d) => Task.parse(d));
          const mapTasks = result.map((d) => MapTask.parse(d));
          return { tasks: tasks, mapTasks: mapTasks };
        })
      );
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as MaterialActions from "../actions/material.actions";
import { MaterialsService } from "../../services/materials.service";
import { LayoutUtilsService, MessageType } from "../../_base/crud";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable()
export class MaterialEffects {
  loadMaterials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialActions.loadMaterials),
      concatMap(() =>
        this.materialsService.getAllMaterials().pipe(
          map((data) => {
            return MaterialActions.loadMaterialsSuccess({ data });
          }),
          catchError((error) =>
            of(MaterialActions.loadMaterialsFailure({ error }))
          )
        )
      )
    );
  });

  loadMaterial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialActions.loadMaterial),
      concatMap((action) =>
        this.materialsService.getMaterialById(action.data).pipe(
          map((data) => {
            return MaterialActions.loadMaterialSuccess({ data });
          }),
          catchError((error) =>
            of(MaterialActions.loadMaterialFailure({ error }))
          )
        )
      )
    );
  });

  addMaterial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialActions.addMaterial),
      concatMap((action) =>
        this.materialsService.addMaterial(action.data).pipe(
          map((data) => {
            return MaterialActions.addMaterialSuccess({ data });
          }),
          catchError((error) =>
            of(MaterialActions.addMaterialFailure({ error }))
          )
        )
      )
    );
  });

  addMaterialSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.addMaterialSuccess),
        tap((action) => {
          const message = `Material successfully has been created.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );

          this.router.navigate(["materials"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  addMaterialFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.addMaterialFailure),
        tap((action) => {
          const message = `Material add failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  updateMaterial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialActions.updateMaterial),
      concatMap((action) =>
        this.materialsService.updateMaterial(action.data).pipe(
          map((data) => {
            return MaterialActions.updateMaterialSuccess({ data });
          }),
          catchError((error) =>
            of(MaterialActions.updateMaterialFailure({ error }))
          )
        )
      )
    );
  });

  updateMaterialSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.updateMaterialSuccess),
        tap((action) => {
          const message = `Material successfully has been saved.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );

          this.router.navigate(["materials"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  updateMaterialFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.updateMaterialFailure),
        tap((action) => {
          const message = `Material update failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  deleteMaterial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialActions.deleteMaterial),
      concatMap((action) =>
        this.materialsService.deleteMaterial(action.data).pipe(
          map((data) => {
            return MaterialActions.deleteMaterialSuccess({ data });
          }),
          catchError((error) =>
            of(MaterialActions.deleteMaterialFailure({ error }))
          )
        )
      )
    );
  });

  deleteMaterialSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.deleteMaterialSuccess),
        tap((action) => {
          const message = `Material successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private materialsService: MaterialsService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

<kt-mat-select-filter
  [dataSet]="data"
  [filterBy]="filterBy"
  [valueProperty]="valueProperty"
  [selectFormControl]="formControl"
  [error]="error"
  [hint]="hint"
  [placeHolder]="placeHolder"
  (valueChanged)="valueChanged.emit($event)"
  [primitiveValue]="false"
>
</kt-mat-select-filter>

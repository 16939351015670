import { loadDronePilots } from "./../../../core/store/actions/drone-pilots.actions";
import { loadCustomers } from "./../../../core/store/actions/customer.actions";
// Angular
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
// RxJS
import { Observable } from "rxjs";
// NGRX
import { Store, select } from "@ngrx/store";
// AppState
import { AppState } from "../../../core/reducers";
// Auth
import { Permission } from "../../../core/auth";
import { loadRoleTypes } from "src/app/core/store/actions/user.actions";

const userManagementPermissionId = 2;
@Component({
  selector: "kt-user-management",
  templateUrl: "./user-management.component.html",
})
export class UserManagementComponent implements OnInit {
  // Public properties
  // hasUserAccess$: Observable<boolean>;
  currentUserPermission$: Observable<Permission[]>;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   * @param router: Router
   */
  constructor(private store: Store<AppState>, private router: Router) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.store.dispatch(loadRoleTypes());
    //  this.store.dispatch(loadCustomers());
    //  this.store.dispatch(loadDronePilots());
  }
}

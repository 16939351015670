import { FieldsRoutingModule } from "./fields-routing.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldsListComponent } from "./fields-list/fields-list.component";
import { EditFieldComponent } from "./edit-field/edit-field.component";
import { FieldsTableComponent } from "./fields-list/fields-table/fields-table.component";
import { StoreModule } from "@ngrx/store";
import * as fromField from "../../../core/store/reducers/field.reducer";
import { EffectsModule } from "@ngrx/effects";
import { FieldEffects } from "../../../core/store/effects/field.effects";
import { FieldsComponent } from "./fields.component";
import { Routes, RouterModule } from "@angular/router";
import { MatCoreModule } from "src/app/core/utils/mat-core.module";
import { HttpClientModule } from "@angular/common/http";
import { PartialsModule } from "../../partials/partials.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CoordinatesListComponent } from "./edit-field/coordinates-list/coordinates-list.component";
import { ImportFieldsComponent } from "./import-fields/import-fields.component";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { FieldMapComponent } from "./field-map/field-map.component";
import { AgmCoreModule } from "@agm/core";

@NgModule({
  declarations: [
    FieldsComponent,
    FieldsListComponent,
    EditFieldComponent,
    FieldsTableComponent,
    CoordinatesListComponent,
    ImportFieldsComponent,
    FieldMapComponent,
  ],
  exports: [
    FieldsListComponent,
    EditFieldComponent,
    FieldsTableComponent,
    CoordinatesListComponent,
    ImportFieldsComponent,
  ],
  imports: [
    MatCoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    FieldsRoutingModule,
    StoreModule.forFeature(fromField.fieldFeatureKey, fromField.reducer),
    EffectsModule.forFeature([FieldEffects]),
    FileUploadModule,
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDMWp19lJGUMI_DoASc_mOCf7qFcwu81vA",
    }),
  ],
})
export class FieldsModule {}

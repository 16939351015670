import { importFields } from "./../../../../core/store/actions/field.actions";
import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Customer } from "src/app/core/models/customer.model";
import { Observable, ReplaySubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { FieldState } from "src/app/core/store/reducers/field.reducer";
import { selectFieldsLoading } from "src/app/core/store/selectors/field.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { selectCustomersSelector } from "src/app/core/store/selectors/customer.selectors";
import { FileUploadValidators } from "@iplab/ngx-file-upload";
import { FieldImportWrapper } from "src/app/core/models/field-import-wrapper.model";

@Component({
  selector: "kt-import-fields",
  templateUrl: "./import-fields.component.html",
  styleUrls: ["./import-fields.component.scss"],
})
export class ImportFieldsComponent implements OnInit, OnDestroy {
  fieldForm: FormGroup;
  hasFormErrors = false;
  customers: Customer[];
  loading$: Observable<boolean>;

  public customersSelectFormControl: FormControl = new FormControl();

  public filesControl = new FormControl(null, [Validators.required]);

  constructor(
    private route: ActivatedRoute,
    private store: Store<FieldState>,
    private fieldFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectFieldsLoading),
      untilDestroyed(this)
    );

    this.store
      .pipe(select(selectCustomersSelector), untilDestroyed(this))
      .subscribe((c) => {
        this.customers = c;
      });

    this.createForm();
  }

  createForm() {
    this.fieldForm = this.fieldFB.group({});

    this.fieldForm.addControl(
      "customersSelectFormControl",
      this.customersSelectFormControl
    );
    this.customersSelectFormControl.setValidators(Validators.required);

    this.fieldForm.addControl("fileUploadControl", this.filesControl);
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.fieldForm.controls;
    /** check form */
    if (this.fieldForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    let wrapper = {
      file: this.filesControl.value,
      customerId: this.customersSelectFormControl.value,
    };
    this.store.dispatch(importFields({ data: wrapper }));
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

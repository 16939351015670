<kt-generic-portlet [title]="title" [actionsTemplate]="actionsTemplate">
  <mat-slide-toggle #toggle (toggleChange)="isLoadedChanged(!toggle.checked)"
    >Show only fields that aren't fully loaded</mat-slide-toggle
  >

  <kt-fields-table
    [fields]="fields"
    [loading]="loading"
    (deleteField)="deleteField($event)"
  ></kt-fields-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a color="primary" mat-raised-button [routerLink]="['import']">
    <i class="fas fa-file-import import-button"></i> Import KML
  </a>

  <a color="primary" mat-raised-button [routerLink]="['add']">
    New Field
  </a>
</ng-template>

import { Coordinate } from "./coordinate.mode";
import { DroneRouteCoordinate } from "./drone-route-coordinate.model";
import { RouteChunk } from "./route-chunk-model";

export class DroneRoute {
  color: string;

  constructor(public routeChunks: RouteChunk[], public tailNumber: string) {
    this.color =
      "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
  }

  static parse(obj) {
    let routeChunks = obj.routeChunks.map((chunk) => ({
      coordinates: JSON.parse(chunk.coordinates).map(
        (c) => new Coordinate(+c.lat, +c.long)
      ),
      isOn: chunk.isOn,
    }));
    return new DroneRoute(routeChunks, obj.tailNumber);
  }
}

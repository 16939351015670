<kt-generic-portlet [title]="title" [loading]="loading$">
  <div class="row mb-6 card-border">
    <!-- FILTER -->
    <div class="col">
      <kt-completed-tasks-filter></kt-completed-tasks-filter>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!-- TABLE -->
      <kt-completed-tasks-table [tasks]="tasks"></kt-completed-tasks-table>
    </div>
  </div>
</kt-generic-portlet>

import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Drone } from "../models/drone.model";
import { DronePilot } from "../models/drone-pilot.model";
import { map } from "rxjs/operators";
import { PilotType } from "../models/pilot-type.model";
@Injectable({
  providedIn: "root",
})
export class DronePilotsService {
  constructor(private api: ApiService) {}
  getAllDronePilots() {
    return this.api.get("pilots").pipe(
      map((result) => {
        const drones = result.map((d) => DronePilot.parse(d));
        return drones;
      })
    );
  }

  getAllPilotTypes() {
    return this.api.get("pilottypes").pipe(
      map((result) => {
        const pilotTypes = result.map((p) => PilotType.parse(p));

        return pilotTypes;
      })
    );
  }

  getPilotById(id) {
    return this.api.get("pilots/" + id).pipe(
      map((result) => {
        const pilot = DronePilot.parse(result);
        return pilot;
      })
    );
  }

  addDronePilot(pilot: DronePilot) {
    return this.api.post("pilots", null, DronePilot.parseBack(pilot)).pipe(
      map((result) => {
        return pilot;
      })
    );
  }

  updatePilot(pilot: DronePilot) {
    return this.api
      .put("pilots/" + pilot.pilotId, DronePilot.parseBack(pilot))
      .pipe(
        map((result) => {
          return pilot;
        })
      );
  }

  deletePilot(pilot: DronePilot) {
    return this.api.delete("pilots/" + pilot.pilotId).pipe(
      map((result) => {
        return pilot;
      })
    );
  }
}

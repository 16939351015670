import { loadDronePayloads } from "./../../../core/store/actions/drones.actions";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { loadMaterials } from "src/app/core/store/actions/material.actions";

@Component({
  selector: "kt-protocols",
  templateUrl: "./protocols.component.html",
  styleUrls: ["./protocols.component.scss"],
})
export class ProtocolsComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(loadMaterials());
    this.store.dispatch(loadDronePayloads());
  }
}

<kt-portlet>
  <kt-portlet-header [title]="title">
    <div class="items-container" ktPortletTools>
      <ng-container [ngTemplateOutlet]="actionsTemplate"></ng-container>
    </div>
  </kt-portlet-header>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading | async"
  ></mat-progress-bar>
  <kt-portlet-body>
    <ng-content></ng-content>
  </kt-portlet-body>
</kt-portlet>

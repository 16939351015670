export class Customer {
  constructor(
    public customerId?: string,
    public name?: string,
    public email?: string,
    public phone?: string,
    public dateCreated?: string
  ) {}

  static createEmpty(): Customer {
    return new Customer(null, null, null, null, null);
  }
  static parseBack(customer: Customer): object {
    return {
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
    };
  }
}

import { Store } from "@ngrx/store";
import { Component, OnInit } from "@angular/core";
import { currentUser } from "src/app/core/store/selectors/auth.selectors";

@Component({
  selector: "kt-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  userId;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.select(currentUser).subscribe((user) => {
      if (user) {
        this.userId = user.userId;
      }
    });
  }
}

import { Action, createReducer, on } from "@ngrx/store";
import * as FieldActions from "../actions/field.actions";
import { Field } from "../../models/field.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

export const fieldFeatureKey = "field";

export interface FieldState extends EntityState<Field> {
  loading: boolean;
  currentField: Field;
}

export const adapter: EntityAdapter<Field> = createEntityAdapter<Field>({
  selectId: (field: Field) => field.fieldId,
});

export const initialState: FieldState = adapter.getInitialState({
  loading: false,
  currentField: null,
});

const fieldReducer = createReducer(
  initialState,

  on(FieldActions.loadFields, (state) => state),
  on(FieldActions.loadFieldsSuccess, (state, action) => {
    return { ...adapter.setAll(action.data, state), loading: false };
  }),
  on(FieldActions.loadFieldsFailure, (state, action) => state),

  on(FieldActions.addField, (state) => ({
    ...state,
    loading: true,
  })),
  on(FieldActions.addFieldSuccess, (state, action) => {
    return { ...adapter.addOne(action.data, state), loading: false };
  }),
  on(FieldActions.addFieldFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(FieldActions.updateField, (state) => ({
    ...state,
    loading: true,
  })),
  on(FieldActions.updateFieldSuccess, (state, action) => {
    return { ...adapter.updateOne(action.data, state), loading: false };
  }),
  on(FieldActions.updateFieldFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(FieldActions.deleteField, (state) => ({
    ...state,
    loading: true,
  })),
  on(FieldActions.deleteFieldSuccess, (state, action) => {
    return {
      ...adapter.removeOne(action.data.fieldId, state),
      loading: false,
    };
  }),
  on(FieldActions.addFieldFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(FieldActions.loadField, (state) => ({
    ...state,
    loading: true,
  })),
  on(FieldActions.loadFieldSuccess, (state, action) => {
    return { ...state, currentField: action.data, loading: false };
  }),
  on(FieldActions.loadFieldFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(FieldActions.importFields, (state) => ({
    ...state,
    loading: true,
  })),
  on(FieldActions.importFieldsSuccess, (state, action) => {
    return { ...state, loading: false };
  }),
  on(FieldActions.loadFieldFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: FieldState | undefined, action: Action) {
  return fieldReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as DronesActions from "../actions/drones.actions";
import { DronesService } from "../../services/drones.service";
import { props } from "@ngrx/store";
import { LayoutUtilsService, MessageType } from "../../_base/crud";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class DronesEffects {
  loadDrones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronesActions.loadDrones),
      concatMap(() =>
        this.dronesService.getAllDrones().pipe(
          map((data) => {
            return DronesActions.loadDronesSuccess({ data });
          }),
          catchError((error) => 
            of(DronesActions.loadDronesFailure({ error })
           ))
        )
      )
    );
  });

  loadDroneModels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronesActions.loadDroneModels),
      concatMap(() =>
        this.dronesService.getAllModels().pipe(
          map((data) => {
            return DronesActions.loadDroneModelsSuccess({ data });
          }),
          catchError((error) => of(DronesActions.loadDroneModelsFailure({ error })))
        )
      )
    );
  });

  loadDronePayloads$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronesActions.loadDronePayloads),
      concatMap(() =>
        this.dronesService.getAllPayloads().pipe(
          map((data) => {
            return DronesActions.loadDronePayloadsSuccess({ data });
          }),
          catchError((error) => of(DronesActions.loadDronePayloadsFailure({ error })))
        )
      )
    );
  });

  loadDrone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronesActions.loadDrone),
      concatMap((action) =>
        this.dronesService.getDroneById(action.data).pipe(
          map((data) => {
            return DronesActions.loadDroneSuccess({ data });
          }),
          catchError((error) => of(DronesActions.loadDroneFailure({ error })))
        )
      )
    );
  });

  addDrone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronesActions.addDrone),
      concatMap((action) =>
        this.dronesService.addDrone(action.data).pipe(
          map((data) => {
            return DronesActions.addDroneSuccess({ data });
          }),
          catchError((error) => of(DronesActions.addDroneFailure({ error })))
        )
      )
    );
  });

  addDroneSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronesActions.addDroneSuccess),
        tap((action) => {
          const message = `Drone successfully has been created.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );

          this.router.navigate(["drones"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  addDroneFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronesActions.addDroneFailure),
        tap((action) => {
          const message = `Drone add failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
            console.log(action.error)
        })
      ),
    { dispatch: false }
  );

  updateDrone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronesActions.updateDrone),
      concatMap((action) =>
        this.dronesService.updateDrone(action.data).pipe(
          map((data) => {
            return DronesActions.updateDroneSuccess({ data });
          }),
          catchError((error) => of(DronesActions.updateDroneFailure({ error })))
        )
      )
    );
  });

  updateDroneSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronesActions.updateDroneSuccess),
        tap((action) => {
          const message = `Drone successfully has been saved.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );

          this.router.navigate(["drones"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  updateDroneFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronesActions.updateDroneFailure),
        tap((action) => {
          const message = `Drone update failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
            console.log(action.error)
        })
      ),
    { dispatch: false }
  );

  deleteDrone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DronesActions.deleteDrone),
      concatMap((action) =>
        this.dronesService.deleteDrone(action.data).pipe(
          map((data) => {
            return DronesActions.deleteDroneSuccess({ data });
          }),
          catchError((error) => of(DronesActions.deleteDroneFailure({ error })))
        )
      )
    );
  });

  deleteDroneSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DronesActions.deleteDroneSuccess),
        tap((action) => {
          const message = `Drone successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private dronesService: DronesService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

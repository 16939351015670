<kt-generic-portlet
  [title]="'Drone Pilots'"
  [actionsTemplate]="actionsTemplate"
>
  <kt-drone-pilots-table
    [pilots]="pilots"
    [loading]="loading"
    (deletePilot)="deletePilot($event)"
  ></kt-drone-pilots-table>
</kt-generic-portlet>

<ng-template #actionsTemplate>
  <a color="primary" mat-raised-button [routerLink]="['add']">
    New Drone Pilot
  </a>
</ng-template>

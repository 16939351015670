import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Field } from "src/app/core/models/field.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-fields-table",
  templateUrl: "./fields-table.component.html",
  styleUrls: ["./fields-table.component.scss"],
})
export class FieldsTableComponent implements OnInit, AfterViewInit {
  @Input() fields: Field[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deleteField = new EventEmitter<Field>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild("customerTemplate", { static: false })
  customerTemplate: TemplateRef<any>;

  @ViewChild("isLoadedTemplate", { static: false })
  isLoadedTemplate: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "id",
          clientFieldName: "id",
          displayNameKey: "",
          sortable: false,
          template: this.isLoadedTemplate,
        },
        {
          serverFieldName: "plotNumber",
          clientFieldName: "plotNumber",
          displayNameKey: "Plot Number",
          sortable: true,
        },
        {
          serverFieldName: "cropName",
          clientFieldName: "cropName",
          displayNameKey: "Crop Name",
          sortable: true,
        },
        {
          serverFieldName: "plantingYear",
          clientFieldName: "plantingYear",
          displayNameKey: "PlantingYear",
          sortable: true,
        },
        {
          serverFieldName: "customer",
          clientFieldName: "customer",
          displayNameKey: "Customer",
          sortable: true,
          template: this.customerTemplate,
        },
        {
          serverFieldName: "moagId",
          clientFieldName: "moagId",
          displayNameKey: "MOAG Id number",
          sortable: true,
        },
        {
          serverFieldName: "areaSquareMeters",
          clientFieldName: "areaSquareMeters",
          displayNameKey: "Area(Square meters)",
          sortable: true,
        },
        {
          clientFieldName: "actions",
          serverFieldName: "actions",
          displayNameKey: "Actions",
          sortable: false,
          template: this.actionsTemplate,
        },
      ];
    });
  }

  editFieldClick(field: Field) {
    this.router.navigate(["edit/" + field.fieldId], { relativeTo: this.route });
  }

  goToFieldTasks(field: Field) {
    this.router.navigateByUrl("tasks/" + field.fieldId);
  }
}

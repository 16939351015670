import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Customer } from "src/app/core/models/customer.model";
import { DronePilot } from "src/app/core/models/drone-pilot.model";
import { Store, select } from "@ngrx/store";
import { selectCustomersSelector } from "src/app/core/store/selectors/customer.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { selectPilotsSelector } from "src/app/core/store/selectors/drone-pilots.selectors";
import {
  filterTasks,
  filterCompletedTasks,
} from "src/app/core/store/actions/task.actions";
import { TaskFilterWrapper } from "src/app/core/models/task-filter-wrapper.model";
import { FormControl } from "@angular/forms";
import { selectCompletedTasksFilterWrapper } from "src/app/core/store/selectors/task.selectors";

@Component({
  selector: "kt-completed-tasks-filter",
  templateUrl: "./completed-tasks-filter.component.html",
  styleUrls: ["./completed-tasks-filter.component.scss"],
})
export class CompletedTasksFilterComponent
  implements OnInit, OnDestroy, AfterViewInit {
  loading$;

  customers: Customer[];
  pilots: DronePilot[];

  public customersSelectFormControl: FormControl = new FormControl();
  public pilotsSelectFormControl: FormControl = new FormControl();

  @ViewChild("fromDateControl") fromDateControl;
  @ViewChild("toDateControl") toDateControl;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectCustomersSelector), untilDestroyed(this))
      .subscribe((c) => {
        this.customers = c;
      });

    this.store
      .pipe(select(selectPilotsSelector), untilDestroyed(this))
      .subscribe((p) => {
        this.pilots = p;
      });
  }

  ngAfterViewInit(): void {
    this.store
      .pipe(select(selectCompletedTasksFilterWrapper), untilDestroyed(this))
      .subscribe((wrapper) => {
        if (wrapper) {
          this.customersSelectFormControl.setValue(wrapper.customerId);
          this.pilotsSelectFormControl.setValue(wrapper.pilotId);
          this.fromDateControl.nativeElement.value = wrapper.fromDate;
          this.toDateControl.nativeElement.value = wrapper.toDate;
        }
      });
  }

  filterClick() {
    let filterWrapper = this.prepareFilterWrapper();
    this.store.dispatch(filterCompletedTasks({ data: filterWrapper }));
  }

  prepareFilterWrapper(): TaskFilterWrapper {
    return {
      customerId: this.customersSelectFormControl.value,
      toDate: this.toDateControl.nativeElement.value,
      fromDate: this.fromDateControl.nativeElement.value,
      pilotId: this.pilotsSelectFormControl.value,
    };
  }
  ngOnDestroy(): void {}
}

// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpClient, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { tap, catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/core/auth';
import { logout } from 'src/app/core/store/actions/auth.actions';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
    private store: Store,
    private http: HttpClient,
    private auth: AuthService
) {}

isRefreshingToken: boolean = false;
tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

addToken(req: HttpRequest<any>): HttpRequest<any> {
    //token = "Bearer " + token;
    let token = this.auth.getToken()
    return token ? req.clone({
        setHeaders: {
            Authorization: "Bearer " + token
        }
    }) : req;
}

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {   
    return next.handle(this.addToken(req)).pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse) {
                switch ((<HttpErrorResponse>error).status) {
                    case 400:
                        return this.handle400Error(error);
                    case 401:
                        console.log("401 error:", error);
                        return this.handle401Error(req, next, error);
                    default:
                        return throwError(error);
                }
            } else {
                return throwError(error);
            }
        })
    );
}

handle400Error(error) {
    if (
        error &&
        error.status === 400
    ) {
        // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
        return this.logoutUser(error);
    }

    return throwError(error);
}

handle401Error(req: HttpRequest<any>, next: HttpHandler, error) {

  if (error &&
    error.status === 401) {
      return this.logoutUser(error);
    }


  return throwError(error);
    // if (!this.isRefreshingToken) {
    //     this.isRefreshingToken = true;

    //     // Reset here so that the following requests wait until the token
    //     // comes back from the refreshToken call.
    //     this.tokenSubject.next(null);

    //     return this.auth.refreshToken().pipe(
    //         switchMap(newToken => {
    //             localStorage.setItem("authToken", newToken.token);
    //             const token = localStorage.getItem("authToken");
    //             if (newToken) {
    //                 this.tokenSubject.next(newToken);
    //                 return next.handle(this.addToken(req, token));
    //             }

    //             return this.logoutUser(error);
    //         }),
    //         catchError(error => {
    //             return this.logoutUser(error);
    //         }),
    //         finalize(() => {
    //             this.isRefreshingToken = false;
    //         })
    //     );
    // } else {
    //     return this.tokenSubject.pipe(
    //         filter(token => token != null),
    //         take(1),
    //         switchMap(token => {
    //             return next.handle(this.addToken(req, token));
    //         })
    //     );
    // }
}

logoutUser(error) {
    console.log("logout user");
    this.store.dispatch(logout());
    return throwError(error);
}
}

<kt-datatable
  [loading]="loading"
  [data]="fields"
  (loadData)="loadData.emit($event)"
  [columns]="columns"
  showSelect="true"
  [selectActionsTemplate]="selectActionsTemplate"
></kt-datatable>

<ng-template #actionsTemplate let-row="row">
  <button
    mat-icon-button
    color="primary"
    matTooltip="Edit field"
    (click)="editFieldClick(row)"
  >
    <mat-icon>create</mat-icon>
  </button>

  <button
    mat-stroked-button
    color="primary"
    matTooltip="View field's tasks"
    (click)="goToFieldTasks(row)"
  >
    Tasks
  </button>

  <button
    mat-icon-button
    color="warn"
    matTooltip="Delete field"
    type="button"
    (click)="deleteField.emit(row)"
  >
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>

<ng-template #customerTemplate let-row="row">
  <a
    [routerLink]="['../customers/' + row.customer.customerId]"
    *ngIf="row.customer"
    >{{ row.customer.name }}</a
  >
</ng-template>

<ng-template #isLoadedTemplate let-row="row">
  <div
    matTooltip="Some data is missing on this field"
    *ngIf="!row.isFullyLoaded"
  >
    <mat-icon class="fas fa-tasks" color="warn"></mat-icon>
  </div>
</ng-template>

<ng-template #selectActionsTemplate>
  <button
    mat-raised-button
    matTooltip="Fetch selected users"
    class="mat-button-mt-4"
  >
    <mat-icon>clear_all</mat-icon>
    Fetch Selected
  </button>
</ng-template>

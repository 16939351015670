import { EntitySelectService } from "./entity-select.service";
import { FormControl } from "@angular/forms";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "kt-entity-select",
  templateUrl: "./entity-select.component.html",
  styleUrls: ["./entity-select.component.scss"],
})
export class EntitySelectComponent implements OnInit {
  // Entity name, used to access api
  // *REQUIRED*
  @Input() entityName;

  // The dataset to display
  // *REQUIRED*
  @Input() data;

  // The property to filter by, usually the display property
  // *REQUIRED*
  @Input() filterBy: string;

  // The id property
  // *REQUIRED*
  @Input() valueProperty = "id";

  // To support being part of a form
  @Input() formControl: FormControl = new FormControl();

  // Error string if form is invalid
  @Input() error: string;

  @Input() hint = "Select a entity";
  @Input() placeHolder = "Enter a value";

  @Output() valueChanged = new EventEmitter();

  constructor(private service: EntitySelectService) {}

  ngOnInit(): void {
    if (!this.entityName) {
      throw "Must supply entityName to EntitySelectComponent";
    }

    this.service.getEntity(this.entityName).subscribe((x) => {
      this.data = x;
    });
  }
}

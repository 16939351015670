import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { DronePilotsState } from "src/app/core/store/reducers/drone-pilots.reducer";
import { ActivatedRoute } from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { PilotType } from "src/app/core/models/pilot-type.model";
import { DronePilot } from "src/app/core/models/drone-pilot.model";
import {
  updateDronePilot,
  addDronePilot,
  loadDronePilot,
  loadPilotTypes,
} from "src/app/core/store/actions/drone-pilots.actions";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  selectPilotsLoading,
  selectPilotTypes,
  selectCurrentPilot,
} from "src/app/core/store/selectors/drone-pilots.selectors";
import * as _ from "lodash";
import { RegEx } from "src/app/core/_base/crud/utils/regex-utils";

@Component({
  selector: "kt-edit-drone-pilot",
  templateUrl: "./edit-drone-pilot.component.html",
  styleUrls: ["./edit-drone-pilot.component.scss"],
})
export class EditDronePilotComponent implements OnInit, OnDestroy {
  title = "Add Drone Pilot";
  isEdit = false;
  pilot: DronePilot;
  pilotForm: FormGroup;
  hasFormErrors = false;

  pilotTypes: PilotType[];

  loading$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<DronePilotsState>,
    private pilotFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectPilotsLoading),
      untilDestroyed(this)
    );

    this.pilot = DronePilot.createEmpty();

    combineLatest(
      this.store.pipe(select(selectPilotTypes), untilDestroyed(this)),
      this.store.pipe(select(selectCurrentPilot), untilDestroyed(this))
    ).subscribe(([pilotTypes, currentPilot]) => {
      this.pilotTypes = pilotTypes;

      if (currentPilot && this.isEdit) {
        this.pilot = _.cloneDeep(currentPilot);
      }

      this.createForm();
    });

    let pilotId = this.route.snapshot.params["id"];

    if (pilotId && pilotId != "add") {
      this.isEdit = true;
      this.title = "Edit Drone Pilot";

      this.store.dispatch(loadDronePilot({ data: pilotId }));
    }
  }

  createForm() {
    this.pilotForm = this.pilotFB.group({
      name: [this.pilot.name, Validators.required],
      pilotType: [this.pilot.pilotType?.pilotTypeId, Validators.required],
      phone: [
        this.pilot.phone,
        [Validators.required, Validators.pattern(new RegExp(RegEx.phone))],
      ],
      isLicenseGimel: [this.pilot.isLicenseGimel],
      isLicenseRT: [this.pilot.isLicenseRT],
    });
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.pilotForm.controls;
    /** check form */
    if (this.pilotForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.preparePilot();

    if (this.isEdit) {
      console.log("updating pilot with value:", this.pilot);
      this.store.dispatch(updateDronePilot({ data: this.pilot }));
    } else {
      console.log("adding drone with value:", this.pilot);
      this.store.dispatch(addDronePilot({ data: this.pilot }));
    }
  }

  preparePilot() {
    this.pilot.name = this.pilotForm.controls.name.value;
    this.pilot.pilotType = this.pilotTypes.find(
      (p) => p.pilotTypeId == this.pilotForm.controls.pilotType.value
    );
    this.pilot.phone = this.pilotForm.controls.phone.value;
    this.pilot.isLicenseGimel = this.pilotForm.controls.isLicenseGimel.value;
    this.pilot.isLicenseRT = this.pilotForm.controls.isLicenseRT.value;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

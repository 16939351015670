import { RoleType } from "./../../auth/_models/role-type.model";
import { Action, createReducer, on } from "@ngrx/store";
import * as UserActions from "../actions/user.actions";
import { User } from "../../auth/_models/user.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

export const userFeatureKey = "user";

export interface UserState extends EntityState<User> {
  loading: boolean;
  currentUser: User;
  roleTypes: RoleType[];
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (user: User) => user.userId,
});

export const initialState: UserState = adapter.getInitialState({
  loading: false,
  currentUser: null,
  roleTypes: null,
});

const userReducer = createReducer(
  initialState,

  on(UserActions.loadUsers, (state) => state),
  on(UserActions.loadUsersSuccess, (state, action) => {
    return { ...adapter.setAll(action.data, state), loading: false };
  }),
  on(UserActions.loadUsersFailure, (state, action) => state),

  on(UserActions.loadRoleTypesSuccess, (state, action) => {
    return { ...state, roleTypes: action.data };
  }),

  on(UserActions.addUser, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.addUserSuccess, (state, action) => {
    return { ...adapter.addOne(action.data, state), loading: false };
  }),
  on(UserActions.addUserFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(UserActions.updateUser, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.updateUserSuccess, (state, action) => {
    return { ...adapter.updateOne(action.data, state), loading: false };
  }),
  on(UserActions.updateUserFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(UserActions.deleteUser, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.deleteUserSuccess, (state, action) => {
    return {
      ...adapter.removeOne(action.data.userId, state),
      loading: false,
    };
  }),
  on(UserActions.addUserFailure, (state, action) => ({
    ...state,
    loading: false,
  })),

  on(UserActions.loadUser, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.loadUserSuccess, (state, action) => {
    return { ...state, currentUser: action.data, loading: false };
  }),
  on(UserActions.loadUserFailure, (state, action) => ({
    ...state,
    loading: false,
  }))
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

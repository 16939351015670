import { selectPayloadsSelector } from "./../../../../core/store/selectors/drones.selectors";
import { DronePayload } from "./../../../../core/models/drone-payload.model";
import { selectMaterialsSelector } from "./../../../../core/store/selectors/material.selectors";
import { Material } from "src/app/core/models/material.model";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Protocol } from "src/app/core/models/protocol.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Observable, combineLatest, ReplaySubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import {
  selectProtocolsLoading,
  selectCurrentProtocol,
} from "src/app/core/store/selectors/protocol.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { cloneDeep } from "lodash";
import {
  loadProtocol,
  updateProtocol,
  addProtocol,
} from "src/app/core/store/actions/protocol.actions";
import { ProtocolState } from "src/app/core/store/reducers/protocol.reducer";
import { loadMaterials } from "src/app/core/store/actions/material.actions";

@Component({
  selector: "kt-edit-protocol",
  templateUrl: "./edit-protocol.component.html",
  styleUrls: ["./edit-protocol.component.scss"],
})
export class EditProtocolComponent implements OnInit, OnDestroy {
  title = "Add Protocol";
  isEdit = false;
  protocol: Protocol;
  protocolForm: FormGroup;
  hasFormErrors = false;
  materials: Material[];
  payloads: DronePayload[];
  loading$: Observable<boolean>;

  public materialSelectControl: FormControl = new FormControl();
  public payloadSelectControl: FormControl = new FormControl();

  constructor(
    private route: ActivatedRoute,
    private store: Store<ProtocolState>,
    private protocolFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectProtocolsLoading),
      untilDestroyed(this)
    );

    this.protocol = Protocol.createEmpty();
    this.createForm();

    combineLatest(
      this.store.pipe(select(selectMaterialsSelector), untilDestroyed(this)),
      this.store.pipe(select(selectCurrentProtocol), untilDestroyed(this)),
      this.store.pipe(select(selectPayloadsSelector), untilDestroyed(this))
    ).subscribe(([materials, currentProtocol, payloads]) => {
      this.materials = materials;
      this.payloads = payloads;

      if (currentProtocol && this.isEdit) {
        this.protocol = cloneDeep(currentProtocol);
        this.createForm();
      }
    });

    let protocolId = this.route.snapshot.params["id"];

    if (protocolId && protocolId != "add") {
      this.isEdit = true;
      this.title = "Edit Protocol";

      this.store.dispatch(loadProtocol({ data: protocolId }));
    }
  }

  createForm() {
    this.protocolForm = this.protocolFB.group({
      name: [this.protocol.name, Validators.required],
      marginSpace: [this.protocol.marginSpace, Validators.required],
      rowSpace: [this.protocol.rowSpace, Validators.required],
      dunamVolume: [this.protocol.dunamVolume, Validators.required],
      waterVolume: [this.protocol.waterVolume, Validators.required],
      nozzle: [this.protocol.nozzle, Validators.required],
    });

    this.protocolForm.addControl(
      "materialsSelectControl",
      this.materialSelectControl
    );
    this.protocolForm.controls.materialsSelectControl.setValidators(
      Validators.required
    );
    if (this.protocol.material) {
      this.materialSelectControl.setValue(this.protocol.material.materialId);
    }

    this.protocolForm.addControl(
      "payloadSelectControl",
      this.payloadSelectControl
    );
    this.protocolForm.controls.payloadSelectControl.setValidators(
      Validators.required
    );
    if (this.protocol.payload) {
      this.payloadSelectControl.setValue(this.protocol.payload.payloadId);
    }
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.protocolForm.controls;
    /** check form */
    if (this.protocolForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.prepareProtocol();

    if (this.isEdit) {
      this.store.dispatch(updateProtocol({ data: this.protocol }));
    } else {
      this.store.dispatch(addProtocol({ data: this.protocol }));
    }
  }

  prepareProtocol() {
    this.protocol.name = this.protocolForm.controls.name.value;
    this.protocol.material = this.materials.find(
      (m) => m.materialId == this.materialSelectControl.value
    );
    this.protocol.payload = this.payloads.find(
      (p) => p.payloadId == this.payloadSelectControl.value
    );
    this.protocol.marginSpace = this.protocolForm.controls.marginSpace.value;
    this.protocol.rowSpace = this.protocolForm.controls.rowSpace.value;
    this.protocol.dunamVolume = this.protocolForm.controls.dunamVolume.value;
    this.protocol.waterVolume = this.protocolForm.controls.waterVolume.value;
    this.protocol.nozzle = this.protocolForm.controls.nozzle.value;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

<div class="row mb-5">
  <!-- Customer -->
  <div class="col-3 kt-margin-bottom-20-mobile">
    <kt-mat-select-filter
      [dataSet]="customers"
      [filterBy]="'name'"
      [valueProperty]="'customerId'"
      [selectFormControl]="customersSelectFormControl"
      [hint]="'Choose a customer to filter by'"
      [placeHolder]="'Select a customer'"
    >
    </kt-mat-select-filter>
  </div>

  <!-- Pilot -->
  <div class="col-3 kt-margin-bottom-20-mobile">
    <kt-mat-select-filter
      [dataSet]="pilots"
      [filterBy]="'name'"
      [valueProperty]="'pilotId'"
      [selectFormControl]="pilotsSelectFormControl"
      [hint]="'Choose a pilot to filter by'"
      [placeHolder]="'Select a pilot'"
    >
    </kt-mat-select-filter>
  </div>

  <!-- From Date -->
  <div class="col-3">
    <div class="row">
      <div class="col-lg-10">
        <mat-form-field class="mat-form-field-fluid">
          <input
            #fromDateControl
            matInput
            [matDatepicker]="fromPicker"
            placeholder="Filter tasks from date"
          />
          <mat-datepicker #fromPicker></mat-datepicker>
          <mat-hint align="start"
            >Please enter
            <strong>from date</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-datepicker-toggle
          matSuffix
          [for]="fromPicker"
        ></mat-datepicker-toggle>
      </div>
    </div>
  </div>

  <!-- To Date -->
  <div class="col-3">
    <div class="row">
      <div class="col-lg-10">
        <mat-form-field class="mat-form-field-fluid">
          <input
            #toDateControl
            matInput
            [matDatepicker]="toDatePicker"
            placeholder="To date"
          />
          <mat-datepicker #toDatePicker></mat-datepicker>
          <mat-hint align="start"
            >Please enter
            <strong>to date</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-datepicker-toggle
          matSuffix
          [for]="toDatePicker"
        ></mat-datepicker-toggle>
      </div>
    </div>
  </div>
</div>

<div class="row no-gutters">
  <div class="col d-flex justify-content-end">
    <button
      mat-raised-button
      color="primary"
      (click)="filterClick()"
      [disabled]="loading$ | async"
    >
      Filter
    </button>
  </div>
</div>

import {
  selectDroneRoutes,
  selectControllerMapTasks,
  selectSelectedControllerMapTasks,
} from "./../../../../core/store/selectors/task.selectors";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { MapTask } from "src/app/core/models/map-task.model";
import { Store, select } from "@ngrx/store";
import {
  selectTasksLoading,
  selectMapTasks,
  selectSelectedMapTasks,
} from "src/app/core/store/selectors/task.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import { cloneDeep } from "lodash";
import { DroneRoute } from "src/app/core/models/drone-route-model";

@Component({
  selector: "kt-task-controller",
  templateUrl: "./task-controller.component.html",
  styleUrls: ["./task-controller.component.scss"],
})
export class TaskControllerComponent implements OnInit, OnDestroy {
  loading$;
  mapTasks: MapTask[];
  selectedMapTasks: MapTask[];
  droneRoutes: DroneRoute[];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(select(selectTasksLoading));

    this.store
      .pipe(select(selectControllerMapTasks), untilDestroyed(this))
      .subscribe((mapTasks) => {
        this.mapTasks = cloneDeep(mapTasks);
      });

    this.store
      .pipe(select(selectSelectedControllerMapTasks), untilDestroyed(this))
      .subscribe((selectedMapTasks) => {
        this.selectedMapTasks = cloneDeep(selectedMapTasks);
      });

    this.store
      .pipe(select(selectDroneRoutes), untilDestroyed(this))
      .subscribe((droneRoutes) => {
        if (droneRoutes) {
          this.droneRoutes = droneRoutes;
        }
      });
  }

  ngOnDestroy(): void {}
}

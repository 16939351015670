import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DronesComponent } from "./drones.component";
import { RouterModule, Routes } from "@angular/router";
import { PartialsModule } from "../../partials/partials.module";
import { HttpClientModule } from "@angular/common/http";
import { DronesListComponent } from "./drones-list/drones-list.component";
import { StoreModule } from "@ngrx/store";
import * as fromDrones from "../../../core/store/reducers/drones.reducer";
import { EffectsModule } from "@ngrx/effects";
import { DronesEffects } from "../../../core/store/effects/drones.effects";
import { DronesTableComponent } from "./drones-list/drones-table/drones-table.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCoreModule } from "src/app/core/utils/mat-core.module";
import { EditDroneComponent } from "./edit-drone/edit-drone.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ModuleGuard } from "src/app/core/auth";

const routes: Routes = [
  {
    path: "",
    component: DronesComponent,
    canActivate: [ModuleGuard],
    data: {
      permissions: {
        only: "Admin",
        redirectTo: "dashboard",
      },
    },
    children: [
      {
        path: "",
        pathMatch: "full",
        component: DronesListComponent,
      },
      {
        path: ":id",
        component: EditDroneComponent,
      },
      {
        path: "add",
        component: EditDroneComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    DronesComponent,
    DronesListComponent,
    DronesTableComponent,
    EditDroneComponent,
  ],
  imports: [
    MatCoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(fromDrones.dronesFeatureKey, fromDrones.reducer),
    EffectsModule.forFeature([DronesEffects]),
  ],
})
export class DronesModule {}

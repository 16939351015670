import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, concatMap, tap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";

import * as CustomerActions from "../actions/customer.actions";
import { CustomersService } from "../../services/customers.service";
import { MessageType, LayoutUtilsService } from "../../_base/crud";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable()
export class CustomerEffects {
  loadCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.loadCustomers),
      concatMap(() =>
        this.customersService.getAllCustomers().pipe(
          map((data) => {
            return CustomerActions.loadCustomersSuccess({ data });
          }),
          catchError((error) =>
            of(CustomerActions.loadCustomersFailure({ error }))
          )
        )
      )
    );
  });

  loadCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.loadCustomer),
      concatMap((action) =>
        this.customersService.getCustomerById(action.data).pipe(
          map((data) => {
            return CustomerActions.loadCustomerSuccess({ data });
          }),
          catchError((error) =>
            of(CustomerActions.loadCustomerFailure({ error }))
          )
        )
      )
    );
  });

  addCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.addCustomer),
      concatMap((action) =>
        this.customersService.addCustomer(action.data).pipe(
          map((data) => {
            return CustomerActions.addCustomerSuccess({ data });
          }),
          catchError((error) =>
            of(CustomerActions.addCustomerFailure({ error }))
          )
        )
      )
    );
  });

  addCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerActions.addCustomerSuccess),
        tap((action) => {
          const message = `Customer successfully has been created.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            false
          );

          this.router.navigate(["customers"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  addCustomerFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerActions.addCustomerFailure),
        tap((action) => {
          const message = `Customer add failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  updateCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.updateCustomer),
      concatMap((action) =>
        this.customersService.updateCustomer(action.data).pipe(
          map((data) => {
            return CustomerActions.updateCustomerSuccess({ data });
          }),
          catchError((error) =>
            of(CustomerActions.updateCustomerFailure({ error }))
          )
        )
      )
    );
  });

  updateCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerActions.updateCustomerSuccess),
        tap((action) => {
          const message = `Customer successfully has been saved.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );

          this.router.navigate(["customers"], { relativeTo: this.route });
        })
      ),
    { dispatch: false }
  );

  updateCustomerFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerActions.updateCustomerFailure),
        tap((action) => {
          const message = `Customer update failed. Try again later.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Read,
            5000,
            true,
            false
          );
          console.log(action.error);
        })
      ),
    { dispatch: false }
  );

  deleteCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.deleteCustomer),
      concatMap((action) =>
        this.customersService.deleteCustomer(action.data).pipe(
          map((data) => {
            return CustomerActions.deleteCustomerSuccess({ data });
          }),
          catchError((error) =>
            of(CustomerActions.deleteCustomerFailure({ error }))
          )
        )
      )
    );
  });

  deleteCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerActions.deleteCustomerSuccess),
        tap((action) => {
          const message = `Customer successfully has been deleted.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Delete,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private customersService: CustomersService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

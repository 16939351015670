<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Drone routes
    </mat-panel-title>
    <mat-panel-description>
      {{ droneRoutes?.length }} routes filtered
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div
    class="panel-content"
    [perfectScrollbar]="{ wheelPropagation: false }"
    [ngStyle]="{ 'max-height': '19vh', position: 'relative' }"
  >
    <mat-selection-list [multiple]="false">
      <mat-list-option
        *ngFor="let route of droneRoutes"
        [value]="route"
        (click)="routeSelected.emit(route)"
      >
        <div class="row">
          <div class="col-auto d-flex">
            <div
              class="align-self-center"
              [ngStyle]="{
                'background-color': route.color,
                height: '10px',
                width: '10px'
              }"
            ></div>
          </div>

          <div class="col-auto">
            {{ route.tailNumber }}
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-expansion-panel>

import { Actions, ofType } from "@ngrx/effects";

import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { untilDestroyed } from "ngx-take-until-destroy";
import { Coordinate } from "src/app/core/models/coordinate.mode";
import { InfoWindow } from "@agm/core/services/google-maps-types";
import { AgmMap, LatLngLiteral, FitBoundsAccessor } from "@agm/core";
import {
  mapTaskSelected,
  mapTaskTableClick,
} from "src/app/core/store/actions/task.actions";
import { MapTask } from "src/app/core/models/map-task.model";

declare const google: any;

@Component({
  selector: "kt-task-fields-map",
  templateUrl: "./task-fields-map.component.html",
  styleUrls: ["./task-fields-map.component.scss"],
})
export class TaskFieldsMapComponent implements OnInit, OnChanges, OnDestroy {
  zoom = 17;
  bounds = {
    north: 33.290422,
    south: 29.49388,
    west: 34.219153,
    east: 35.579897,
  };
  @Input() coordinates: Coordinate[];
  @Input() center: Coordinate;
  @Input() mapTasks: MapTask[];

  @ViewChild("map") map: AgmMap;
  drawingManager;
  selectedShape;
  infoWindow;
  selectedMapTask: MapTask;

  constructor(private store: Store, private actions$: Actions) {}

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (
      changes.mapTasks &&
      this.mapTasks?.length > 0 &&
      changes.mapTasks.currentValue?.length !=
        changes.mapTasks.previousValue?.length
    ) {
      this.center = this.mapTasks[0].task.field.coordinates[0];
      this.fitBounds();
    }
  }

  fitBounds() {
    let allCoords = this.mapTasks.reduce(
      (acc, val) => acc.concat(val.task.field.coordinates),
      []
    );

    let lats = allCoords.map((c) => c.lat);
    let lngs = allCoords.map((c) => c.lng);

    let maxLat = Math.max(...lats);
    let minLat = Math.min(...lats);
    let maxLng = Math.max(...lngs);
    let minLng = Math.min(...lngs);

    this.bounds = {
      north: maxLat,
      south: minLat,
      east: maxLng,
      west: minLng,
    };
  }

  ngOnInit(): void {
    this.actions$
      .pipe(ofType(mapTaskTableClick), untilDestroyed(this))
      .subscribe((action) => {
        this.center = null;
        setTimeout(
          () => (this.center = action.data.task.field.coordinates[0]),
          0
        );

        this.zoom = 17;
      });
  }

  mouseOverPoly(infoWindow: InfoWindow, mapTask: MapTask) {
    this.selectedMapTask = mapTask;
    infoWindow.open();
  }

  mapTaskSelected(mapTask: MapTask) {
    this.store.dispatch(
      mapTaskSelected({ mapTask: mapTask, selected: !mapTask.isSelected })
    );
  }

  selectTasksInRect(select: boolean) {
    if (this.selectedShape) {
      const northEeast = <any>Object.values(Object.values(this.selectedShape.bounds)[1]);
      const southWest = <any>Object.values(Object.values(this.selectedShape.bounds)[0]);

      const swLat = southWest[1];
      const swLng = southWest[0];
      const neLat = northEeast[1];
      const neLng = northEeast[0];

      this.mapTasks.forEach((mapTask) => {
        let coor = mapTask.task.field.coordinates[0];

        if (
          coor.lat < swLat &&
          coor.lng < neLat &&
          coor.lat > swLng &&
          coor.lng > neLng &&
          !mapTask.isSelected == select
        ) {
          this.store.dispatch(
            mapTaskSelected({ mapTask: mapTask, selected: select })
          );
        }
      });

      this.deleteSelectedShape();
    }
  }

  onMapReady(map) {
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      document.getElementById("delete-button")
    );
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      document.getElementById("select-button")
    );
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      document.getElementById("unselect-button")
    );

    this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["rectangle"],
      },
      rectangleOptions: {
        draggable: true,
        editable: true,
      },
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.modeChanged;
    this.drawingManager.setMap(map);

    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (e) => {
        {
          // Switch back to non-drawing mode after drawing a shape.
          this.drawingManager.setDrawingMode(null);
          // To hide:
          this.drawingManager.setOptions({
            drawingControl: false,
          });

          // Add an event listener that selects the newly-drawn shape when the user
          // mouses down on it.
          var newShape = e.overlay;
          newShape.type = e.type;
          google.maps.event.addListener(newShape, "click", function () {
            this.setSelection(newShape);
          });
          this.setSelection(newShape);
        }
      }
    );
  }

  clearSelection() {
    if (this.selectedShape) {
      this.selectedShape.setEditable(false);
      this.selectedShape = null;
    }
  }

  setSelection(shape) {
    this.clearSelection();
    this.selectedShape = shape;
    shape.setEditable(true);
  }

  deleteSelectedShape() {
    if (this.selectedShape) {
      this.selectedShape.setMap(null);
      // To show:
      this.drawingManager.setOptions({
        drawingControl: true,
      });

      this.clearSelection();
    }
  }

  ngOnDestroy(): void {}
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Material } from "src/app/core/models/material.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable, combineLatest } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { MaterialState } from "src/app/core/store/reducers/material.reducer";
import {
  selectMaterialsLoading,
  selectCurrentMaterial,
} from "src/app/core/store/selectors/material.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  loadMaterial,
  updateMaterial,
  addMaterial,
} from "src/app/core/store/actions/material.actions";
import { cloneDeep } from "lodash";

@Component({
  selector: "kt-edit-materials",
  templateUrl: "./edit-materials.component.html",
  styleUrls: ["./edit-materials.component.scss"],
})
export class EditMaterialsComponent implements OnInit, OnDestroy {
  title = "Add Material";
  isEdit = false;
  material: Material;
  materialForm: FormGroup;
  hasFormErrors = false;
  loading$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<MaterialState>,
    private materialFB: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.pipe(
      select(selectMaterialsLoading),
      untilDestroyed(this)
    );

    this.material = new Material(null, null);

    this.store
      .pipe(select(selectCurrentMaterial), untilDestroyed(this))
      .subscribe((currentMaterial) => {
        if (currentMaterial && this.isEdit) {
          this.material = cloneDeep(currentMaterial);
        }

        this.createForm();
      });

    let materialId = this.route.snapshot.params["id"];

    if (materialId && materialId != "add") {
      this.isEdit = true;
      this.title = "Edit Material";

      this.store.dispatch(loadMaterial({ data: materialId }));
    }
  }

  createForm() {
    this.materialForm = this.materialFB.group({
      name: [this.material.name, Validators.required],
    });
  }

  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.materialForm.controls;
    /** check form */
    if (this.materialForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      return;
    }

    this.prepareMaterial();

    if (this.isEdit) {
      this.store.dispatch(updateMaterial({ data: this.material }));
    } else {
      this.store.dispatch(addMaterial({ data: this.material }));
    }
  }

  prepareMaterial() {
    this.material.name = this.materialForm.controls.name.value;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  ngOnDestroy(): void {}
}

import { Component, OnInit } from "@angular/core";
import { loadCustomers } from "src/app/core/store/actions/customer.actions";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/core/reducers";

@Component({
  selector: "kt-fields",
  templateUrl: "./fields.component.html",
  styleUrls: ["./fields.component.scss"],
})
export class FieldsComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(loadCustomers());
  }
}

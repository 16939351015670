import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DroneRoute } from "src/app/core/models/drone-route-model";

@Component({
  selector: "kt-route-list",
  templateUrl: "./route-list.component.html",
  styleUrls: ["./route-list.component.scss"],
})
export class RouteListComponent implements OnInit {
  @Input() droneRoutes: DroneRoute[];
  @Output() routeSelected = new EventEmitter<DroneRoute>();

  constructor() {}

  ngOnInit(): void {}
}

import { selectMaterialsSelector } from "./../../../../core/store/selectors/material.selectors";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Material } from "src/app/core/models/material.model";
import {
  deleteMaterial,
  loadMaterials,
} from "src/app/core/store/actions/material.actions";
import { MessageType, LayoutUtilsService } from "src/app/core/_base/crud";
import { MaterialState } from "src/app/core/store/reducers/material.reducer";
import { Store, select } from "@ngrx/store";
import { selectMaterialsLoading } from "src/app/core/store/selectors/material.selectors";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
  selector: "kt-materials-list",
  templateUrl: "./materials-list.component.html",
  styleUrls: ["./materials-list.component.scss"],
})
export class MaterialsListComponent implements OnInit, OnDestroy {
  loading;
  materials: Material[];

  constructor(
    private store: Store<MaterialState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectMaterialsLoading), untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.store
      .pipe(select(selectMaterialsSelector), untilDestroyed(this))
      .subscribe((materials) => {
        this.materials = materials;
      });

    this.store.dispatch(loadMaterials());
  }

  deleteMaterial(_item: Material) {
    const _title = "Material Delete";
    const _description = "Are you sure to permanently delete this material?";
    const _waitDesciption = "Material is deleting...";
    const _deleteMessage = `Material has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(deleteMaterial({ data: _item }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  ngOnDestroy(): void {}
}

import { TasksService } from "./../../services/tasks.service";
import { PilotTasksService } from "./../../services/pilot-tasks.service";
import { currentUser } from "./../../auth/_selectors/auth.selectors";
import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  concatMap,
  withLatestFrom,
  filter,
  tap,
  switchMap,
} from "rxjs/operators";
import { EMPTY, of, pipe } from "rxjs";

import * as PilotTasksActions from "../actions/pilot-tasks.actions";
import { MessageType, LayoutUtilsService } from "../../_base/crud";

@Injectable()
export class PilotTasksEffects {
  loadPilotTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PilotTasksActions.loadPilotTasks),
      pipe(withLatestFrom(this.store.select(currentUser))),
      switchMap(([action, user]) => {
        return this.pilotTasksService
          .getPilotTasks(user.pilot.pilotId, action.date)
          .pipe(
            map((result) => {
              return PilotTasksActions.loadPilotTasksSuccess({
                tasks: result.tasks,
                mapTasks: result.mapTasks,
              });
            }),
            catchError((error) =>
              of(PilotTasksActions.loadPilotTasksFailure({ error }))
            )
          );
      })
    );
  });

  loadTaskById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PilotTasksActions.loadTaskById),
      concatMap((action) =>
        this.tasksService.getTaskById(action.taskId).pipe(
          map((data) => {
            return PilotTasksActions.loadTaskByIdSuccess({ task: data });
          }),
          catchError((error) =>
            of(PilotTasksActions.loadTaskByIdFailure({ error }))
          )
        )
      )
    );
  });

  updateTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PilotTasksActions.updateTask),
      concatMap((action) =>
        this.tasksService.updateTask(action.task).pipe(
          map((data) => {
            return PilotTasksActions.updateTaskSuccess({ task: action.task });
          }),
          catchError((error) =>
            of(PilotTasksActions.updateTaskFailure({ error }))
          )
        )
      )
    );
  });

  updateTaskSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PilotTasksActions.updateTaskSuccess),
        tap((action) => {
          const message = "Task status updated";
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            false
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private layoutUtilsService: LayoutUtilsService,
    private pilotTasksService: PilotTasksService,
    private tasksService: TasksService
  ) {}
}

<!--begin::Sticky Toolbar-->
<ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
	<li class="nav-item mb-2" data-toggle="tooltip" data-placement="left" ngbTooltip="Layout Builder">
		<a class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"  href="javascript:;" routerLink="/builder">
      <i class="flaticon2-gear"></i>
		</a>
	</li>
	<li class="nav-item mb-2" data-toggle="tooltip" data-placement="left" ngbTooltip="Documentation">
    <a class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning" href="https://keenthemes.com/metronic/?page=docs" target="_blank">
      <i class="flaticon2-telegram-logo"></i>
		</a>
	</li>
</ul>
<!--end::Sticky Toolbar-->

import { CustomersRoutingModule } from "./customers-routing.module";
import { FieldsListComponent } from "./../fields/fields-list/fields-list.component";
import { FieldsModule } from "./../fields/fields.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomersComponent } from "./customers.component";
import { CustomersListComponent } from "./customers-list/customers-list.component";
import { EditCustomerComponent } from "./edit-customer/edit-customer.component";
import { CustomersTableComponent } from "./customers-list/customers-table/customers-table.component";
import { Routes, RouterModule } from "@angular/router";
import { MatCoreModule } from "src/app/core/utils/mat-core.module";
import { HttpClientModule } from "@angular/common/http";
import { PartialsModule } from "../../partials/partials.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import * as fromCustomer from "../../../core/store/reducers/customer.reducer";
import { EffectsModule } from "@ngrx/effects";
import { CustomerEffects } from "../../../core/store/effects/customer.effects";
import { CustomerFieldsComponent } from "./customers-list/customer-fields/customer-fields.component";

@NgModule({
  declarations: [
    CustomersComponent,
    CustomersListComponent,
    EditCustomerComponent,
    CustomersTableComponent,
    CustomerFieldsComponent,
  ],
  imports: [
    MatCoreModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    CustomersRoutingModule,
    StoreModule.forFeature(
      fromCustomer.customerFeatureKey,
      fromCustomer.reducer
    ),
    EffectsModule.forFeature([CustomerEffects]),
  ],
})
export class CustomersModule {}

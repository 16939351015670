import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  fromAuth.authFeatureKey
);

export const isLoggedIn = createSelector(selectAuthState, auth => auth.loggedIn);

export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);

export const currentUser = createSelector(selectAuthState, auth => auth.currentUser);

export const authError = createSelector(selectAuthState, auth => auth.error);

export const authLoading = createSelector(
  selectAuthState,
  (state) => state.loading
);
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Coordinate } from "src/app/core/models/coordinate.mode";
import { Field } from "src/app/core/models/field.model";

@Component({
  selector: "kt-field-map",
  templateUrl: "./field-map.component.html",
  styleUrls: ["./field-map.component.scss"],
})
export class FieldMapComponent implements OnInit, OnChanges {
  zoom = 17;
  @Input() coordinates: Coordinate[];
  @Input() center: Coordinate;
  @Input() fields: Field[];
  @Input() infoWindowTemplate: TemplateRef<any>;

  constructor() {}
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.fields && this.fields?.length > 0 && !this.center) {
      this.center = this.fields[0].coordinates[0];
    }
  }

  ngOnInit(): void {}
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Task } from "src/app/core/models/task.model";
import { DatasourceOptions } from "src/app/core/models/datasource-options.model";
import { DataTableColumn } from "src/app/views/partials/content/general/datatable/datatable.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "kt-completed-tasks-table",
  templateUrl: "./completed-tasks-table.component.html",
  styleUrls: ["./completed-tasks-table.component.scss"],
})
export class CompletedTasksTableComponent implements OnInit {
  @Input() tasks: Task[];
  @Input() loading: boolean;
  @Input() datasourceOptions: DatasourceOptions;
  @Output() loadData = new EventEmitter<DatasourceOptions>();
  @Output() deleteTask = new EventEmitter<Task>();

  @ViewChild("actionsTemplate", { static: false }) actionsTemplate: TemplateRef<
    any
  >;

  @ViewChild("customerTemplate", { static: false })
  customerTemplate: TemplateRef<any>;

  @ViewChild("protocolTemplate", { static: false })
  protocolTemplate: TemplateRef<any>;

  @ViewChild("fieldTemplate", { static: false })
  fieldTemplate: TemplateRef<any>;

  @ViewChild("statusTemplate", { static: false })
  statusTemplate: TemplateRef<any>;

  @ViewChild("createdAtTemplate", { static: false })
  createdAtTemplate: TemplateRef<any>;

  @ViewChild("executionDate", { static: false })
  executionDate: TemplateRef<any>;

  @ViewChild("pilotTemplate", { static: false })
  pilotTemplate: TemplateRef<any>;

  @ViewChild("materialTemplate", { static: false })
  materialTemplate: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.columns = [
        {
          serverFieldName: "customer",
          clientFieldName: "customer",
          displayNameKey: "Customer",
          sortable: true,
          template: this.customerTemplate,
        },
        {
          serverFieldName: "material",
          clientFieldName: "material",
          displayNameKey: "Material",
          sortable: true,
          template: this.materialTemplate,
        },
        {
          serverFieldName: "protocol",
          clientFieldName: "protocol",
          displayNameKey: "Protocol",
          sortable: true,
          template: this.protocolTemplate,
        },
        {
          serverFieldName: "field",
          clientFieldName: "field",
          displayNameKey: "Field",
          sortable: true,
          template: this.fieldTemplate,
        },
        {
          serverFieldName: "pilot",
          clientFieldName: "pilot",
          displayNameKey: "Pilot",
          sortable: true,
          template: this.pilotTemplate,
        },
        {
          serverFieldName: "status",
          clientFieldName: "status",
          displayNameKey: "status",
          sortable: true,
          template: this.statusTemplate,
        },
        {
          serverFieldName: "createdAt",
          clientFieldName: "createdAt",
          displayNameKey: "Created",
          sortable: true,
          template: this.createdAtTemplate,
        },
        {
          serverFieldName: "executionDate",
          clientFieldName: "executionDate",
          displayNameKey: "Execution",
          sortable: true,
          template: this.executionDate,
        },
      ];
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

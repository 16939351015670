import { MapTask } from "./../../models/map-task.model";
import { Task } from "./../../models/task.model";
import { createAction, props } from "@ngrx/store";

export const loadPilotTasks = createAction(
  "[PilotTasks] Load PilotTasks",
  props<{ date?: Date }>()
);

export const loadPilotTasksSuccess = createAction(
  "[PilotTasks] Load PilotTasks Success",
  props<{ tasks: Task[]; mapTasks: MapTask[] }>()
);

export const loadPilotTasksFailure = createAction(
  "[PilotTasks] Load PilotTasks Failure",
  props<{ error: any }>()
);

export const loadTaskById = createAction(
  "[PilotTasks] Load taskById",
  props<{ taskId: string }>()
);

export const loadTaskByIdSuccess = createAction(
  "[PilotTasks] Load taskById Success",
  props<{ task: Task }>()
);

export const loadTaskByIdFailure = createAction(
  "[PilotTasks] Load taskById Failure",
  props<{ error: any }>()
);

export const updateTask = createAction(
  "[PilotTasks] updateTask ",
  props<{ task: Task }>()
);

export const updateTaskSuccess = createAction(
  "[PilotTasks] updateTask Success",
  props<{ task: Task }>()
);

export const updateTaskFailure = createAction(
  "[PilotTasks] updateTask Failure",
  props<{ error: any }>()
);

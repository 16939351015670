import { Task } from "./task.model";
import { LatLngBounds, LatLngLiteral } from "@agm/core";

declare const google: any;

export class MapTask {
  bounds;

  constructor(public task: Task, public isSelected: boolean) {
    this.bounds = new google.maps.LatLngBounds();
    task.field.coordinates.map((c) => this.bounds.extend(c));
  }

  static parse(obj) {
    let task = Task.parse(obj);

    return new MapTask(task, false);
  }

  getNorth() {
    let lats = this.task.field.coordinates.map((c) => c.lat);
    let northLat = Math.max(...lats);
    let north = this.task.field.coordinates.find((c) => c.lat == northLat);
    return north;
  }
}

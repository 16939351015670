import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { loadPilotTypes } from "src/app/core/store/actions/drone-pilots.actions";
import { Store } from "@ngrx/store";
import { DronePilotsState } from "src/app/core/store/reducers/drone-pilots.reducer";

@Component({
  selector: "kt-drone-pilots",
  templateUrl: "./drone-pilots.component.html",
  styleUrls: ["./drone-pilots.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DronePilotsComponent implements OnInit {
  constructor(private store: Store<DronePilotsState>) {}

  ngOnInit(): void {
    this.store.dispatch(loadPilotTypes());
  }
}

import { Coordinate } from "./../../../../../core/models/coordinate.mode";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "kt-coordinates-list",
  templateUrl: "./coordinates-list.component.html",
  styleUrls: ["./coordinates-list.component.scss"],
})
export class CoordinatesListComponent implements OnInit {
  @Input() coordinates: Coordinate[] = [];
  @Output() coordinateAdded = new EventEmitter<Coordinate>();
  coorForm: FormGroup;

  constructor(private coorFb: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.coorForm = this.coorFb.group({
      lat: [null, Validators.required],
      lng: [null, Validators.required],
    });
  }

  addCoor() {
    const controls = this.coorForm.controls;

    if (this.coorForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    const coor = new Coordinate(controls.lat.value, controls.lng.value);
    this.coordinates.push(coor);

    this.coorForm.reset();

    this.coordinateAdded.emit(coor);
  }

  deleteCoor(coor) {
    this.coordinates.splice(this.coordinates.indexOf(coor), 1);
  }
}

export class Coordinate {
  constructor(public lat: number, public lng: number) {}

  static parseBack(coor: Coordinate) {
    return {
      lat: coor.lat,
      long: coor.lng,
    };
  }
}

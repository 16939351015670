import { Customer } from "./customer.model";
import { Coordinate } from "./coordinate.mode";

export class Field {
  static createEmpty(): Field {
    return new Field(null, null, [], null, null, null, null, null);
  }
  static parseBack(field: Field): object {
    return {
      customerId: field.customer.customerId,
      cropName: field.cropName,
      plantingYear: field.plantingYear,
      coordinates: JSON.stringify(
        field.coordinates.map((c) => Coordinate.parseBack(c))
      ),
      plotNumber: field.plotNumber,
      moagId: field.moagId,
      areaSquareMeters: field.areaSquareMeters,
    };
  }
  static parse(obj: any) {
    const customer = JSON.parse(JSON.stringify(obj.Customer));

    let c = JSON.parse(obj.coordinates);
    const coordinates = c.map((c) => new Coordinate(+c.lat, +c.long));
    return new Field(
      obj.fieldId,
      customer,
      coordinates,
      obj.cropName,
      obj.plotNumber,
      obj.plantingYear,
      obj.moagId,
      obj.areaSquareMeters
    );
  }
  constructor(
    public fieldId: string,
    public customer: Customer,
    public coordinates: Coordinate[],
    public cropName: string,
    public plotNumber: number,
    public plantingYear: number,
    public moagId: string,
    public areaSquareMeters: number
  ) {}

  get isFullyLoaded() {
    return (
      this.cropName &&
      this.plotNumber &&
      this.coordinates &&
      this.coordinates.length > 0 &&
      this.customer
    );
  }
}

<div class="row card-border">
  <!-- Drones -->
  <div class="col">
    <kt-mat-select-filter
      [dataSet]="drones"
      [filterBy]="'tailNumber'"
      [valueProperty]="'droneId'"
      [hint]="'Please select a drone'"
      [placeHolder]="'Select one or more drones to see their routes'"
      [selectFormControl]="dronesSelectFormControl"
      [multiple]="true"
    >
    </kt-mat-select-filter>
  </div>

  <!-- Pilot -->
  <div class="col">
    <kt-mat-select-filter
      [dataSet]="pilots"
      [filterBy]="'name'"
      [valueProperty]="'pilotId'"
      [selectFormControl]="pilotsSelectFormControl"
      [hint]="'Choose a pilot to filter by'"
      [placeHolder]="'Select a pilot'"
    >
    </kt-mat-select-filter>
  </div>
  <!-- Execution Date -->
  <div class="col">
    <div class="row">
      <div class="col-lg-10">
        <mat-form-field class="mat-form-field-fluid">
          <input
            #executionDateFormControl
            matInput
            [matDatepicker]="picker"
            placeholder="Enter execution date"
          />
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            >Execution date is
            <strong>required</strong>
          </mat-error>
          <mat-hint align="start"
            >Please enter
            <strong>Execution date</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      </div>
    </div>
  </div>

  <div class="col-auto">
    <button
      mat-raised-button
      color="primary"
      (click)="filterClick()"
      [disabled]="loading$ | async"
    >
      Filter
    </button>
  </div>
</div>

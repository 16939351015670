import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldsComponent } from "./fields.component";
import { FieldsListComponent } from "./fields-list/fields-list.component";
import { ImportFieldsComponent } from "./import-fields/import-fields.component";
import { EditFieldComponent } from "./edit-field/edit-field.component";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ModuleGuard } from "src/app/core/auth";

const routes: Routes = [
  {
    path: "",
    component: FieldsComponent,
    canActivate: [ModuleGuard],
    data: {
      permissions: {
        only: "Admin",
        redirectTo: "dashboard",
      },
    },
    children: [
      {
        path: "",
        pathMatch: "full",
        component: FieldsListComponent,
      },
      {
        path: "import",
        pathMatch: "full",
        component: ImportFieldsComponent,
      },
      {
        path: "add",
        component: EditFieldComponent,
      },
      {
        path: ":customerId",
        pathMatch: "full",
        component: FieldsListComponent,
      },
      {
        path: ":customerId/edit/:id",
        pathMatch: "full",
        component: EditFieldComponent,
      },
      {
        path: ":customerId/import",
        pathMatch: "full",
        component: ImportFieldsComponent,
      },
      {
        path: ":customerId/add",
        pathMatch: "full",
        component: EditFieldComponent,
      },

      {
        path: "edit/:id",
        component: EditFieldComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FieldsRoutingModule {}

<!-- begin:: Footer -->
<div
  class="footer bg-white py-4 d-flex flex-lg-column"
  id="kt_footer"
  [ngClass]="footerClasses"
>
  <div
    [ngClass]="footerContainerClasses"
    class="d-flex flex-column flex-md-row align-items-center justify-content-between"
  >
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <a class="text-dark-75 text-hover-primary">
        {{ "AUTH.GENERAL.TITLE" | translate }} {{ today | date: "yyyy" }}</a
      >
    </div>
    <!--end::Copyright-->

    <!--begin::Nav-->
    <div class="nav nav-dark order-1 order-md-2">
      <!-- <a href="http://keenthemes.com/metronic" target="_blank" class="nav-link pr-3 pl-0">About</a>
			<a href="http://keenthemes.com/metronic" target="_blank" class="nav-link px-3">Team</a> -->
      <a
        href="https://www.aksata360.com/contact-us"
        target="_blank"
        class="nav-link pl-3 pr-0"
        >Contact</a
      >
    </div>
  </div>
</div>
<!-- end:: Footer -->

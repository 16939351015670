import { createFeatureSelector, createSelector, select } from "@ngrx/store";
import * as fromDronePilots from "../reducers/drone-pilots.reducer";
import { pipe } from "rxjs";
import { skip } from "rxjs/operators";

export const selectDronePilotsState = createFeatureSelector<
  fromDronePilots.DronePilotsState
>(fromDronePilots.dronePilotsFeatureKey);

export const selectPilotsSelector = createSelector(
  selectDronePilotsState,
  fromDronePilots.selectAll
);

//export const selectPilots = pipe(select(selectDronesSelector), skip(1));

export const selectPilotsLoading = createSelector(
  selectDronePilotsState,
  (state) => state.loading
);

export const selectPilotTypes = createSelector(
  selectDronePilotsState,
  (state) => state.pilotTypes
);

export const selectCurrentPilot = createSelector(
  selectDronePilotsState,
  (state) => state.currentDronePilot
);
